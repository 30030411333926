import { LoadingSpinner } from "components/LoadingSpinner";
import { useLoading } from "context/LoadingContext";
import { ToastContext } from "context/ToastContext";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { useMemo, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetCaseRateRulesetsQuery,
  useDeleteCaseRateRulesetMutation,
} from "store/queries/pricing";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColumnWrapper = styled.div`
  display: block;
  max-height: 20px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export function CaseRateRulesTable() {
  const [selectedItems, setSelectedItems] = useState<{ id: string }[]>([]);
  const { setIsLoading } = useLoading();
  const { current: toastElement } = useContext(ToastContext);
  const [deleteCaseRateRuleset] = useDeleteCaseRateRulesetMutation();

  const navigate = useNavigate();
  const {
    data: caseRateRulesets,
    isLoading,
    refetch,
  } = useGetCaseRateRulesetsQuery();

  const parsedCaseRateRulesets = useMemo(() => {
    if (!caseRateRulesets?.data) return [];
    return caseRateRulesets.data.map((aCaseRateRuleset) => {
      return {
        ...aCaseRateRuleset,
        providerName: aCaseRateRuleset.provider.name,
        payerName: aCaseRateRuleset?.payer?.name,
      };
    });
  }, [caseRateRulesets]);

  const cptCodesTransformer = (cptCodes: string[]) => {
    const allCodes = cptCodes.join(", ");
    return <ColumnWrapper>{allCodes}</ColumnWrapper>;
  };

  function payerRuleAction(payerRuleId: string) {
    return (
      <Row>
        <Button
          className="p-button-link"
          onClick={() => navigate(`/payer-rules/case-rate/edit/${payerRuleId}`)}
        >
          <p className="small-text">Edit</p>
        </Button>
      </Row>
    );
  }

  const handleDeletePayerRules = async () => {
    setIsLoading(true);
    try {
      const deletePromises = selectedItems.map((item) =>
        deleteCaseRateRuleset(item.id)
      );
      await Promise.all(deletePromises);
      await refetch();
      toastElement?.show({
        severity: "success",
        summary: "Deleted!",
        detail: "Payer Rules(s) has been deleted successfully.",
      });
    } catch (error) {
      await refetch();
      toastElement?.show({
        severity: "error",
        summary: "Error",
        detail: "Error while deleting payer rules",
      });
    } finally {
      setIsLoading(false);
      setSelectedItems([]);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <DataTable
        rows={10}
        paginator
        stripedRows
        className="p-fluid"
        filterDisplay="row"
        value={parsedCaseRateRulesets}
        responsiveLayout="scroll"
        style={{ cursor: "pointer" }}
        selection={selectedItems}
        emptyMessage="No payer rules to show."
        selectionMode="single"
        onSelectionChange={(event) => setSelectedItems(event.value)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
        <Column
          filter
          field="providerName"
          style={{ minWidth: "300px" }}
          header="Provider Name"
          filterPlaceholder="Search by provider name"
        />
        <Column
          filter
          field="payerName"
          style={{ minWidth: "300px" }}
          header="Payer Name"
          filterPlaceholder="Search by payer name"
        />
        <Column
          filter
          field="groupName"
          style={{ minWidth: "300px" }}
          header="Rule Name"
          filterPlaceholder="Search by rule name"
        />
        <Column
          filter
          field="cptCodes"
          body={(row) => cptCodesTransformer(row.cptCodes)}
          style={{ minWidth: "280px", maxWidth: "280px" }}
          header="CPT Codes"
          filterPlaceholder="Search by CPT code"
        />
        <Column
          filter
          field="excludedCptCodes"
          body={(row) => cptCodesTransformer(row.excludedCptCodes)}
          style={{ minWidth: "280px", maxWidth: "280px" }}
          header="Excluded CPT Codes"
          filterPlaceholder="Search by CPT code"
        />
        <Column
          style={{ maxWidth: "100px" }}
          field="viewEditPayerRule"
          body={(row) => payerRuleAction(row.id)}
        />
      </DataTable>
      <div className="flex justify-content-end">
        <Button
          label="Delete"
          className="p-button-outlined mt-4"
          disabled={!selectedItems.length}
          onClick={() => {
            confirmDialog({
              header: "Confirmation",
              icon: "pi pi-exclamation-triangle",
              message: `Are you sure you want delete selected payer rules?`,
              accept: handleDeletePayerRules,
            });
          }}
        />
      </div>
    </>
  );
}
