import useFormValidation from "hooks/useFormValidation";
import { Label } from "layout/typography/Label";
import { Chips } from "primereact/chips";
import { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { formValidationConstants } from "../helpers/formValidations";

const Title = styled.h3`
  margin-bottom: 16px;
`;

export function PverifyId() {
  const {
    control,
    trigger,
    formState: { errors },
    watch,
  } = useFormContext();
  const [pverifyId, setPverifyId] = useState<string[]>([]);
  const { getFormErrorMessage, handleOnBlurField } = useFormValidation();
  const watchedPverifyId = watch("pverifyId");

  useEffect(() => {
    if (watchedPverifyId) {
      setPverifyId(watchedPverifyId);
    }
  }, [watchedPverifyId]);

  return (
    <div className="block bg-white border-round p-4 mb-4">
      <Title>Pverify Code</Title>
      <Label htmlFor="cptCodes">Add Pverify Code</Label>
      <Controller
        name="pverifyId"
        control={control}
        rules={{
          required: formValidationConstants.PAYER.PVERIFY_ID,
        }}
        render={({ field: { onChange, onBlur } }) => (
          <>
            <Chips
              id="cptCodes"
              value={pverifyId}
              className="w-full mt-2"
              onChange={(e: any) => {
                setPverifyId(e.value.map((item: any) => item.trim()));
                onChange(e.value.map((item: any) => item.trim()));
              }}
              separator=","
              onBlur={() => {
                handleOnBlurField({ onBlur, field: "pverifyId", trigger });
              }}
            />
            {getFormErrorMessage("pverifyId", errors)}
          </>
        )}
      />
    </div>
  );
}
