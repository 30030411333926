import { LoadingSpinner } from "components/LoadingSpinner";
import useDocumentTitle from "hooks/useDocumentTitle";
import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useGetDiscountRulesetByIdQuery } from "store/queries/procedureDiscountRuleset";

import { AllowDuplicateCptCodes } from "./components/AllowDuplicateCptCodes";
import { CptCodes } from "./components/CptCodes";
import { DiscountPercents } from "./components/DiscountPercents";
import { Header } from "./components/Header";
import { ProviderAndPayerEditSection } from "./components/ProviderAndPayerEditSection";
import { RuleName } from "./components/RuleName";
import { SubmitEdit } from "./components/SubmitEdit";

export function EditPayerRulesPage() {
  useDocumentTitle("Edit Payer Rules");
  const { id } = useParams();
  const { data: ruleset, isLoading } = useGetDiscountRulesetByIdQuery(id);

  const methods = useForm({
    defaultValues: {
      provider: {
        name: ruleset?.data.provider.name,
        value: ruleset?.data.provider.id,
      },
      payerName: {
        name: ruleset?.data?.payer?.name,
        value: ruleset?.data?.payer?.id,
      },
      groupName: ruleset?.data.groupName,
      cptCodes: ruleset?.data.cptCodes,
      discountPercentForRank2: ruleset?.data.discountPercentForRank2,
      discountPercentForRank3: ruleset?.data.discountPercentForRank3,
      discountPercentForRank4: ruleset?.data.discountPercentForRank4,
      discountPercentForRank5Plus: ruleset?.data.discountPercentForRank5Plus,
      allowOnDuplicateCptCodes: ruleset?.data.allowOnDuplicateCptCodes,
    },
  });

  useEffect(() => {
    if (ruleset) {
      methods.reset({
        provider: {
          name: ruleset?.data.provider.name,
          value: ruleset?.data.provider.id,
        },
        payerName: {
          name: ruleset?.data?.payer?.name,
          value: ruleset?.data?.payer?.id,
        },
        groupName: ruleset?.data.groupName,
        cptCodes: ruleset?.data.cptCodes,
        discountPercentForRank2: ruleset?.data.discountPercentForRank2,
        discountPercentForRank3: ruleset?.data.discountPercentForRank3,
        discountPercentForRank4: ruleset?.data.discountPercentForRank4,
        discountPercentForRank5Plus: ruleset?.data.discountPercentForRank5Plus,
        allowOnDuplicateCptCodes: ruleset?.data.allowOnDuplicateCptCodes,
      });
    }
  }, [ruleset, methods]);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <FormProvider {...methods}>
      <Header name={ruleset?.data.groupName} />
      <ProviderAndPayerEditSection />
      <RuleName />
      <AllowDuplicateCptCodes />
      <CptCodes />
      <DiscountPercents />
      {id && ruleset && <SubmitEdit id={id} />}
    </FormProvider>
  );
}
