import { validationConstants } from "constants/formValidation";
import { orderTypeConstants } from "constants/order";
import { ToastContext } from "context/ToastContext";
import { parseDate } from "helpers/date";
import useFormValidation from "hooks/useFormValidation";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import {
  removeTimezone,
  validateInputDatePicker,
} from "pages/CreateOrder/utils/helpers";
import {
  handleChangeDateWithMask,
  handleChangePhoneWithMask,
} from "pages/CreateOrder/utils/mask";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useContext, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useNotifyMutation } from "store/queries/communications";
import { useUpdatePatientOverviewMutation } from "store/queries/patient";
import styled from "styled-components";
import { IDefaultServiceDetails, IOrder } from "types/Order/Order";

const StyledCard = styled(Card)`
  margin-bottom: ${sizer(2)};
`;

interface IPatientEdit {
  email?: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber?: string;
  outOfPocketReason: string;
}

function PatientDetails({
  order: { patient, serviceDetails, orderType, id },
}: {
  order: IOrder;
}) {
  const {
    control,
    trigger,
    getValues,
    setValue,
    setError,
    formState: { errors, dirtyFields },
  } = useFormContext<IPatientEdit>();
  const [editing, setEdit] = useState(false);
  const [updatePatient, { isError: isUpdatePatientError }] =
    useUpdatePatientOverviewMutation();
  const [notify] = useNotifyMutation();
  const {
    isValidPhoneNumber,
    handleEmailPhoneValidation,
    getFormErrorMessage,
    handleOnBlurField,
  } = useFormValidation();
  const { current: toastElement } = useContext(ToastContext);

  useEffect(() => {
    setValue("email", patient.email);
    setValue("firstName", patient.firstName);
    setValue("lastName", patient.lastName);
    setValue("dateOfBirth", parseDate(patient.dateOfBirth));
    setValue("phoneNumber", patient.phoneNumber);
  }, [patient, setValue]);

  const resetForm = () => {
    setValue("email", patient.email);
    setValue("firstName", patient.firstName);
    setValue("lastName", patient.lastName);
    setValue("dateOfBirth", parseDate(patient.dateOfBirth));
    setValue("phoneNumber", patient.phoneNumber);
  };

  const mapPatientUpdateRequest = (values: IPatientEdit) => ({
    dateOfBirth: removeTimezone(values.dateOfBirth),
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    phoneNumber: values.phoneNumber,
  });

  const submit = () => {
    const values = mapPatientUpdateRequest(getValues());
    const EmailOrPhoneChanged =
      dirtyFields.email || dirtyFields.phoneNumber || false;

    updatePatient({ id: patient.id, data: values })
      .unwrap()
      .then(async () => {
        if (EmailOrPhoneChanged) {
          notify(id)
            .unwrap()
            .then(() => {
              toastElement?.show({
                summary: "Success",
                severity: "success",
                detail: "Patient edited successfully",
              });
            })
            .catch(() => {
              toastElement?.show({
                severity: "error",
                detail: "Try again later.",
                summary: "Email failed to be sent. Please try again.",
              });
            });
        } else {
          toastElement?.show({
            summary: "Success",
            severity: "success",
            detail: "Patient edited successfully",
          });
        }
        setEdit(false);
      })
      .catch(() => {
        if (isUpdatePatientError) {
          toastElement?.show({
            summary: "Error",
            severity: "error",
            detail: "Patient cannot be edited. Please try again.",
          });
        }
      });
  };

  const actionFooter = () => {
    return (
      <>
        <Button label="Save" icon="pi pi-check" onClick={submit} />
        <Button
          label="Cancel"
          onClick={() => {
            resetForm();
            setEdit(false);
          }}
          icon="pi pi-times"
          style={{ marginLeft: "0.5em" }}
        />
      </>
    );
  };

  const enableEditing = () => {
    setEdit(true);
  };

  const actionHeader = () => {
    return (
      <div className="flex flex-column col-12 align-items-end">
        <div>
          <Button label="Edit" icon="pi pi-check" onClick={enableEditing} />
        </div>
      </div>
    );
  };

  const isDefaultOrder =
    orderType === orderTypeConstants.BUNDLED ||
    orderType === orderTypeConstants.BARIATRICS ||
    orderType === orderTypeConstants.ED;

  return (
    <StyledCard
      title="Patient Details"
      footer={editing && actionFooter}
      header={!editing && actionHeader}
    >
      <div className="formgrid grid w-100">
        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="firstName">First Name</Label>
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: validationConstants.PATIENT_INFO_FORM.FIRST_NAME,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  disabled={!editing}
                  id="firstName"
                  aria-labelledby="firstName"
                  value={value}
                  onChange={onChange}
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "firstName", trigger });
                  }}
                />
                {getFormErrorMessage("firstName", errors)}
              </>
            )}
          />
        </div>
        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="lastName">Last Name</Label>
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: validationConstants.PATIENT_INFO_FORM.LAST_NAME,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  disabled={!editing}
                  id="lastName"
                  aria-labelledby="lastName"
                  value={value}
                  onChange={onChange}
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "lastName", trigger });
                  }}
                />
                {getFormErrorMessage("lastName", errors)}
              </>
            )}
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="email">Email</Label>
          <Controller
            name="email"
            control={control}
            rules={{
              required: handleEmailPhoneValidation(
                patient.preferredContactMethod,
                "email"
              ),
              pattern: {
                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                message: "Email is invalid.",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  disabled={!editing}
                  id="email"
                  aria-labelledby="email"
                  value={value}
                  onChange={onChange}
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "email", trigger });
                  }}
                />
                {getFormErrorMessage("email", errors)}
              </>
            )}
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="dob">DOB</Label>
          <Controller
            name="dateOfBirth"
            control={control}
            rules={{
              validate: (date) => validateInputDatePicker(date, "past"),
              required: validationConstants.PATIENT_INFO_FORM.DOB,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  disabled={!editing}
                  value={value}
                  id="dateOfBirth"
                  placeholder="99/99/9999"
                  onBlur={() =>
                    handleOnBlurField({
                      onBlur,
                      field: "dateOfBirth",
                      trigger,
                    })
                  }
                  onChange={(e) => handleChangeDateWithMask(e, onChange)}
                />

                {getFormErrorMessage("dateOfBirth", errors)}
              </>
            )}
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="phoneNumber">Phone Number</Label>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              required: handleEmailPhoneValidation(
                patient.preferredContactMethod,
                "phone"
              ),
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  disabled={!editing}
                  id="phoneNumber"
                  autoComplete="off"
                  aria-labelledby="phoneNumber"
                  value={value}
                  onChange={(e) => handleChangePhoneWithMask(e, onChange)}
                  onBlur={() => {
                    isValidPhoneNumber(
                      patient.preferredContactMethod,
                      value,
                      setError
                    );
                    return handleOnBlurField({
                      onBlur,
                      field: "phoneNumber",
                      trigger,
                    });
                  }}
                />
                {getFormErrorMessage("phoneNumber", errors)}
              </>
            )}
          />
        </div>

        {isDefaultOrder && (
          <div className="field flex flex-column col-12 md:col-4">
            <Label htmlFor="outOfPocketReason">Out of pocket reason</Label>

            <InputText
              disabled
              id="outOfPocketReason"
              aria-labelledby="outOfPocketReason"
              value={
                (serviceDetails as IDefaultServiceDetails).outOfPocketReason
              }
            />
          </div>
        )}
      </div>
    </StyledCard>
  );
}

export default PatientDetails;
