import { api } from ".";

const customizeQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrderTypesCustoms: builder.query<
      // { data: ICustomizeResponse[] },
      any,
      void
    >({
      query: () => "customize",
    }),
    getAccountCustoms: builder.query<any, string>({
      query: (accountId) => `customize/${accountId}`,
    }),
    getCustomsByOrderTypeAndAccountId: builder.query<
      any,
      { orderType: string; accountId: string }
    >({
      query: ({ orderType, accountId }) =>
        `customize/${orderType}/account/${accountId}`,
    }),
    upsertCustom: builder.mutation({
      query: (body) => ({
        url: `customize`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllOrderTypesCustomsQuery,
  useUpsertCustomMutation,
  useGetAccountCustomsQuery,
  useGetCustomsByOrderTypeAndAccountIdQuery,
} = customizeQueries;
