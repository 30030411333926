import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { IAccount } from "types/Account/Account";

export interface IUserState {
  currentAccount?: string;
  email?: string;
  id?: string;
  name?: string;
  scope?: string;
  accountLogoUrl?: string;
  accountIds?: string[];
  chatOnlineEnabled?: boolean;
}

const initialState: IUserState = {
  currentAccount: "",
  email: "",
  id: "",
  name: "",
  scope: "",
  accountLogoUrl: "",
  accountIds: [],
  chatOnlineEnabled: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    onAdminAccountChange: (
      state,
      action: { payload: { currentAccount: string | undefined } }
    ) => {
      state.currentAccount = action.payload.currentAccount;
    },
    onStaffAccountChange: (
      state,
      action: { payload: { currentAccount: IAccount } }
    ) => {
      state.accountLogoUrl =
        action.payload.currentAccount.accountLogoUrl || undefined;
      state.id = action.payload.currentAccount.id;
      state.currentAccount = action.payload.currentAccount.id;
    },
    onUserAccountLoad: (state, action: { payload: Partial<IUserState> }) => {
      state.email = action.payload.email;
      state.accountLogoUrl = action.payload.accountLogoUrl;
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.scope = action.payload.scope;
      state.accountIds = action.payload.accountIds;
      state.currentAccount = action.payload.currentAccount;
      state.chatOnlineEnabled = false;
    },
  },
});

export const { onAdminAccountChange, onUserAccountLoad, onStaffAccountChange } =
  userSlice.actions;
export const userSelector = (state: RootState) => state.userSlice;

export default userSlice.reducer;
