import { contactMethodOptions } from "constants/contactMethod";
import { validationConstants } from "constants/formValidation";
import useFormValidation from "hooks/useFormValidation";
import { useQuery } from "hooks/useQuery";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { validateInputDatePicker } from "pages/CreateOrder/utils/helpers";
import {
  handleChangeDateWithMask,
  handleChangePhoneWithMask,
} from "pages/CreateOrder/utils/mask";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useMemo, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useGetAccountByIdQuery } from "store/queries/account";
import styled from "styled-components";

const StyledCard = styled(Card)`
  margin-bottom: ${sizer(4)};
`;

export function PatientInfoForm() {
  const {
    control,
    trigger,
    getValues,
    setError,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();

  const query = useQuery();
  const accountId = query.get("accountId") || "";
  const orderTypeField = watch("orderType");

  const { data: accountData } = useGetAccountByIdQuery(accountId, {
    skip: !accountId,
  });

  const mainProvider = useMemo(() => {
    return (
      accountData?.data?.providers.find(
        (aMainProvider) => !!aMainProvider.mainFacility
      ) || {}
    );
  }, [accountData]);

  useEffect(() => {
    if (!!mainProvider.eligibilityEnabled && orderTypeField === "GFE") {
      setValue("isSubscriberPatient", true);
    }
  }, [mainProvider, orderTypeField]);

  const {
    isValidPhoneNumber,
    handleEmailPhoneValidation,
    handleOnBlurField,
    getFormErrorMessage,
  } = useFormValidation();

  return (
    <StyledCard title="Patient details">
      <div className="flex flex-column gap-4 w-100">
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="firstName">First Name</Label>

            <Controller
              name="firstName"
              control={control}
              rules={{
                required: validationConstants.PATIENT_INFO_FORM.FIRST_NAME,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id="firstName"
                    autoComplete="off"
                    value={value || ""}
                    placeholder="First Name"
                    aria-labelledby="firstName"
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field: "firstName",
                        trigger,
                      });
                    }}
                    onChange={onChange}
                  />

                  {getFormErrorMessage("firstName", errors)}
                </>
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="lastName">Last Name</Label>

            <Controller
              name="lastName"
              control={control}
              rules={{
                required: validationConstants.PATIENT_INFO_FORM.LAST_NAME,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id="lastName"
                    value={value || ""}
                    autoComplete="off"
                    placeholder="Last Name"
                    onBlur={() =>
                      handleOnBlurField({ onBlur, field: "lastName", trigger })
                    }
                    onChange={onChange}
                  />

                  {getFormErrorMessage("lastName", errors)}
                </>
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="dateOfBirth">Date of Birth</Label>

            <Controller
              name="dateOfBirth"
              control={control}
              rules={{
                validate: (date) => validateInputDatePicker(date, "past"),
                required: validationConstants.PATIENT_INFO_FORM.DOB,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    value={value || ""}
                    id="dateOfBirth"
                    placeholder="99/99/9999"
                    onBlur={() =>
                      handleOnBlurField({
                        onBlur,
                        field: "dateOfBirth",
                        trigger,
                      })
                    }
                    onChange={(e) => handleChangeDateWithMask(e, onChange)}
                  />

                  {getFormErrorMessage("dateOfBirth", errors)}
                </>
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="preferredContactMethod">
              Preferred contact method
            </Label>

            <Controller
              control={control}
              name="preferredContactMethod"
              rules={{
                required: validationConstants.PATIENT_INFO_FORM.CONTACT_METHOD,
              }}
              render={({ field: { onChange, value, onBlur } }) => (
                <>
                  <Dropdown
                    value={value || ""}
                    optionLabel="name"
                    placeholder="Select"
                    id="preferredContactMethod"
                    options={contactMethodOptions}
                    onChange={onChange}
                    onBlur={() =>
                      handleOnBlurField({
                        onBlur,
                        field: "dateOfBirth",
                        trigger,
                      })
                    }
                  />

                  {getFormErrorMessage("preferredContactMethod", errors)}
                </>
              )}
            />
          </div>
        </div>

        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="email">Email Address</Label>

            <Controller
              name="email"
              control={control}
              rules={{
                required: handleEmailPhoneValidation(
                  getValues("preferredContactMethod"),
                  "email"
                ),
                pattern: {
                  value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  message: "Email is invalid.",
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id="email"
                    value={value || ""}
                    autoComplete="off"
                    placeholder="Email Address"
                    onBlur={() =>
                      handleOnBlurField({ onBlur, field: "email", trigger })
                    }
                    onChange={onChange}
                  />

                  {getFormErrorMessage("email", errors)}
                </>
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="phoneNumber">Phone Number</Label>

            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                required: handleEmailPhoneValidation(
                  getValues("preferredContactMethod"),
                  "phone"
                ),
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    value={value || ""}
                    id="phoneNumber"
                    autoComplete="off"
                    placeholder="Phone Number"
                    onBlur={() => {
                      isValidPhoneNumber(
                        getValues("preferredContactMethod"),
                        value,
                        setError
                      );
                      return handleOnBlurField({
                        onBlur,
                        field: "phoneNumber",
                        trigger,
                      });
                    }}
                    onChange={(e) => {
                      handleChangePhoneWithMask(e, onChange);
                    }}
                  />

                  {getFormErrorMessage("phoneNumber", errors)}
                </>
              )}
            />
          </div>
          {!!mainProvider.eligibilityEnabled && orderTypeField === "GFE" && (
            <div className="field-checkbox mt-3 col-12 md:col-12 lg:col-6 hidden">
              <Label htmlFor="isSubscriberPatient">
                Is patient the subscriber?
              </Label>
              <Controller
                name="isSubscriberPatient"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    className="ml-3"
                    onChange={onChange}
                    checked={value}
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>
    </StyledCard>
  );
}
