import { LoadingSpinner } from "components/LoadingSpinner";
import useDocumentTitle from "hooks/useDocumentTitle";
import { useQuery } from "hooks/useQuery";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useGetProviderQuery } from "store/queries/providers";
import styled from "styled-components";

import { AddressForm } from "./components/AddressForm";
import { Header } from "./components/Header";
import { MoreInfoForm } from "./components/MoreInfoForm";
import { ProviderDetailsForm } from "./components/ProviderDetailsForm";
import { SubmitEdit } from "./components/SubmitEdit";

const Space = styled.div`
  height: 20px;
`;
export function EditProvider() {
  const { providerId } = useParams();
  const query = useQuery();
  const accountId = query.get("accountId");
  useDocumentTitle("Edit Provider");
  const { data, isLoading } = useGetProviderQuery(providerId);

  const methods = useForm({
    values: {
      name: data?.data.name,
      specialty: data?.data.specialty,
      address: data?.data.streetAddress,
      city: data?.data.city,
      state: data?.data.state,
      zipcode: data?.data.zipcode,
      npi: data?.data.npi,
      contactName: data?.data.paymentContact?.contactName,
      contactPhone: data?.data.paymentContact?.contactPhone,
      contactEmail: data?.data.paymentContact?.contactEmail,
      taxPayorIdNumber: data?.data.taxPayorIdNumber,
      stripeAccountNumber: data?.data.stripeAccountNumber,
      type: data?.data.classification,
      emailToReceiveReports: data?.data.emailToReceiveReports,
      emailToReceiveNotification: data?.data.emailToReceiveNotification,
      accountId,
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <FormProvider {...methods}>
      <Header />
      <ProviderDetailsForm />
      <Space />
      <AddressForm />
      <Space />
      <MoreInfoForm />
      <Space />
      <SubmitEdit />
    </FormProvider>
  );
}
