import { api } from ".";

const eligibilityQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    createEligibility: builder.mutation({
      query: ({ body }) => {
        return {
          url: "eligibility",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const { useCreateEligibilityMutation } = eligibilityQueries;
