import { Auth0Provider } from "@auth0/auth0-react";
import ContextCompose from "components/ContextCompose/ContextCompose";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { WebSocketComponent } from "components/WebSocket";
import { getEnvironment, ConfigContext, getAuth0Config } from "config/api";
import { LoadingProvider } from "context/LoadingContext";
import { ToastContext } from "context/ToastContext";
import { UserAccountProvider } from "context/UserAccountProvider";
import { useAnalytics } from "hooks/useAnalytics";
import { usePostHog } from "hooks/usePostHog";
import { Toast } from "primereact/toast";
import { useEffect, useRef } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
// Uncomment for 'free' predefined prime react theme
// import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
// GFE PrimeReact theme with primary color & font family replaced
import "./layout/styles/clear-theme-overrides.css";
import "./layout/styles/global.css";
import "primeflex/primeflex.css";
import styled from "styled-components";

import { routes } from "./routes/routes";

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

export function App() {
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const routeElements = useRoutes(routes);
  const { initializeGA } = useAnalytics();
  const { initializePostHog } = usePostHog();
  const environment = getEnvironment();

  const onRedirectCallback = (appState: any) => {
    navigate("/login", {
      state: { returnTo: appState?.returnTo || window.location.pathname },
    });
  };

  const auth0Config = getAuth0Config();

  useEffect(() => {
    initializeGA();
    if (environment === "production") {
      initializePostHog();
    }
  }, []);

  return (
    <ErrorBoundary>
      <Auth0Provider
        useRefreshTokens
        domain={auth0Config.domain}
        cacheLocation="localstorage"
        clientId={auth0Config.clientId}
        onRedirectCallback={onRedirectCallback}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: auth0Config.apiAudience,
        }}
      >
        <ContextCompose components={[UserAccountProvider, LoadingProvider]}>
          <ConfigContext.Provider value={(window as any).config}>
            <Toast ref={toast} baseZIndex={1000} position="bottom-right" />
            <ToastContext.Provider value={toast}>
              <WebSocketComponent>
                <Container>{routeElements}</Container>
              </WebSocketComponent>
            </ToastContext.Provider>
          </ConfigContext.Provider>
        </ContextCompose>
      </Auth0Provider>
    </ErrorBoundary>
  );
}
