import { Logo } from "layout/components/Logo";
import styled from "styled-components";

const ErrorText = styled.div`
  font-size: var(--fontsize-title);
  padding: var(--gutter-padding-horizontal);
`;

const LogoContainer = styled.div`
  padding: var(--gutter-padding-horizontal);
`;

export function Error() {
  return (
    <div className="flex card-container indigo-container flex-column">
      <LogoContainer className="flex justify-content-center">
        <Logo />
      </LogoContainer>
      <div className="flex justify-content-center">
        <ErrorText>
          Oops, looks like something went wrong! Go back and try your request
          again.
        </ErrorText>
      </div>
    </div>
  );
}
