import { IGFEOrderPricingRequest } from "types/Order/Order";
import {
  ICaseRateRuleset,
  ICaseRateRulesetCreateRequest,
  ICaseRateRulesetUpdateRequest,
} from "types/ProcedureDiscountRuleset/ProcedureDiscountRuleset";
import {
  IAllCaseRateRulesetResponse,
  ICaseRateRulesetResponse,
} from "types/ProcedureDiscountRuleset/ProcedureDiscountRulesetResponse";

import { api } from ".";

const accountQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    pricing: builder.mutation<any, IGFEOrderPricingRequest>({
      query: (pricing) => ({
        url: "pricing",
        method: "POST",
        body: pricing,
      }),
    }),
    getCaseRateRulesets: builder.query<
      IAllCaseRateRulesetResponse,
      null | void
    >({
      query: () => "pricing/caseRateRulesets",
    }),
    getCaseRateRulesetById: builder.query<
      ICaseRateRulesetResponse,
      string | undefined
    >({
      query: (id: string) => `pricing/caseRateRulesets/${id}`,
    }),
    createCaseRateRuleset: builder.mutation<
      ICaseRateRuleset,
      ICaseRateRulesetCreateRequest
    >({
      query: (newRuleset) => ({
        url: "pricing/caseRateRuleset",
        method: "POST",
        body: newRuleset,
      }),
    }),
    updateCaseRateRuleset: builder.mutation<
      ICaseRateRuleset,
      ICaseRateRulesetUpdateRequest
    >({
      query: (updatedRuleset) => ({
        url: `pricing/caseRateRuleset/${updatedRuleset.id}`,
        method: "PUT",
        body: updatedRuleset,
      }),
    }),
    deleteCaseRateRuleset: builder.mutation<any, string>({
      query: (id) => ({
        url: `pricing/caseRateRuleset/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  usePricingMutation,
  useGetCaseRateRulesetsQuery,
  useCreateCaseRateRulesetMutation,
  useDeleteCaseRateRulesetMutation,
  useUpdateCaseRateRulesetMutation,
  useGetCaseRateRulesetByIdQuery,
} = accountQueries;
