import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { ISPAConfig } from "config/api";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";

import { CardPageLayout, ContainerForm } from "../styled";

const getPublishableKey = () => {
  const config = window?.config as ISPAConfig;

  if (!config) {
    throw new Error("Config not found");
  }

  return config.stripe.publishableKey;
};

export default function CardDetails({
  orderInfo,
  onReturnClick,
}: {
  orderInfo: any;
  onReturnClick: () => void;
}) {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const [clientSecret, setClientSecret] = useState("");
  useEffect(() => {
    setStripePromise(loadStripe(getPublishableKey()));
  }, []);
  return (
    <CardPageLayout>
      {/* <Elements stripe={stripePromise} options={{ clientSecret }}> */}
      <ContainerForm>
        <h2>Fill In New Card Details</h2>
        {/* <PaymentElement /> */}

        <Button
          type="submit"
          data-testid="button-submit-payment-plan"
          className="w-full justify-content-center  mb-1"
          onClick={() => {}}
        >
          <span className="font-bold">Update Card Details</span>
        </Button>
        <div className="flex align-items-center justify-content-center mt-3">
          <Button
            className="p-button-text mb-0 p-button-secondary p-0"
            onClick={onReturnClick}
          >
            <p className="text-xs purple font-medium">Back to details</p>
          </Button>
        </div>
      </ContainerForm>
      {/* </Elements> */}
    </CardPageLayout>
  );
}
