import { useLoading } from "context/LoadingContext";
import dayjs from "dayjs";
import {
  AutoComplete,
  AutoCompleteChangeParams,
} from "primereact/autocomplete";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { useGenerateMonthlySalesReportMutation } from "store/queries/report";
import styled from "styled-components";

import { DownloadCSV } from "./DownloadCSV";

const StyledDialog = styled(Dialog)`
  width: 40vw;

  @media (max-width: 720px) {
    width: 90vw;
  }
`;

type MonthlyReportModalProps = {
  visible: boolean;
  onClose: () => void;
};

export function MonthlyReportModal({
  visible,
  onClose,
}: MonthlyReportModalProps) {
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [filteredMonths, setFilteredMonths] = useState<string[]>([]);
  const [months, setMonths] = useState<string[]>([]);

  const [generateReport, { isLoading, data }] =
    useGenerateMonthlySalesReportMutation();

  const { setIsLoading } = useLoading();

  const generateMonthSuggestions = () => {
    const months = [];
    const currentMonth = dayjs().month(); // Month is 0-indexed
    const currentYear = dayjs().year();

    // Generate month suggestions for the last 3 years up to the current month
    for (let year = currentYear - 3; year <= currentYear; year += 1) {
      const isCurrentYear = year === currentYear;
      const lastMonth = isCurrentYear ? currentMonth - 1 : 11; // For the current year, stop before the current month

      for (let month = 0; month <= lastMonth; month += 1) {
        const formattedMonth = dayjs(new Date(year, month)).format("MMMM YYYY");
        months.push(formattedMonth);
      }
    }

    const sortedMonths = months.sort(
      (a, b) =>
        dayjs(b, "MMMM YYYY").valueOf() - dayjs(a, "MMMM YYYY").valueOf()
    );

    setFilteredMonths(sortedMonths);
    setMonths(sortedMonths);

    return sortedMonths;
  };

  useEffect(() => {
    generateMonthSuggestions();
  }, []);

  const searchMonth = (event: { query: string }) => {
    setTimeout(() => {
      let filteredAccountsInternal;

      if (!event.query.trim().length) {
        filteredAccountsInternal = [...months];
      } else {
        filteredAccountsInternal = months.filter((aMonth: string) => {
          return aMonth.toLowerCase().includes(event.query.toLowerCase());
        });
      }

      setFilteredMonths(filteredAccountsInternal);
    }, 250);
  };

  const onAutoCompleteSelect = async (e: AutoCompleteChangeParams) => {
    setSelectedMonth(e.value);
    setIsLoading(true);

    if (e.value === "") return;

    try {
      const generateReportsForOrderTypes = ["PatientResponsibility"];

      const dateStart = dayjs(e.value).startOf("month").format("YYYY-MM-DD");

      const dateEnd = dayjs(e.value).endOf("month").format("YYYY-MM-DD");

      await Promise.all(
        generateReportsForOrderTypes.map((aOrderType) =>
          generateReport({
            dateStart,
            dateEnd,
            orderType: aOrderType,
          })
        )
      );

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error generating report", error);
    }
  };

  const onAutoCompleteChange = (e: AutoCompleteChangeParams) => {
    setSelectedMonth(e.value);
  };

  return (
    <StyledDialog
      closable
      onHide={onClose}
      visible={visible}
      header="Select the month to download report"
    >
      <AutoComplete
        dropdown
        className="w-full"
        value={selectedMonth}
        completeMethod={searchMonth}
        placeholder="Ex: January"
        suggestions={filteredMonths}
        onChange={onAutoCompleteChange}
        onSelect={onAutoCompleteSelect}
      />
      <div className="flex justify-content-end mt-3">
        <DownloadCSV
          data={{
            patientResponsibility:
              (data as any)?.data.patientResponsibility || [],
            clearPackage: (data as any)?.data.clearPackage || [],
          }}
          loading={isLoading}
          disabled={!selectedMonth}
        />
      </div>
    </StyledDialog>
  );
}
