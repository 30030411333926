import { FieldValues } from "react-hook-form";

export const mapFormToRequestBody = (props: FieldValues) => ({
  cptCodes: props.cptCodes,
  discountPercentForRank2: Number(props.discountPercentForRank2),
  discountPercentForRank3: Number(props.discountPercentForRank3),
  discountPercentForRank4: Number(props.discountPercentForRank4),
  discountPercentForRank5Plus: Number(props.discountPercentForRank5Plus),
  groupName: props.groupName,
  providerId: props.provider.value,
  payerId: props.payerName.value,
  allowOnDuplicateCptCodes: props.allowOnDuplicateCptCodes,
});

export const mapCaseRateFormToRequestBody = (props: FieldValues) => ({
  cptCodes: props.cptCodes,
  groupName: props.groupName,
  providerId: props.provider.value,
  payerId: props.payerName.value,
  excludedCptCodes: props.excludedCptCodes,
});

export const getRulesetList = (data: any, addDiscounts = true) => {
  const result = [] as any[];
  const providers = data.provider;
  const payers = data.payerName;
  const { allowOnDuplicateCptCodes } = data;
  const discountsToAdd = {
    discountPercentForRank2: data.discountPercentForRank2,
    discountPercentForRank3: data.discountPercentForRank3,
    discountPercentForRank4: data.discountPercentForRank4,
    discountPercentForRank5Plus: data.discountPercentForRank5Plus,
  };
  providers.forEach((provider: any) => {
    payers.forEach((payer: any) => {
      const newObj = {
        provider: {
          name: provider.name,
          value: provider.value,
        },
        payerName: {
          name: payer.name,
          value: payer.value,
        },
        groupName: data.groupName,
        cptCodes: data.cptCodes,
        excludedCptCodes: data.excludedCptCodes,
        allowOnDuplicateCptCodes,
        ...(addDiscounts && discountsToAdd),
      };
      result.push(newObj);
    });
  });
  return result;
};
