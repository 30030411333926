import { LoadingSpinner } from "components/LoadingSpinner";
import { useLoading } from "context/LoadingContext";
import { ToastContext } from "context/ToastContext";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { useMemo, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetAllPayersQuery,
  useDeletePayerMutation,
} from "store/queries/payers";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

function PayersTable() {
  const [selectedItems, setSelectedItems] = useState<{ id: string }[]>([]);
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();
  const { current: toastElement } = useContext(ToastContext);
  const { data: payers, isLoading, refetch } = useGetAllPayersQuery();
  const [deletePayer] = useDeletePayerMutation();

  const parsedPayers = useMemo(() => {
    if (!payers?.data) return [];
    return payers.data.map((aPayer: any) => {
      return {
        id: Number(aPayer.id),
        payerName: aPayer.name,
        parentName: aPayer.parent.join(","),
        pverifyCode: aPayer.pverifyId.join(","),
        medtelIds: aPayer.medtelIds.join(","),
      };
    });
  }, [payers]);

  const handleDeletePayers = async () => {
    setIsLoading(true);
    try {
      const deletePromises = selectedItems.map((item) => deletePayer(item.id));
      await Promise.all(deletePromises);
      await refetch();
      toastElement?.show({
        severity: "success",
        summary: "Deleted!",
        detail: "Payer(s) has been deleted successfully.",
      });
    } catch (error) {
      await refetch();
      toastElement?.show({
        severity: "error",
        summary: "Error",
        detail: "Error while deleting payer(s).",
      });
    } finally {
      setIsLoading(false);
      setSelectedItems([]);
    }
  };

  function payerAction(payerId: string) {
    return (
      <Row>
        <Button
          className="p-button-link"
          onClick={() => navigate(`/payer-rules/payer/edit/${payerId}`)}
        >
          <p className="small-text">Edit</p>
        </Button>
      </Row>
    );
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <DataTable
        rows={10}
        paginator
        stripedRows
        className="p-fluid"
        filterDisplay="row"
        value={parsedPayers}
        responsiveLayout="scroll"
        style={{ cursor: "pointer" }}
        selection={selectedItems}
        emptyMessage="No payers to show."
        selectionMode="single"
        onSelectionChange={(event) => setSelectedItems(event.value)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
        <Column
          filter
          field="id"
          style={{ minWidth: "60px", maxWidth: "200px" }}
          header="Payer Id"
          dataType="numeric"
          filterPlaceholder="Search by id"
        />
        <Column
          filter
          field="payerName"
          style={{ minWidth: "300px" }}
          header="Payer Name"
          filterPlaceholder="Search by payer name"
        />
        <Column
          filter
          field="parentName"
          className="overflow-hidden white-space-nowrap text-overflow-ellipsis"
          style={{ minWidth: "300px" }}
          header="Parent Name"
          filterPlaceholder="Search by parent name"
        />
        <Column
          filter
          field="pverifyCode"
          className="overflow-hidden white-space-nowrap text-overflow-ellipsis"
          style={{ minWidth: "280px", maxWidth: "280px" }}
          header="Pverify Code"
          filterPlaceholder="Search by Pverify code"
        />
        <Column
          filter
          field="medtelIds"
          className="overflow-hidden white-space-nowrap text-overflow-ellipsis"
          style={{ minWidth: "280px", maxWidth: "280px" }}
          header="Medtel Ids"
          filterPlaceholder="Search by Medtel Id"
        />
        <Column
          style={{ maxWidth: "100px" }}
          field="viewEditPayer"
          body={(row) => payerAction(row.id)}
        />
      </DataTable>
      <div className="flex justify-content-end">
        <Button
          label="Delete"
          className="p-button-outlined mt-4"
          disabled={!selectedItems.length}
          onClick={() => {
            confirmDialog({
              header: "Confirmation",
              icon: "pi pi-exclamation-triangle",
              message: `Are you sure you want delete selected payer(s)?`,
              accept: handleDeletePayers,
            });
          }}
        />
      </div>
    </>
  );
}

export default PayersTable;
