import { ItemBar } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { useUserWithAccount } from "context/UserAccountProvider";
import { useQuery } from "hooks/useQuery";
import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledH2 = styled.h2`
  margin-bottom: ${sizer(8)};
`;

export function Header() {
  const navigate = useNavigate();
  const { current: toastElement } = useContext(ToastContext);
  const query = useQuery();
  const accountId = query.get("accountId") || undefined;
  const { accounts } = useUserWithAccount();

  const accountName = useMemo(() => {
    if (!accounts || !accountId) return "";
    const account = accounts.data.find((acc) => acc.id === accountId);
    return account?.name || "";
  }, [accounts]);

  const backToOrderList = () => {
    navigate(`/hospitals/view/${accountId}?tab=0`);
    toastElement?.show({
      severity: "warn",
      summary: "Exit creation",
      detail: "Progress not saved",
    });
  };

  return (
    <ItemBar>
      <StyledH2>{accountName}</StyledH2>

      <div data-testid="header" className="flex flex-1 justify-content-end">
        <Button label="Cancel" onClick={backToOrderList} />
      </div>
    </ItemBar>
  );
}
