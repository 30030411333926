import useFormValidation from "hooks/useFormValidation";
import { Label } from "layout/typography/Label";
import { Chips } from "primereact/chips";
import { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { formValidationConstants } from "../helpers/formValidations";

const Title = styled.h3`
  margin-bottom: 16px;
`;

export function Parent() {
  const {
    control,
    trigger,
    formState: { errors },
    watch,
  } = useFormContext();
  const [parent, setParent] = useState<string[]>([]);
  const { getFormErrorMessage, handleOnBlurField } = useFormValidation();
  const watchedParent = watch("parent");

  useEffect(() => {
    if (watchedParent) {
      setParent(watchedParent);
    }
  }, [watchedParent]);

  return (
    <div className="block bg-white border-round p-4 mb-4">
      <Title>Parent</Title>
      <Label htmlFor="cptCodes">Add Parent</Label>
      <Controller
        name="parent"
        control={control}
        rules={{
          required: formValidationConstants.PARENT.PARENT,
        }}
        render={({ field: { onChange, onBlur } }) => (
          <>
            <Chips
              id="cptCodes"
              value={parent}
              className="w-full mt-2"
              onChange={(e: any) => {
                setParent(e.value.map((item: any) => item.trim()));
                onChange(e.value.map((item: any) => item.trim()));
              }}
              separator=","
              onBlur={() => {
                handleOnBlurField({ onBlur, field: "parent", trigger });
              }}
            />
            {getFormErrorMessage("parent", errors)}
          </>
        )}
      />
    </div>
  );
}
