import { accountFeeType } from "constants/fee";
import { orderTypeConstants } from "constants/order";

export function getFeeTypeForOrder(orderType: string): string | null {
  switch (orderType) {
    case orderTypeConstants.PATIENT_RESPONSIBILITY:
      return accountFeeType.CTFForPR;
    case orderTypeConstants.BUNDLED:
      return accountFeeType.ClearTransactionFee;
    case orderTypeConstants.GFE:
      return accountFeeType.ClearHealthFeeForGFE;
    default:
      return null;
  }
}
