import { IProvider } from "types/Provider/Provider";

import { api } from ".";

const providersQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllProviders: builder.query<{ data: IProvider[] }, void>({
      query: () => `providers`,
    }),
    getProvider: builder.query<{ data: IProvider }, string | undefined>({
      query: (id) => `providers/${id}`,
    }),
    getProvidersByAccount: builder.query<
      { data: IProvider[]; total: number },
      { accountId: string | undefined; page?: number; pageSize?: number }
    >({
      query: ({ accountId, page, pageSize }) => {
        if (page !== undefined && pageSize !== undefined) {
          return `providers/account/${accountId}?page=${page}&pageSize=${pageSize}`;
        }
        return `providers/account/${accountId}`;
      },
    }),
    deleteProvider: builder.mutation<any, string>({
      query: (id) => ({
        url: `providers/${id}`,
        method: "DELETE",
      }),
    }),
    createProvider: builder.mutation<any, any>({
      query: (body) => ({
        url: `providers`,
        method: "POST",
        body,
      }),
    }),
    updateProvider: builder.mutation<
      any,
      { id: string | undefined; body: any }
    >({
      query: ({ id, body }) => ({
        url: `providers/${id}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetProvidersByAccountQuery,
  useGetAllProvidersQuery,
  useGetProviderQuery,
  useDeleteProviderMutation,
  useUpdateProviderMutation,
  useCreateProviderMutation,
} = providersQueries;
