import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onTokenChange } from "store/slices/auth";
import styled from "styled-components";

type LogoProps = {
  variant?: "unlogged";
  accountLogo?: string | null;
};

const StyledButton = styled.button`
  all: unset;

  img {
    max-width: 160px;
    max-height: 72px;
    object-fit: contain;

    @media (max-width: 720px) {
      height: 100%;
    }
  }
`;

export function Logo({ variant, accountLogo }: LogoProps): JSX.Element {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const handleOnClickToRedirect = async () => {
    if (variant === "unlogged") {
      const token = await getAccessTokenSilently();

      if (token) {
        dispatch(onTokenChange({ token }));
      }
    }

    setTimeout(() => {
      navigate("/");
    }, 200);
  };

  return (
    <div className="flex flex-row	align-items-center">
      <StyledButton
        type="button"
        onClick={handleOnClickToRedirect}
        className="cursor-pointer	flex align-items-center"
      >
        <img alt="Hospital Logo" src={accountLogo || "/logo.svg"} />
      </StyledButton>
    </div>
  );
}
