import { api } from ".";

const patientQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    notify: builder.mutation<any, string>({
      query: (orderId: string) => ({
        method: "POST",
        url: `/communications/${orderId}/notify`,
      }),
    }),
    internalNotification: builder.mutation<
      any,
      { orderId: string; internalEvent: string }
    >({
      query: ({
        orderId,
        internalEvent,
      }: {
        orderId: string;
        internalEvent: string;
      }) => ({
        method: "POST",
        url: `/communications/${orderId}/internal-event`,
        body: { internalEvent },
      }),
    }),
  }),
});

export const { useNotifyMutation, useInternalNotificationMutation } =
  patientQueries;
