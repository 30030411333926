import { Label } from "layout/typography/Label";
import { AutoComplete } from "primereact/autocomplete";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useGetAllAccountsQuery } from "store/queries/account";
import { IAccount } from "types/Account/Account";

export function AccountIds() {
  const [filteredAccountIds, setFilteredAccountIds] = useState<IAccount[]>([]);
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([]);

  const { data, isFetching } = useGetAllAccountsQuery();
  const { setValue, watch } = useFormContext();

  const accounts = data?.data || [];

  const formAccountIds = watch("accountIds");

  useEffect(() => {
    if (formAccountIds?.length && !isFetching) {
      const accountIdsAlreadySelected = accounts.filter((account) =>
        formAccountIds.includes(account.id)
      );

      setSelectedAccountIds(
        accountIdsAlreadySelected.map(
          (account: IAccount) => `${account.id} - ${account.name}`
        )
      );
    }
  }, [formAccountIds, isFetching]);

  const parsedFilteredAccountIds = useMemo(() => {
    return filteredAccountIds.map(
      (account: IAccount) => `${account.id} - ${account.name}`
    );
  }, [filteredAccountIds]);

  const search = (event: { query: string }) => {
    let filteredAccountIds;

    if (!event.query.trim().length) {
      filteredAccountIds = [...accounts];
    } else {
      filteredAccountIds = accounts.filter((account: IAccount) => {
        return `${account.id} - ${account.name}`
          .toLowerCase()
          .includes(event.query.toLowerCase());
      });
    }

    setFilteredAccountIds(filteredAccountIds);
  };

  return (
    <div className="formgrid grid w-100">
      <div className="field p-fluid col-12">
        <Label htmlFor="code">Account IDs</Label>

        <AutoComplete
          multiple
          dropdown
          id="accountIds"
          className="w-full"
          completeMethod={search}
          value={selectedAccountIds}
          data-testid="autocomplete"
          placeholder="Select Account IDs..."
          suggestions={parsedFilteredAccountIds}
          onChange={(e) => {
            const accountIds = e.value.map(
              (accountId: string) => accountId.split(" ")[0]
            );
            setValue("accountIds", accountIds);
            setSelectedAccountIds(e.value);
          }}
        />
      </div>
    </div>
  );
}
