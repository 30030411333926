import orderPaidImage from "assets/images/order_paid.svg";
import { orderStatusConstants } from "constants/order";
import { sizer } from "layout/styles/styled/sizer";
import { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 11rem);
  display: flex;
  flex-direction: column;
  gap: ${sizer(4)};
  justify-content: center;
  align-items: center;
  padding: 48px 24px;
  p {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  img {
    width: 200px;
    height: 200px;
  }
`;

export function OrderPaidSection({ status }: { status: string }) {
  const [text, setText] = useState<string>("");

  useEffect(() => {
    if (status === orderStatusConstants.PAID) {
      setText("This order has already been paid");
    }
    if (status === orderStatusConstants.PARTIALLY_PAID) {
      setText("This order is partially paid, and autopayment is enabled.");
    }
  }, [status]);
  return (
    <Container>
      <img src={orderPaidImage as string} alt="Order paid" />
      <p>{text}</p>
    </Container>
  );
}
