import { IPayment, IUpdatePayment } from "types/Payment";

import { publicApi, api } from ".";

const publicApiPaymentQueries = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    updatePaymentHospitalVerification: builder.mutation<
      IPayment,
      IUpdatePayment
    >({
      query: ({ paymentId, hospitalCheck }) => ({
        method: "PUT",
        url: `/payment/${paymentId}`,
        body: { hospitalVerification: hospitalCheck },
      }),
    }),
  }),
});

const apiPaymentQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    markPaymentReadyForTransfers: builder.mutation<IPayment, any>({
      query: (paymentId: string) => ({
        url: `payment/${paymentId}/mark_ready_for_transfers`,
        method: "PATCH",
        body: {},
      }),
    }),
  }),
});

export const {
  useUpdatePaymentHospitalVerificationMutation,
  useMarkPaymentReadyForTransfersMutation,
} = { ...publicApiPaymentQueries, ...apiPaymentQueries };
