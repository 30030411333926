import { contactMethodConstants } from "constants/contactMethod";
import { validationConstants } from "constants/formValidation";
import { FieldErrors, UseFormSetError, UseFormTrigger } from "react-hook-form";
import { useVerifyPatientPhoneMutation } from "store/queries/patient";

export default function useFormValidation() {
  const [verifyPatientPhoneNumber] = useVerifyPatientPhoneMutation();

  async function handleOnBlurField({
    onBlur,
    field,
    trigger,
  }: {
    onBlur: () => void;
    field: string;
    trigger: UseFormTrigger<any>;
  }) {
    trigger(field);
    onBlur();
  }

  function getFormErrorMessage(name: string, errors: FieldErrors<any>) {
    return (
      errors[name] && (
        <small className="p-error">{errors[name]?.message as string}</small>
      )
    );
  }

  function handleEmailPhoneValidation(
    contactMethodSelected: string,
    field: "phone" | "email"
  ) {
    const isEmailRequired =
      contactMethodSelected === contactMethodConstants.EMAIL ||
      contactMethodSelected === contactMethodConstants.BOTH ||
      contactMethodSelected === null;

    const isPhoneRequired =
      contactMethodSelected === contactMethodConstants.PHONE ||
      contactMethodSelected === contactMethodConstants.BOTH;

    if (isPhoneRequired && field === "phone") {
      return validationConstants.PATIENT_INFO_FORM.PHONE;
    }

    if (isEmailRequired && field === "email") {
      return validationConstants.PATIENT_INFO_FORM.EMAIL;
    }

    return false;
  }

  async function isValidPhoneNumber(
    contactMethodSelected: string,
    phoneNumber: string | undefined,
    setError: UseFormSetError<any>
  ): Promise<boolean | string> {
    const phoneIsNotRequired = contactMethodSelected === "Email";

    if (phoneIsNotRequired) return true;

    if (!phoneNumber) return false;

    const hasTenDigits = phoneNumber.replace(/\D/g, "").length === 10;
    if (!hasTenDigits) return false;

    const response = await verifyPatientPhoneNumber(phoneNumber);
    const isValid = (response as any).data?.data.valid || false;

    if (isValid) {
      return true;
    }

    setError("phoneNumber", {
      type: "manual",
      message: "Invalid phone number",
    });

    return false;
  }

  return {
    handleEmailPhoneValidation,
    isValidPhoneNumber,
    handleOnBlurField,
    getFormErrorMessage,
  };
}
