import { IMonthlyReportResponse } from "types/Report/Report";
import { IReportResponse } from "types/Report/ReportResponse";

import { api } from ".";

const reportsQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.query<IReportResponse, void>({
      query: () => "/reports/all",
    }),

    generateNewReport: builder.mutation<{ reportId: string; file: File }, any>({
      query: ({ reportId, file }) => {
        const formData = new FormData();

        formData.append("file", file);

        return {
          url: `/reports/${reportId}`,
          method: "PATCH",
          body: formData,
          formData: true,
        };
      },
    }),
    generateMonthlySalesReport: builder.mutation<
      IMonthlyReportResponse,
      { dateStart: any; dateEnd: any; orderType: any }
    >({
      query: ({ dateStart, dateEnd, orderType }) => {
        return {
          url: `/reports/generate/monthly-sales`,
          method: "POST",
          body: { dateStart, dateEnd, orderType },
          formData: true,
        };
      },
    }),
  }),
});

export const {
  useGetReportsQuery,
  useGenerateNewReportMutation,
  useGenerateMonthlySalesReportMutation,
} = reportsQuery;
