/* eslint-disable no-restricted-syntax */

import { mapValues } from "lodash";

export function transformEmptyStringsToNull(obj: any) {
  return mapValues(obj, (value) =>
    typeof value === "string" && value.trim() === "" ? null : value
  );
}

export function filterUniqueObjects<T>(array: T[], keys: (keyof T)[]): T[] {
  const uniqueValues = new Set<string>();
  const result: T[] = [];

  for (const obj of array) {
    const uniqueIdentifier = keys.map((key) => obj[key]).join("|");

    if (!uniqueValues.has(uniqueIdentifier)) {
      uniqueValues.add(uniqueIdentifier);
      result.push(obj);
    }
  }

  return result;
}
