import { api } from ".";

export interface ILoginRequest {
  email: string;
  password: string;
}
export interface ILoginResponse {
  data: any;
}

export interface IPreLoginResponseSendCodeAttempts {
  attempt_sid: string;
  channel: string;
  time: string;
}

export type PostPreloginApiResponse = {
  email: string;
  message: string;
  sendCodeAttempts: Array<IPreLoginResponseSendCodeAttempts>;
};

export type PostPreloginApiArg = {
  token: string;
};

export type PostPreloginVerifyApiResponse = unknown;

export type PostPreloginVerifyApiArg = {
  token: string;
  code: string;
};

export const authQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: ({ email, password }) => ({
        url: "/login",
        method: "POST",
        body: {
          email,
          password,
        },
      }),
    }),
    prelogin: builder.mutation<PostPreloginApiResponse, PostPreloginApiArg>({
      query: (queryArg) => ({
        url: `/prelogin`,
        method: "POST",
        body: queryArg,
      }),
    }),
    preloginVerify: builder.mutation<
      PostPreloginVerifyApiResponse,
      PostPreloginVerifyApiArg
    >({
      query: (queryArg) => ({
        url: `/prelogin/verify`,
        method: "POST",
        body: queryArg,
      }),
    }),
    loginWithOrderIdAndDOB: builder.mutation<
      { token: string },
      { orderId: string; dateOfBirth: string }
    >({
      query: (dataToAuth) => ({
        url: "/patients/login",
        method: "POST",
        body: dataToAuth,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  usePreloginMutation,
  usePreloginVerifyMutation,
  useLoginWithOrderIdAndDOBMutation,
} = authQueries;
