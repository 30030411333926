import { ItemBar } from "components/ItemBar";
import { events } from "constants/tagManager";
import { ToastContext } from "context/ToastContext";
import { useAnalytics } from "hooks/useAnalytics";
import { mapFormToRequestBody } from "pages/CreateOrder/utils/helpers";
import { Button } from "primereact/button";
import { useContext, useMemo } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { useSaveDraftMutation } from "store/queries/order";

export function SaveDraft() {
  const [saveDraft] = useSaveDraftMutation();
  const { getValues } = useFormContext();
  const { pageViewEvents } = useAnalytics();
  const { isValid } = useFormState();
  const navigate = useNavigate();
  const { selectedDraftOrder, integrationErrors } = useSelector(
    (state: RootState) => state.orderSlice
  );
  const { loadingPriceBreakdown } = useSelector(
    (state: RootState) => state.serviceDetailsSlice
  );
  const { current: toastElement } = useContext(ToastContext);

  const integrationErrorsParsed = useMemo(() => {
    return integrationErrors
      .filter((error) => error.active)
      .reduce((acc, error) => {
        acc[error.errorType] = error.message;
        return acc;
      }, {} as { [key: string]: string });
  }, [integrationErrors]);

  const updateDraftOrder = () => {
    const requestBody = mapFormToRequestBody(getValues());

    saveDraft({
      ...selectedDraftOrder,
      ...requestBody,
      status: "Draft",
      patient: { ...selectedDraftOrder.patient, ...requestBody.patient },
      integrationErrors: integrationErrorsParsed,
      updatedAt: new Date().toISOString(),
    })
      .then(() => {
        pageViewEvents(
          {
            accountName: selectedDraftOrder.account.name,
            orderId: selectedDraftOrder.id,
            status: "Draft",
          },
          events.ORDER_UPDATED
        );

        toastElement?.show({
          severity: "success",
          summary: "Pending Review Order changed",
          detail: "Pending Review Order changed successfully but not approved",
        });
        navigate(`/hospitals/view/${selectedDraftOrder.account.id}?tab=0`);
      })
      .catch((error) => {
        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail: `Message: ${error}`,
        });
      });
  };

  return (
    <ItemBar>
      <div
        data-testid="saveDraft_ReviewOrder"
        className="flex flex-1 justify-content-start"
      >
        <Button
          label="Save Draft"
          onClick={() => updateDraftOrder()}
          loading={loadingPriceBreakdown}
          disabled={!isValid}
        />
      </div>
    </ItemBar>
  );
}
