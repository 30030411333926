import { LoadingSpinner } from "components/LoadingSpinner";
import { useLoading } from "context/LoadingContext";
import { ToastContext } from "context/ToastContext";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { useMemo, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetAllDiscountRulesetsQuery,
  useDeleteDiscountRulesetMutation,
} from "store/queries/procedureDiscountRuleset";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const ColumnWrapper = styled.div`
  display: block;
  max-height: 20px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

function PayerRulesTable() {
  const [selectedItems, setSelectedItems] = useState<{ id: string }[]>([]);
  const { setIsLoading } = useLoading();
  const [deleteDiscountRuleset] = useDeleteDiscountRulesetMutation();
  const {
    data: discountRulesets,
    isLoading,
    refetch,
  } = useGetAllDiscountRulesetsQuery();

  const { current: toastElement } = useContext(ToastContext);
  const navigate = useNavigate();
  const parsedDiscountRulesets = useMemo(() => {
    if (!discountRulesets?.data) return [];
    return discountRulesets.data.map((aDiscountRuleset) => {
      return {
        ...aDiscountRuleset,
        providerName: aDiscountRuleset.provider.name,
        payerName: aDiscountRuleset?.payer?.name,
        allowOnDuplicateCptCodes: aDiscountRuleset.allowOnDuplicateCptCodes
          ? "Enabled"
          : "Disabled",
      };
    });
  }, [discountRulesets]);

  function payerRuleAction(payerRuleId: string) {
    return (
      <Row>
        <Button
          className="p-button-link"
          onClick={() => navigate(`/payer-rules/mppr/edit/${payerRuleId}`)}
        >
          <p className="small-text">Edit</p>
        </Button>
      </Row>
    );
  }

  const cptCodesTransformer = (cptCodes: string[]) => {
    const allCodes = cptCodes.join(", ");
    return <ColumnWrapper>{allCodes}</ColumnWrapper>;
  };

  const handleDeletePayerRules = async () => {
    setIsLoading(true);
    try {
      const deletePromises = selectedItems.map((item) =>
        deleteDiscountRuleset(item.id)
      );
      await Promise.all(deletePromises);
      await refetch();
      toastElement?.show({
        severity: "success",
        summary: "Deleted!",
        detail: "Payer Rules(s) has been deleted successfully.",
      });
    } catch (error) {
      await refetch();
      toastElement?.show({
        severity: "error",
        summary: "Error",
        detail: "Error while deleting payer rules",
      });
    } finally {
      setIsLoading(false);
      setSelectedItems([]);
    }
  };

  const filterTemplate = (options: any) => {
    const filterOptions = ["Enabled", "Disabled"];
    return (
      <Dropdown
        value={options.value}
        options={filterOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <DataTable
        rows={10}
        paginator
        stripedRows
        className="p-fluid"
        filterDisplay="row"
        value={parsedDiscountRulesets}
        responsiveLayout="scroll"
        style={{ cursor: "pointer" }}
        selection={selectedItems}
        emptyMessage="No payer rules to show."
        selectionMode="single"
        onSelectionChange={(event) => setSelectedItems(event.value)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
        <Column
          filter
          field="providerName"
          style={{ minWidth: "300px" }}
          header="Provider Name"
          filterPlaceholder="Search by provider name"
        />
        <Column
          filter
          field="payerName"
          style={{ minWidth: "300px" }}
          header="Payer Name"
          filterPlaceholder="Search by payer name"
        />
        <Column
          filter
          field="groupName"
          style={{ minWidth: "300px" }}
          header="Rule Name"
          filterPlaceholder="Search by rule name"
        />
        <Column
          filter
          field="cptCodes"
          body={(row) => cptCodesTransformer(row.cptCodes)}
          style={{ minWidth: "280px", maxWidth: "280px" }}
          header="CPT Codes"
          filterPlaceholder="Search by CPT code"
        />
        <Column
          filter
          field="discountPercentForRank2"
          dataType="numeric"
          style={{ minWidth: "250px" }}
          header="Discount Percent for Rank 2"
          filterPlaceholder="Search by discount percent"
        />
        <Column
          filter
          field="discountPercentForRank3"
          dataType="numeric"
          style={{ minWidth: "250px" }}
          header="Discount Percent for Rank 3"
          filterPlaceholder="Search by discount percent"
        />
        <Column
          filter
          field="discountPercentForRank4"
          dataType="numeric"
          style={{ minWidth: "250px" }}
          header="Discount Percent for Rank 4"
          filterPlaceholder="Search by discount percent"
        />
        <Column
          filter
          field="discountPercentForRank5Plus"
          dataType="numeric"
          style={{ minWidth: "250px" }}
          header="Discount Percent for Rank 5"
          filterPlaceholder="Search by discount percent"
        />
        <Column
          style={{ maxWidth: "250px", minWidth: "220px" }}
          field="allowOnDuplicateCptCodes"
          header="Allow Duplicate CPT Codes"
          filterPlaceholder="Search by type"
          filterElement={filterTemplate}
          showFilterMenu={false}
          filter
        />
        <Column
          style={{ maxWidth: "100px" }}
          field="viewEditPayerRule"
          body={(row) => payerRuleAction(row.id)}
        />
      </DataTable>
      <div className="flex justify-content-end">
        <Button
          label="Delete"
          className="p-button-outlined mt-4"
          disabled={!selectedItems.length}
          onClick={() => {
            confirmDialog({
              header: "Confirmation",
              icon: "pi pi-exclamation-triangle",
              message: `Are you sure you want delete selected payer rules?`,
              accept: handleDeletePayerRules,
            });
          }}
        />
      </div>
    </>
  );
}

export default PayerRulesTable;
