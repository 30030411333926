export const InvalidCodeErrorConst = {
  title: "Invalid Code",
  message: "The code you entered is invalid or expired. Please try again.",
};

export const TooManyAttemptsForCodeErrorConst = {
  title: "Too Many Attempts",
  message:
    "You have exceeded the maximum number of attempts. Please try again after 10 minutes.",
};

export const GenericLoginErrorConst = {
  title: "Error",
  message:
    "Something went wrong with processing your login request, please sign in again",
};

export const TooManyRequestsForCodeErrorConst = {
  title: "Too many requests",
  message:
    "You have exceeded the maximum number of code requests. Please try again after 10 minutes.",
};
