import { createSlice } from "@reduxjs/toolkit";
import { VerificationSteps } from "pages/VerificationPage/utils/constants";
import type { RootState } from "store";
import { IAccount } from "types/Account/Account";

interface IVerificationStateProps {
  verificationStep: VerificationSteps;
  accountInfo: IAccount;
}

const initialState: IVerificationStateProps = {
  verificationStep: VerificationSteps.DOB_AUTH,
  accountInfo: {} as IAccount,
};

export const verificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {
    onVerificationStepChange: (
      state,
      action: { payload: { step: VerificationSteps } }
    ) => {
      state.verificationStep = action.payload.step;
    },
    onChangeAccountInfo: (
      state,
      action: { payload: { accountInfo: IAccount } }
    ) => {
      state.accountInfo = action.payload.accountInfo;
    },
  },
});

export const { onVerificationStepChange, onChangeAccountInfo } =
  verificationSlice.actions;

export const verificationSelector = (state: RootState) =>
  state.verificationSlice;

export default verificationSlice.reducer;
