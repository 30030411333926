import useDocumentTitle from "hooks/useDocumentTitle";
import { useForm, FormProvider } from "react-hook-form";

import { Header } from "./components/Header";
import { MedtelIds } from "./components/MedtelIds";
import { Parent } from "./components/Parent";
import { PayerName } from "./components/PayerName";
import { PverifyId } from "./components/PverifyId";
import { SubmitPayer } from "./components/SubmitPayer";

export function CreatePayerPage() {
  useDocumentTitle("Create Payer");

  const methods = useForm({
    defaultValues: {
      name: "",
      parent: [],
      pverifyId: [],
    },
  });
  return (
    <FormProvider {...methods}>
      <Header isPayer />
      <PayerName />
      <Parent />
      <PverifyId />
      <MedtelIds />
      <SubmitPayer />
    </FormProvider>
  );
}
