import { ToastContext } from "context/ToastContext";
import JSZip from "jszip";
import { isEmpty } from "lodash";
import { Button } from "primereact/button";
import { useContext } from "react";
import { IMonthlyReportResponse } from "types/Report/Report";

import { convertDataToCSV } from "../helpers";

export function DownloadCSV({
  data,
  loading,
  disabled,
}: {
  data: IMonthlyReportResponse | undefined;
  loading: boolean;
  disabled: boolean;
}) {
  const { current: toastElement } = useContext(ToastContext);

  const downloadCSV = (
    orderType: "patientResponsibility" | "clearPackage"
  ): Blob | null => {
    const csvString = convertDataToCSV(orderType, data);
    if (!csvString) return null;

    return new Blob([csvString], { type: "text/csv" });
  };

  const downloadZip = async (files: { blob: Blob; name: string }[]) => {
    const zip = new JSZip();
    files.forEach((file) => {
      zip.file(file.name, file.blob);
    });

    const zipBlob = await zip.generateAsync({ type: "blob" });
    const url = URL.createObjectURL(zipBlob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `monthly_sales_${new Date().getTime()}.zip`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleDownload = () => {
    const filesToZip: { blob: Blob; name: string }[] = [];

    if (isEmpty(data?.patientResponsibility) && isEmpty(data?.clearPackage)) {
      toastElement?.show({
        severity: "warn",
        summary: "No records!",
        detail: `No records found for Patient Responsibility and Clear Package for the given month.`,
      });
      return;
    }
    if (!isEmpty(data?.clearPackage)) {
      const clearPackageBlob = downloadCSV("clearPackage");
      if (clearPackageBlob) {
        filesToZip.push({
          blob: clearPackageBlob,
          name: `monthly_sales_report_clear_package_${new Date().getTime()}.csv`,
        });
      }
    } else {
      toastElement?.show({
        severity: "warn",
        summary: "No records!",
        detail: `No records found for Clear Package for the given month.`,
      });
    }
    if (!isEmpty(data?.patientResponsibility)) {
      const patientResponsibilityBlob = downloadCSV("patientResponsibility");
      if (patientResponsibilityBlob) {
        filesToZip.push({
          blob: patientResponsibilityBlob,
          name: `monthly_sales_report_patient_responsibility_${new Date().getTime()}.csv`,
        });
      }
    } else {
      toastElement?.show({
        severity: "warn",
        summary: "No records!",
        detail: `No records found for Patient Responsibility for the given month.`,
      });
    }

    if (!isEmpty(filesToZip)) {
      downloadZip(filesToZip);
    }
  };

  return (
    <Button
      data-testid="download-sales-report-button"
      onClick={handleDownload}
      label="Download Report"
      loading={loading}
      disabled={disabled}
    />
  );
}
