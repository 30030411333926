import { View, Text } from "@react-pdf/renderer";

import { styles } from "../styles";

export function Footer({ facilityName }: { facilityName: string | undefined }) {
  return (
    <View style={styles.section} fixed>
      <View style={styles.centralizeView}>
        <Text
          render={({ pageNumber, totalPages }) =>
            `${facilityName} | Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </View>
    </View>
  );
}
