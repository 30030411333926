import { sizer } from "layout/styles/styled/sizer";
import { HTMLAttributes } from "react";
import styled from "styled-components";

export enum ChipVariants {
  WARNING = "warning",
  EXPIRED = "expired",
  SUCCESS = "success",
}

type ChipProps = {
  children: React.ReactNode;
  variant?: ChipVariants;
};

type ContainerProps = HTMLAttributes<HTMLDivElement> & {
  variant: ChipVariants;
};

function getColorsByVariant({ variant }: { variant: ChipVariants }) {
  switch (variant) {
    case ChipVariants.SUCCESS:
      return `color: var(--chip-text-success); background-color: var(--chip-background-success);`;
    case ChipVariants.EXPIRED:
      return `color: var(--chip-text-expired); background-color: var(--chip-background-expired);`;
    default:
      return `color: var(--chip-text-warning); background-color: var(--chip-background-warning);`;
  }
}

const Container = styled.div<ContainerProps>`
  padding: ${sizer(1, 2)};
  font-size: var(--fontsize-contents);

  width: fit-content;
  height: fit-content;

  font-weight: var(--font-weight-medium);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${sizer(25)};
  ${({ variant }) => getColorsByVariant({ variant })};
`;

export function Chip({ variant = ChipVariants.WARNING, children }: ChipProps) {
  return <Container {...{ variant }}>{children}</Container>;
}
