import { useSelector } from "react-redux";
import { orderSelector } from "store/slices/order";

import { DOBAuth } from "./components/DOBAuth";
import { Update } from "./components/Update";
import { Steps } from "./utils/constants";

type OrderUpdateProps = {
  variant: Steps;
};

export function OrderUpdate({ variant }: OrderUpdateProps) {
  const { orderUpdateStep } = useSelector(orderSelector);

  switch (orderUpdateStep) {
    case Steps.ORDER_UPDATE:
      return <Update />;

    default:
      return <DOBAuth {...{ variant }} />;
  }
}
