import { View, Image, Text } from "@react-pdf/renderer";

import { addressIcon } from "../assets/addressIcon";
import { phoneIcon } from "../assets/phoneIcon";
import { styles } from "../styles";

export function Header({
  facilityLogo,
  fullAddress,
  phoneNumber,
}: {
  facilityLogo: string | undefined;
  fullAddress: string | undefined;
  phoneNumber: string | undefined;
}) {
  return (
    <View style={styles.header} fixed>
      <View style={styles.imageWrapper}>
        <Image style={styles.image} src={facilityLogo} />
      </View>
      <View style={styles.addressContainer}>
        <Image
          style={{
            height: 15,
            width: "auto",
          }}
          src={addressIcon}
        />
        <Text style={{ marginRight: 10 }}>{fullAddress}</Text>
        <Image style={{ height: 12, width: "auto" }} src={phoneIcon} />
        <Text style={{ marginLeft: 2 }}>{phoneNumber}</Text>
      </View>
    </View>
  );
}
