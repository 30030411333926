export function Insurance({ ...rest }) {
  return (
    <svg
      width="683"
      height="683"
      viewBox="0 0 683 683"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_402_20)">
        <mask
          id="mask0_402_20"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="683"
          height="683"
        >
          <path
            d="M0 -0.00012207H682.667V682.667H0V-0.00012207Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_402_20)">
          <path
            d="M610.64 185.474C610.311 168.123 609.996 151.731 609.996 135.875C609.996 123.451 599.927 113.38 587.501 113.38C491.3 113.38 418.056 85.731 357.003 26.3697C348.269 17.875 334.371 17.879 325.64 26.3697C264.593 85.731 191.361 113.38 95.1666 113.38C82.7426 113.38 72.6707 123.451 72.6707 135.875C72.6707 151.735 72.36 168.132 72.0267 185.487C68.944 346.958 64.7213 568.102 333.953 661.424C336.341 662.252 338.831 662.667 341.32 662.667C343.809 662.667 346.301 662.252 348.687 661.424C617.94 568.098 613.724 346.948 610.64 185.474Z"
            stroke="black"
            strokeWidth="40"
            strokeMiterlimit="10"
          />
          <path
            d="M381.25 294.738V210.377H301.429V294.738H217.062V374.559H301.429V458.919H381.25V374.559H465.605V294.738H381.25Z"
            stroke="black"
            strokeWidth="40"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_402_20">
          <rect width="682.667" height="682.667" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
