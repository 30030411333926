import { times } from "lodash";
import { Card } from "primereact/card";
import styled from "styled-components";
import { IOrder } from "types/Order/Order";

const Circle = styled.div<{ active: boolean }>`
  margin-right: var(--space-md);
  font-weight: var(--font-weight-bold);
  font-size: var(--fontsize-subtitle);
  color: ${(props) =>
    props.active ? "var(--color-mint-5)" : "var(--color-black-3)"};
`;

const Headline = styled.div`
  color: var(--bluegray-500);
  font-size: var(--fontsize-contents);
  margin-right: var(--space-md);

  @media (min-width: 720px) {
    width: auto;
  }
  @media (min-width: 1020px) {
    width: 200px;
  }
`;

function CommunicationDetails({ order }: { order?: IOrder }) {
  const maxNumberOfCircles = 3;
  const emailCommunication = order?.communications
    .map(
      (aCommunication) =>
        aCommunication?.communicationType === "Email" &&
        aCommunication.communicationEventType === "Automation"
    )
    .filter(Boolean);

  const smsCommunication = order?.communications
    .map(
      (aCommunication) =>
        aCommunication?.communicationType === "SMS" &&
        aCommunication.communicationEventType === "Automation"
    )
    .filter(Boolean);

  const renderCircles = (retryAttempts: number) => {
    return times(maxNumberOfCircles, (i) => (
      <Circle
        key={i}
        className="pi pi-check-circle"
        active={i < retryAttempts}
      />
    ));
  };

  return (
    <Card title="Communication details" className="mb-2">
      <div className="flex flex-column gap-3 mb-0">
        <div className="flex flex-row">
          <Headline>Email Attempts</Headline>
          {renderCircles(emailCommunication?.length || 0)}
        </div>
        <div className="flex flex-row">
          <Headline>Text Attempts</Headline>
          {renderCircles(smsCommunication?.length || 0)}
        </div>
      </div>
    </Card>
  );
}

export default CommunicationDetails;
