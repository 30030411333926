import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";

interface IWebsocketProps {
  ws: WebSocket | null;
}

const initialState: IWebsocketProps = {
  ws: null,
};

export const websocketSlice = createSlice({
  name: "websocket",
  initialState,
  reducers: {
    onWSStart: (state, action: { payload: WebSocket }) => {
      state.ws = action.payload;
    },
    onWSClose: (state) => {
      state.ws = null;
    },
  },
});

export const { onWSClose, onWSStart } = websocketSlice.actions;
export const websocketSelector = (state: RootState) => state.websocketSlice;

export default websocketSlice.reducer;
