import { getGoogleTagManagerPublicId } from "config/api";
import { useQuery } from "hooks/useQuery";
import gtm from "react-gtm-module";

export function useAnalytics() {
  const query = useQuery();

  const googleTagManagerId = getGoogleTagManagerPublicId();

  const tagManagerArgs = {
    gtmId: googleTagManagerId,
  };

  const initializeGA = () => {
    gtm.initialize(tagManagerArgs);
  };

  const gtmEvent = (event: string, params?: object) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      ...params,
    });
  };

  const pageViewEvents = (
    {
      accountName,
      orderType,
      communicationMethod,
    }: {
      [key: string]: string | undefined;
    },
    event: string
  ) => {
    const campaignNumber = query.get("msg") || "in_system";

    gtmEvent(event, {
      accountName,
      orderType,
      communicationMethod,
      campaignNumber,
    });
  };

  return {
    initializeGA,
    pageViewEvents,
  };
}
