import { IBillingCodeResponse } from "types/BillingCode";

import { api } from ".";

const billingCodeQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    getBillingCodes: builder.query<IBillingCodeResponse, void>({
      query: () => "billing_code",
    }),
  }),
});

export const { useGetBillingCodesQuery } = billingCodeQueries;
