export const accountFeeType = {
  ClearTransactionFee: "ClearTransactionFee",
  PRDiscount: "PRDiscount",
  CTFForPR: "CTFForPR",
  ClearHealthFeeForGFE: "ClearHealthFeeForGFE",
  ClearHealthDiscountForGFE: "ClearHealthDiscountForGFE",
};

export enum AccountFeeType {
  ClearTransactionFee = "ClearTransactionFee",
  ClearFinancingFee = "ClearFinancingFee",
  CTFForPR = "CTFForPR",
  PRDiscount = "PRDiscount",
  ClearHealthFeeForGFE = "ClearHealthFeeForGFE",
  ClearHealthDiscountForGFE = "ClearHealthDiscountForGFE",
}
