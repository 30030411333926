import { ItemBar } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export function Header() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { current: toastElement } = useContext(ToastContext);

  const backToUsersList = () => {
    navigate("/users");

    if (id) return;

    toastElement?.show({
      sticky: false,
      severity: "warn",
      summary: `Exit Creation`,
      detail: "Progress not saved",
    });
  };

  return (
    <ItemBar>
      <div className="flex flex-1 justify-content-end">
        <Button label="Cancel" onClick={backToUsersList} />
      </div>
    </ItemBar>
  );
}
