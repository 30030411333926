import { useLoading } from "context/LoadingContext";
import { ToastContext } from "context/ToastContext";
import useDocumentTitle from "hooks/useDocumentTitle";
import { Button } from "primereact/button";
import { FileUpload, ItemTemplateOptions } from "primereact/fileupload";
import { useContext, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateFeeScheduleMutation } from "store/queries/feeSchedule";
import { useGetAllPayersQuery } from "store/queries/payers";
import { useGetAllProvidersQuery } from "store/queries/providers";
import * as XLSX from "xlsx";

import EmptyTemplate from "./components/EmptyTemaplate";
import { ProviderAndPayerSection } from "./components/ProviderAndPayerSection";
import { generateRulesetList } from "./helpers/generateRulesetList";
import { normalizeKeys } from "./helpers/normalizeKeys";

export interface IFileData {
  priceInCents: number;
  description: string;
  cptCode: string;
}

export function CreateFeeSchedulePage() {
  const navigate = useNavigate();
  useDocumentTitle("Create Fee Schedules");
  const { setIsLoading } = useLoading();
  const { current: toastElement } = useContext(ToastContext);

  const [selectedFileData, setSelectedFileData] = useState<IFileData[]>([]);

  const [filteredProviders, setFilteredProviders] = useState<
    { name: string; value: string }[]
  >([]);

  const [filteredPayers, setFilteredPayers] = useState<
    { name: string; value: number }[]
  >([]);

  const [selectedProviders, setSelectedProviders] = useState<
    { name: string; value: string }[]
  >([]);
  const [selectedPayers, setSelectedPayers] = useState<
    { name: string; value: number }[]
  >([]);

  const { data, isLoading } = useGetAllProvidersQuery();
  const [createFeeSchedule, { isLoading: isLoadingCreating }] =
    useCreateFeeScheduleMutation();
  const { data: allPayers, isLoading: allPayersLoading } =
    useGetAllPayersQuery();

  const providers = useMemo(() => {
    if (isLoading || !data) return [];

    return data?.data.map((provider: any) => {
      return { name: provider.name, value: provider.id };
    });
  }, [data]);

  const payers = useMemo(() => {
    if (allPayersLoading || !allPayers) return [];
    return allPayers?.data.map((payer: any) => {
      return { name: payer.name, value: payer.id };
    });
  }, [allPayers]);

  useEffect(() => {
    setFilteredPayers(payers);
    setFilteredProviders(providers);
  }, [payers, providers]);

  const onPayerSearch = (e: any) => {
    const query = e.query.toLowerCase();
    const selectedPayerValues = selectedPayers.map((payer) => payer.value);
    const filtered = payers
      .filter((payer: any) => !selectedPayerValues.includes(payer.value))
      .filter((payer: any) => payer.name.toLowerCase().includes(query));
    setFilteredPayers(filtered);
  };

  const onProviderSearch = (e: any) => {
    const query = e.query.toLowerCase();
    const selectedProviderValues = selectedProviders.map(
      (provider) => provider.value
    );
    const filtered = providers
      .filter((provider) => !selectedProviderValues.includes(provider.value))
      .filter((provider) => provider.name.toLowerCase().includes(query));
    setFilteredProviders(filtered);
  };

  const onPayerSelect = (e: any) => {
    const selected = [...selectedPayers];
    selected.push(e.value);
    setSelectedPayers(selected);
  };

  const onPayerUnselect = (e: any) => {
    const selected = selectedPayers.filter(
      (payer) => payer.name !== e.value.name
    );
    setSelectedPayers(selected);
  };

  const onProviderSelect = (e: any) => {
    const selected = [...selectedProviders];
    selected.push(e.value);
    setSelectedProviders(selected);
  };

  const onProviderUnselect = (e: any) => {
    const selected = selectedProviders.filter(
      (provider) => provider.name !== e.value.name
    );
    setSelectedProviders(selected);
  };

  const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
    const file = inFile as File;

    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          // eslint-disable-next-line react/prop-types
          onClick={props.onRemove}
        />
      </div>
    );
  };

  const handleOnClickOverSubmit = async () => {
    const feeSchedulesToCreate = generateRulesetList({
      providers: selectedProviders,
      payers: selectedPayers,
      data: selectedFileData,
    });
    try {
      setIsLoading(true);
      const results = await Promise.all(
        feeSchedulesToCreate.map((feeSchedule) =>
          createFeeSchedule(feeSchedule)
            .unwrap()
            .catch(() => false)
        )
      );
      const allSuccessful = results.every((result) => result !== false);
      if (allSuccessful) {
        toastElement?.show({
          severity: "success",
          summary: "Fee schedule created!",
          detail: `Your fee schedule has been uploaded successfully. Please wait for message of completion.`,
        });
        setIsLoading(false);
        navigate("/fee-schedule");
      } else {
        toastElement?.show({
          severity: "error",
          summary: "Something went wrong.",
          detail: `An error has occurred attempting to create one or more fee schedules`,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toastElement?.show({
        severity: "error",
        summary: "Something went wrong.",
        detail: `Try again later.`,
      });
      setIsLoading(false);
    }
  };

  const uploadOptions = {
    style: { display: "none" },
  };

  const handleFileUpload = (e: { files: any[] }) => {
    // normalize XLSX's headers, and parse the upload file to json
    const file = e.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target && event.target.result) {
        const data = new Uint8Array(event.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        const normalizedJson = normalizeKeys(json);
        setSelectedFileData(normalizedJson);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <div
        data-testid="header__HospitalCreate"
        className="flex justify-content-between align-items-center"
      >
        <h2>New fee schedule</h2>
        <Button
          label="Cancel"
          disabled={isLoadingCreating}
          onClick={() => navigate("/fee-schedule")}
        />
      </div>
      <ProviderAndPayerSection
        filteredProviders={filteredProviders}
        filteredPayers={filteredPayers}
        selectedProviders={selectedProviders}
        selectedPayers={selectedPayers}
        onPayerSearch={onPayerSearch}
        onProviderSearch={onProviderSearch}
        onPayerSelect={onPayerSelect}
        onProviderSelect={onProviderSelect}
        onPayerUnselect={onPayerUnselect}
        onProviderUnselect={onProviderUnselect}
      />

      <div className="block bg-white border-round p-4 mb-4">
        <h3 className="mb-4">Update Fee Schedule</h3>

        <FileUpload
          auto
          customUpload
          name="feeSchedule"
          className="w-full"
          maxFileSize={1000000}
          itemTemplate={itemTemplate}
          emptyTemplate={<EmptyTemplate />}
          uploadOptions={uploadOptions}
          onRemove={() => setSelectedFileData([])}
          uploadHandler={handleFileUpload}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </div>

      <div className="flex justify-content-end">
        <Button
          label="Submit"
          onClick={handleOnClickOverSubmit}
          disabled={
            !selectedFileData ||
            !selectedProviders.length ||
            !selectedPayers.length ||
            isLoadingCreating
          }
        />
      </div>
    </div>
  );
}
