import { states } from "constants/states";
import { Label } from "layout/typography/Label";
import { handleChangePhoneWithMask } from "pages/CreateOrder/utils/mask";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import {
  rcmTypesDropdown,
  specialtyDropdown,
} from "../../../helpers/providerConstants";
import { formValidationConstants } from "../helpers/formValidations";

const Title = styled.h3`
  margin-bottom: 16px;
`;

type HospitalDetailsFields =
  | "hospitalName"
  | "addressLine1"
  | "addressLine2"
  | "state"
  | "city"
  | "zipcode"
  | "rcm"
  | "specialty"
  | "publicContactName"
  | "publicContactRole"
  | "eligibilityEnabled"
  | "publicContactPhone"
  | "npiNumber";

export function HospitalDetails() {
  const {
    control,
    trigger,
    formState: { errors },
    watch,
  } = useFormContext<any>();
  const [showNpi, setShowNpi] = useState(false);
  const eligibilityEnabled = watch("eligibilityEnabled");

  useEffect(() => {
    if (eligibilityEnabled) {
      setShowNpi(true);
    } else {
      setShowNpi(false);
    }
  }, [eligibilityEnabled]);

  async function handleOnBlurField({
    onBlur,
    field,
  }: {
    onBlur: () => void;
    field: HospitalDetailsFields;
  }) {
    trigger(field);
    onBlur();
  }

  function getFormErrorMessage(name: string) {
    return (
      errors[name] && (
        <small className="p-error">{errors[name]?.message as string}</small>
      )
    );
  }

  return (
    <div className="block bg-white border-round p-4">
      <Title>Hospital Details</Title>
      <div className="formgrid grid">
        <div className="field col-4">
          <Label htmlFor="hospitalName">Hospital Name</Label>
          <Controller
            name="hospitalName"
            control={control}
            rules={{
              required: formValidationConstants.HOSPITAL_DETAILS.NAME,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  className="w-full"
                  id="hospitalName"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter hospital name here"
                  aria-labelledby="hospitalName"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "hospitalName" });
                  }}
                  onChange={onChange}
                />

                {getFormErrorMessage("hospitalName")}
              </>
            )}
          />
        </div>

        <div className="field col-4">
          <Label htmlFor="specialty">Specialty</Label>
          <Controller
            data-testid="specialty"
            name="specialty"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                id="specialty"
                data-testid="specialty__Dropdown"
                value={value}
                onChange={onChange}
                options={Object.values(specialtyDropdown)}
                optionLabel="name"
                placeholder="Select"
                className="w-full"
                disabled
              />
            )}
          />
        </div>

        <div className="field col-4">
          <Label htmlFor="addressLine1">Address line 1</Label>
          <Controller
            name="addressLine1"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="addressLine1"
                autoComplete="off"
                value={value || ""}
                placeholder="Address line 1"
                aria-labelledby="addressLine1"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "addressLine1" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>

        <div className="field col-4">
          <Label htmlFor="city">City</Label>
          <Controller
            data-testid="city"
            name="city"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="city"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter city here"
                aria-labelledby="city"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "city" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>

        <div className="field col-4">
          <Label htmlFor="state">State</Label>
          <Controller
            data-testid="state"
            name="state"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                id="state"
                name="state"
                data-testid="states__Dropdown"
                value={value}
                onChange={onChange}
                options={states}
                optionLabel="name"
                placeholder="Select"
                className="w-full"
              />
            )}
          />
        </div>

        <div className="field col-4">
          <Label htmlFor="zipcode">Zip Code</Label>
          <Controller
            name="zipcode"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="zipcode"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter postal code here"
                aria-labelledby="zipcode"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "zipcode" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>

        <div className="field col-4">
          <Label htmlFor="rcm">RCM</Label>
          <Controller
            data-testid="rcm"
            name="rcm"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                id="rcm"
                data-testid="rcm__Dropdown"
                value={value}
                onChange={onChange}
                options={Object.values(rcmTypesDropdown)}
                optionLabel="name"
                placeholder="Select"
                className="w-full"
              />
            )}
          />
        </div>

        <div className="field col-12">
          <h3>Public Contact</h3>
        </div>
        <div className="field col-4">
          <Label htmlFor="publicContactName">Name</Label>
          <Controller
            name="publicContactName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                autoComplete="off"
                className="w-full"
                value={value || ""}
                onChange={onChange}
                id="publicContactName"
                aria-labelledby="publicContactName"
                placeholder="Enter contact name code"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "publicContactName" });
                }}
              />
            )}
          />
        </div>

        <div className="field col-4">
          <Label htmlFor="publicContactRole">Role</Label>
          <Controller
            name="publicContactRole"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                autoComplete="off"
                className="w-full"
                value={value || ""}
                onChange={onChange}
                id="publicContactRole"
                aria-labelledby="publicContactRole"
                placeholder="Enter contact role code"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "publicContactRole" });
                }}
              />
            )}
          />
        </div>

        <div className="field col-4">
          <Label htmlFor="publicContactPhone">Phone</Label>
          <Controller
            name="publicContactPhone"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="publicContactPhone"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter phone number here"
                aria-labelledby="publicContactPhone"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "publicContactPhone" });
                }}
                onChange={(e) => {
                  handleChangePhoneWithMask(e, onChange);
                }}
              />
            )}
          />
        </div>
        <div className="field col-12 mt-3">
          <h3>Eligibility</h3>
        </div>
        <div className="field-checkbox col-1" style={{ minHeight: "46px" }}>
          <Label htmlFor="eligibilityEnabled">Enabled?</Label>
          <Controller
            name="eligibilityEnabled"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox className="ml-3" onChange={onChange} checked={value} />
            )}
          />
        </div>
        {showNpi && (
          <div className="field-checkbox col-3 ml-4">
            <Label htmlFor="eligibilityEnabled">NPI</Label>
            <Controller
              name="npiNumber"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  id="npiNumber"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter NPI number here"
                  aria-labelledby="npiNumber"
                  className="ml-3"
                  onChange={onChange}
                />
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
}
