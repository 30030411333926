import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import { useMemo } from "react";

type ParsedOrder = {
  orderId: string;
  status: string;
  orderType: string;
  orderAmount: number;
  dateOfService: string;
  patientName: string;
};

export type OrderFilters = {
  status: string[];
  orderType: string[];
  patientName: string;
  dateOfService: Date[];
};

dayjs.extend(utc);
dayjs.extend(isBetween);

export function useFilteredOrders({
  orders,
  filters,
}: {
  orders: ParsedOrder[];
  filters: OrderFilters;
}) {
  const filteredOrders = useMemo(() => {
    return orders.filter((order) => {
      if (filters.status.length > 0 && !filters.status.includes(order.status)) {
        return false;
      }

      if (
        filters.orderType.length > 0 &&
        !filters.orderType.includes(order.orderType)
      ) {
        return false;
      }

      if (
        filters.patientName !== "" &&
        !order.patientName
          .toLowerCase()
          .includes(filters.patientName.toLowerCase())
      ) {
        return false;
      }

      if (
        filters.dateOfService.length > 0 &&
        filters.dateOfService[0] &&
        filters.dateOfService[1]
      ) {
        const [startDate, endDate] = filters.dateOfService;

        const start = dayjs.utc(startDate);
        const end = dayjs.utc(endDate);

        const dateOfService = dayjs.utc(order.dateOfService);

        if (!dateOfService.isBetween(start, end, "day", "[]")) {
          return false;
        }
      }

      if (
        filters.dateOfService.length > 0 &&
        filters.dateOfService[0] &&
        !filters.dateOfService[1]
      ) {
        const [startDate] = filters.dateOfService;
        const dateOfService = dayjs.utc(order.dateOfService);

        if (!dateOfService.isSame(dayjs.utc(startDate))) {
          return false;
        }
      }

      return true;
    });
  }, [orders, filters]);

  return filteredOrders;
}
