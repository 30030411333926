import styled from "styled-components";

interface IPingAnimationProps {
  count: number;
  position?: string;
}

const StyledPingContainer = styled.span<{ position: string | undefined }>`
  ${(props) => (props.position ? `position: ${props.position};` : " ")};
  min-width: 20px !important;
  min-height: 20px !important;
  display: flex;
  top: -58%;
  right: -40%;
  border-radius: 100%;
`;

const StyledPing = styled.span`
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  position: absolute;
  display: inline-flex;
  border-radius: 100%;
  min-width: 20px;
  min-height: 20px;
  color: transparent;
  background-color: var(--ping-background-color);
  opacity: 75%;
  ::after {
    display: block;
    content: "";
    padding-bottom: 100%;
  }
  @keyframes ping {
    75%,
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
`;

const StyledRound = styled.span`
  border-radius: 100%;
  min-width: 20px;
  min-height: 20px;
  padding: 2px;
  background-color: var(--ping-background-color);
  color: var(--ping-text-color);
  pisition: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  ::after {
    display: block;
    content: "";
    padding-bottom: 100%;
  }
`;

function PingAnimation({ count, position }: IPingAnimationProps) {
  return (
    <StyledPingContainer position={position}>
      <StyledPing>{count}</StyledPing>
      <StyledRound>{count}</StyledRound>
    </StyledPingContainer>
  );
}

export default PingAnimation;
