/* eslint-disable jsx-a11y/anchor-is-valid */
import { Page, View, Text, Link } from "@react-pdf/renderer";

import { styles } from "../styles";
import { Footer } from "./Footer";
import { Header } from "./Header";

export function EnglishInformation({
  facilityLogo,
  fullAddress,
  phoneNumber,
  facilityName,
  isGFE,
  gfeDisclaimer,
}: {
  facilityLogo: string;
  fullAddress: string;
  phoneNumber: string;
  facilityName: string;
  isGFE: boolean;
  gfeDisclaimer?: { title?: string; description?: string };
}) {
  return (
    <Page size="A4" style={styles.page}>
      <Header
        facilityLogo={facilityLogo}
        fullAddress={fullAddress}
        phoneNumber={phoneNumber}
      />
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          height: "700px",
          justifyContent: "space-between",
          textAlign: "left",
          lineHeight: 1.5,
        }}
      >
        <View style={{ ...styles.section, textAlign: "justify", fontSize: 11 }}>
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
              marginBottom: 8,
            }}
          >
            {isGFE
              ? gfeDisclaimer?.title
              : "No Surprise Acts - Dispute Resolution Disclaimer"}
          </Text>
          {isGFE ? (
            <Text style={styles.bodyText}>
              {gfeDisclaimer?.description || ""}
            </Text>
          ) : (
            <>
              <Text style={styles.bodyText}>
                This Good Faith Estimate shows the cost of items and services
                that are reasonably expected for your health care needs for an
                item or service. The estimate is based on information known at
                the time the estimate was created. The Good Faith Estimate does
                not include any unknown or unexpected costs that may arise
                during treatment. You could be charged more if complications or
                special circumstances occur. If this happens, federal law allows
                you to dispute (appeal) the bill.
              </Text>
              <Text style={styles.bodyText}>
                If you are billed for more than this Good Faith Estimate, you
                have the right to dispute the bill. You may contact the health
                care provider or facility listed to let them know the billed
                charges are higher than the Good Faith Estimate. You can ask
                them to update the bill to match the Good Faith Estimate, ask to
                negotiate the bill, or ask if there is financial assistance
                available. You may also start a dispute resolution process with
                the U.S. Department of Health and Human Services (DHHS). If you
                choose to dispute the resolution process, you must start the
                dispute process within 120 calendar days (about 4 months) of the
                date on the original bill. There is a $25 fee to use the dispute
                process. If the agency reviewing your dispute agrees with you,
                you will have to pay the price on your Good Faith Estimate. If
                the agency disagrees with you and agrees with the health care
                provider/facility, you will have to pay the higher amount. To
                learn more and get a form to start the process, go to{" "}
                <Link src="https://www.cms.gov/nosurprises">
                  https://www.cms.gov/nosurprises
                </Link>{" "}
                or call 1-888-918-2522. For questions or more information about
                your right to a Good Faith Estimate or the dispute process,
                visit{" "}
                <Link src="https://www.cms.gov/nosurprises">
                  https://www.cms.gov/nosurprises
                </Link>{" "}
                or call 1-888-918-2522. Keep a copy of this Good Faith Estimate
                in a safe place or take pictures of it. You may need it if you
                are billed a higher amount.
              </Text>
            </>
          )}
        </View>
        <Footer facilityName={facilityName} />
      </View>
    </Page>
  );
}
