import {
  AutoComplete,
  AutoCompleteSelectParams,
} from "primereact/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { IProcedure } from "types/Procedure";
import { IProviderProcedure } from "types/ProviderProcedure";

import { buildFilteredProceduresSuggestions } from "../../builders";
import {
  autoCompleteFieldHandleOnSelectProcedure,
  autoCompleteHandleOnUnSelectProcedure,
} from "../../handlers";

interface IAutoComplete {
  search: (e: any) => void;
  autoCompleteValue: string[];
  procedures: IProcedure[];
  providerProcedures: IProviderProcedure[];
}
export function AutoCompleteCPT({
  search,
  autoCompleteValue,
  procedures,
  providerProcedures,
}: IAutoComplete) {
  const dispatch = useDispatch();
  const { rows, selectedProcedures } = useSelector(
    (state: RootState) => state.serviceDetailsSlice
  );

  const onSelectProcedure = (e: AutoCompleteSelectParams) => {
    const arrayOfCptCodes = [...selectedProcedures];
    const filteredCpt = arrayOfCptCodes.filter((p) => p === e.value);

    autoCompleteFieldHandleOnSelectProcedure({
      e,
      dispatch,
      selectedProcedures,
      procedures,
      providerProcedures,
      rows,
      newValue: filteredCpt.length + 1,
    });
  };

  const onUnSelectProcedure = (e: AutoCompleteSelectParams) =>
    autoCompleteHandleOnUnSelectProcedure({
      e,
      autoCompleteValue,
      rows,
      dispatch,
    });

  return (
    <>
      <label htmlFor="code">CPT code</label>
      <AutoComplete
        multiple
        id="code"
        dropdown
        className="w-full"
        completeMethod={search}
        value={autoCompleteValue}
        data-testid="autocompleteCPT_ServiceDetails"
        placeholder="Select CPT Code..."
        suggestions={buildFilteredProceduresSuggestions(procedures)}
        onSelect={onSelectProcedure}
        onUnselect={onUnSelectProcedure}
        virtualScrollerOptions={{ itemSize: 38 }}
      />
    </>
  );
}
