import { centsToDollars } from "helpers/price";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { IOrder } from "types/Order/Order";

const StyledCard = styled(Card)`
  margin-bottom: ${sizer(2)};
`;

interface IPatientBenefits {
  deductible: number;
  outOfPocketMax: number;
  flatCopay: number;
  coinsurancePercent: number;
}

function PatientBenefits({ order }: { order: IOrder }) {
  const { control, setValue } = useFormContext<IPatientBenefits>();

  useEffect(() => {
    setValue(
      "deductible",
      centsToDollars(order?.gfeQuote?.remainingDeductibleInCents || 0)
    );
    setValue(
      "outOfPocketMax",
      centsToDollars(order?.gfeQuote?.outOfPocketMaxInCents || 0)
    );
    setValue(
      "flatCopay",
      centsToDollars(order?.gfeQuote?.flatCopayInCents || 0)
    );
    setValue("coinsurancePercent", order?.gfeQuote?.coinsurancePercent || 0);
  }, [order, setValue]);

  return (
    <StyledCard title="Patient Benefits">
      <div className="formgrid grid w-100">
        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="deductible">Remaining Deductible</Label>

          <Controller
            name="deductible"
            control={control}
            render={({ field: { value } }) => (
              <InputNumber
                disabled
                data-testid="deductible"
                id="deductible"
                placeholder="Remaining Deductible"
                value={value}
                currency="USD"
                locale="en-US"
                mode="currency"
              />
            )}
          />
        </div>
        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="outOfPocketMax">Remaining Out-of-pocket Max</Label>

          <Controller
            name="outOfPocketMax"
            control={control}
            render={({ field: { value } }) => (
              <InputNumber
                disabled
                data-testid="outOfPocketMax"
                id="outOfPocketMax"
                placeholder="Out of Pocket Max"
                value={value}
                currency="USD"
                locale="en-US"
                mode="currency"
              />
            )}
          />
        </div>
        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="accountId">Flat Copay</Label>

          <Controller
            name="flatCopay"
            control={control}
            render={({ field: { value } }) => (
              <InputNumber
                disabled
                data-testid="flatCopay"
                id="flatCopay"
                placeholder="Flat copay"
                value={value}
                currency="USD"
                locale="en-US"
                mode="currency"
              />
            )}
          />
        </div>
        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="coinsurancePercent">Coinsurance Percent</Label>

          <Controller
            name="coinsurancePercent"
            control={control}
            render={({ field: { value } }) => (
              <InputNumber
                disabled
                suffix="%"
                data-testid="coinsurancePercent"
                id="coinsurancePercent"
                placeholder="Coinsurance Percent"
                value={value}
              />
            )}
          />
        </div>
      </div>
    </StyledCard>
  );
}

export default PatientBenefits;
