import { orderTypeConstants, orderTypeMapping } from "constants/order";

export enum TypesCode {
  ER = "ED",
  Bundled = "Bundled",
  PatientResponsibility = "PatientResponsibility",
  Bariatrics = "Bariatrics",
  GFE = "GFE",
}

export const orderTypes = {
  [TypesCode.Bundled]: {
    name: orderTypeMapping[orderTypeConstants.BUNDLED],
    value: orderTypeConstants.BUNDLED,
  },
  [TypesCode.PatientResponsibility]: {
    name: orderTypeMapping[orderTypeConstants.PATIENT_RESPONSIBILITY],
    value: orderTypeConstants.PATIENT_RESPONSIBILITY,
  },
  [TypesCode.GFE]: {
    name: orderTypeMapping[orderTypeConstants.GFE],
    value: orderTypeConstants.GFE,
  },
  [TypesCode.ER]: {
    name: orderTypeMapping[orderTypeConstants.ED],
    value: orderTypeConstants.ED,
  },
  [TypesCode.Bariatrics]: {
    name: orderTypeMapping[orderTypeConstants.BARIATRICS],
    value: orderTypeConstants.BARIATRICS,
  },
};
