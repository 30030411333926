import useFormValidation from "hooks/useFormValidation";
import { Label } from "layout/typography/Label";
import { InputText } from "primereact/inputtext";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { formValidationConstants } from "../helpers/formValidations";

const Title = styled.h3`
  margin-bottom: 16px;
`;

export function PayerName() {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext<any>();
  const { getFormErrorMessage, handleOnBlurField } = useFormValidation();
  return (
    <div className="block bg-white border-round p-4 mb-4">
      <Title>Payer</Title>
      <Label htmlFor="groupName">Payer Name</Label>
      <Controller
        name="name"
        control={control}
        rules={{
          required: formValidationConstants.PAYER.NAME,
        }}
        render={({ field: { onChange, value, onBlur } }) => (
          <>
            <InputText
              className="w-full mt-2"
              id="name"
              autoComplete="off"
              value={value || ""}
              placeholder="Enter payer name here"
              aria-labelledby="name"
              onChange={onChange}
              onBlur={() => {
                handleOnBlurField({ onBlur, field: "name", trigger });
              }}
            />
            {getFormErrorMessage("name", errors)}
          </>
        )}
      />
    </div>
  );
}
