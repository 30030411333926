import {
  IDefaultProcedure,
  IPatientResponsibilityProcedure,
} from "types/Order/Order";

export function groupByCodeDescriptionAndCount(items: any[]): {
  code: number;
  cptDescription: string;
  providers: { name: string; specialty: string; quantity: number }[];
}[] {
  const uniqueWithCount = items.reduce(
    (
      acc: IDefaultProcedure[] | IPatientResponsibilityProcedure[],
      currentItem
    ) => {
      if (
        "procedureId" in currentItem &&
        "providerProcedureId" in currentItem
      ) {
        const { code, procedureId, providerProcedureId } = currentItem;
        const key = `${code}-${procedureId}-${providerProcedureId}`;
        const existingObj = acc.find((obj: any) => Object.keys(obj)[0] === key);
        if (existingObj) {
          (existingObj as any)[key] += 1;
        } else {
          acc.push({ [key]: 1 } as any);
        }
      }
      return acc;
    },
    []
  );

  const uniqueObjectsMap = new Map();
  uniqueWithCount.forEach((obj) => {
    const [key, value] = Object.entries(obj)[0];
    uniqueObjectsMap.set(key, value);
  });

  const grouped = items.reduce(
    (
      acc: {
        code: number;
        cptDescription: string;
        providers: { name: string; specialty: string; quantity: number }[];
      }[],
      currentItem
    ) => {
      const { code, cptDescription } = currentItem;

      let foundItem = acc.find(
        (item) => item.code === code && item.cptDescription === cptDescription
      );

      if (!foundItem) {
        foundItem = { code, cptDescription, providers: [] };
        acc.push(foundItem);
      }

      if (
        "providerName" in currentItem &&
        "specialty" in currentItem &&
        "procedureId" in currentItem &&
        "providerProcedureId" in currentItem
      ) {
        const { providerName, specialty, procedureId, providerProcedureId } =
          currentItem;

        if (
          specialty &&
          providerName &&
          !foundItem.providers.find(
            (currentProvider) => currentProvider.name === providerName
          )
        ) {
          const key = `${code}-${procedureId}-${providerProcedureId}`;
          const quantity: number = uniqueObjectsMap.get(key) || 0;

          foundItem.providers.push({ name: providerName, specialty, quantity });
        }
      }

      return acc;
    },
    []
  );

  return grouped;
}
