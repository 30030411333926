import { sumBy } from "lodash";
import {
  IPatientResponsibilityProcedure,
  IDefaultProcedure,
} from "types/Order/Order";
import { SpecialtyList } from "types/Provider/Provider";

export function parsePrice(value: number): string {
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export function centsToDollars(cents: number): number {
  return cents / 100;
}

export function dollarsToCents(cents: number): number {
  return Math.round(cents * 100);
}

export function calculateDiscount(price: number, discount: number): number {
  return price - price * (discount / 100);
}

export function calculateProviderFinalPayment(
  price: number,
  clearFee: number | undefined
) {
  return clearFee ? price - price * (clearFee / 100) : 0;
}

export function calculateTax(price: number, tax: number) {
  return price + price * (tax / 100);
}

export function calculateDiscountPercent(
  totalPrice: number,
  suggestedPrice: number
): number {
  return (1 - suggestedPrice / totalPrice) * 100;
}

export function calculateClearCharge(price: number, fee: number): number {
  const priceWithoutCharge = fee ? price - price * (fee / 100) : 0;

  return price - priceWithoutCharge;
}

export const calculatePercentageOff = (
  realPrice: number,
  salePrice: number
): number => {
  return Math.round(((realPrice - salePrice) / realPrice) * 100);
};

export const sumOfFacilityProcedures = (
  procedures: Array<IPatientResponsibilityProcedure | IDefaultProcedure>
) =>
  sumBy(
    procedures.filter(
      (aProcedure: IPatientResponsibilityProcedure | IDefaultProcedure) =>
        "specialty" in aProcedure &&
        aProcedure.specialty === SpecialtyList.FACILITY
    ) || [],
    "amountInCents"
  );

export const sumOfProvidersProcedures = (
  procedures: Array<IPatientResponsibilityProcedure | IDefaultProcedure>
) =>
  sumBy(
    procedures.filter(
      (aProcedure: IPatientResponsibilityProcedure | IDefaultProcedure) =>
        "specialty" in aProcedure &&
        aProcedure.specialty !== SpecialtyList.FACILITY
    ) || [],
    "amountInCents"
  );
