import useFormValidation from "hooks/useFormValidation";
import { Label } from "layout/typography/Label";
import { Chips } from "primereact/chips";
import { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { formValidationConstants } from "../helpers/formValidations";

const Title = styled.h3`
  margin-bottom: 16px;
`;

export function CptCodes() {
  const {
    control,
    trigger,
    formState: { errors },
    watch,
  } = useFormContext();
  const [cptCodes, setCptCodes] = useState<string[]>([]);
  const { getFormErrorMessage, handleOnBlurField } = useFormValidation();
  const watchedCptCodes = watch("cptCodes");

  useEffect(() => {
    if (watchedCptCodes) {
      setCptCodes(watchedCptCodes);
    }
  }, [watchedCptCodes]);

  return (
    <div className="block bg-white border-round p-4 mb-4">
      <Title>CPT Codes</Title>
      <Label htmlFor="cptCodes">Add CPT Codes</Label>
      <Controller
        name="cptCodes"
        control={control}
        rules={{
          required: formValidationConstants.CPT_CODES.CODES,
        }}
        render={({ field: { onChange, onBlur } }) => (
          <>
            <Chips
              id="cptCodes"
              value={cptCodes}
              className="w-full mt-2"
              onChange={(e: any) => {
                setCptCodes(e.value.map((item: any) => item.trim()));
                onChange(e.value.map((item: any) => item.trim()));
              }}
              separator=","
              onBlur={() => {
                handleOnBlurField({ onBlur, field: "cptCodes", trigger });
              }}
            />
            {getFormErrorMessage("cptCodes", errors)}
          </>
        )}
      />
    </div>
  );
}
