import useFormValidation from "hooks/useFormValidation";
import { Label } from "layout/typography/Label";
import { AutoComplete } from "primereact/autocomplete";
import { useMemo, useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useGetAllPayersQuery } from "store/queries/payers";
import { useGetAllProvidersQuery } from "store/queries/providers";
import styled from "styled-components";

import { formValidationConstants } from "../helpers/formValidations";

const Title = styled.h3`
  margin-bottom: 16px;
`;

const StyledAutoComplete = styled(AutoComplete)`
  .p-autocomplete-multiple-container {
    width: 100%;
  }
`;

export function ProviderAndPayerSection() {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext<any>();
  const { getFormErrorMessage, handleOnBlurField } = useFormValidation();
  const { data, isLoading } = useGetAllProvidersQuery();
  const { data: allPayers, isLoading: allPayersLoading } =
    useGetAllPayersQuery();
  const [selectedProviders, setSelectedProviders] = useState<
    { name: string; value: string }[]
  >([]);
  const [selectedPayers, setSelectedPayers] = useState<
    { name: string; value: number }[]
  >([]);
  const [filteredPayers, setFilteredPayers] = useState<
    { name: string; value: number }[]
  >([]);
  const [filteredProviders, setFilteredProviders] = useState<
    { name: string; value: string }[]
  >([]);

  const providers = useMemo(() => {
    if (isLoading || !data) return [];

    return data?.data.map((provider) => {
      return { name: provider.name, value: provider.id };
    });
  }, [data]);

  const payers = useMemo(() => {
    if (allPayersLoading || !allPayers) return [];
    return allPayers?.data.map((payer: any) => {
      return { name: payer.name, value: payer.id };
    });
  }, [allPayers]);

  useEffect(() => {
    setFilteredPayers(payers);
    setFilteredProviders(providers);
  }, [payers, providers]);

  const onPayerSearch = (e: any) => {
    const query = e.query.toLowerCase();
    const selectedPayerValues = selectedPayers.map((payer) => payer.value);
    const filtered = payers
      .filter((payer: any) => !selectedPayerValues.includes(payer.value))
      .filter((payer: any) => payer.name.toLowerCase().includes(query));
    setFilteredPayers(filtered);
  };

  const onProviderSearch = (e: any) => {
    const query = e.query.toLowerCase();
    const selectedProviderValues = selectedProviders.map(
      (provider) => provider.value
    );
    const filtered = providers
      .filter((provider) => !selectedProviderValues.includes(provider.value))
      .filter((provider) => provider.name.toLowerCase().includes(query));
    setFilteredProviders(filtered);
  };

  const onPayerSelect = (e: any, onChange: any) => {
    const selected = [...selectedPayers];
    selected.push(e.value);
    setSelectedPayers(selected);
    onChange(selected);
  };

  const onPayerUnselect = (e: any, onChange: any) => {
    const selected = selectedPayers.filter(
      (payer) => payer.name !== e.value.name
    );
    setSelectedPayers(selected);
    onChange(selected);
  };

  const onProviderSelect = (e: any, onChange: any) => {
    const selected = [...selectedProviders];
    selected.push(e.value);
    setSelectedProviders(selected);
    onChange(selected);
  };

  const onProviderUnselect = (e: any, onChange: any) => {
    const selected = selectedProviders.filter(
      (provider) => provider.name !== e.value.name
    );
    setSelectedProviders(selected);
    onChange(selected);
  };

  return (
    <div className="block bg-white border-round p-4 mb-4">
      <Title>Provider and Payer Section</Title>
      <div className="formgrid grid">
        <div className="field col-6">
          <Label htmlFor="hospitalName">Provider</Label>
          <Controller
            data-testid="provider"
            name="provider"
            control={control}
            rules={{
              required: formValidationConstants.PROVIDER.PROVIDER,
            }}
            render={({ field: { onChange, onBlur } }) => (
              <>
                <StyledAutoComplete
                  suggestions={filteredProviders}
                  dropdown
                  multiple
                  field="name"
                  className="w-full"
                  value={selectedProviders}
                  completeMethod={onProviderSearch}
                  onSelect={(e) => onProviderSelect(e, onChange)}
                  onUnselect={(e) => onProviderUnselect(e, onChange)}
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "provider",
                      trigger,
                    });
                  }}
                />
                {getFormErrorMessage("provider", errors)}
              </>
            )}
          />
        </div>
        <div className="field col-6">
          <Label htmlFor="payerName">Payer Name</Label>
          <Controller
            name="payerName"
            control={control}
            rules={{
              required: formValidationConstants.PAYER_NAME.NAME,
            }}
            render={({ field: { onChange, onBlur } }) => (
              <>
                <StyledAutoComplete
                  suggestions={filteredPayers}
                  dropdown
                  multiple
                  field="name"
                  className="w-full"
                  value={selectedPayers}
                  completeMethod={onPayerSearch}
                  onSelect={(e) => onPayerSelect(e, onChange)}
                  onUnselect={(e) => onPayerUnselect(e, onChange)}
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "payerName",
                      trigger,
                    });
                  }}
                />
                {getFormErrorMessage("payerName", errors)}
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
}
