import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { ProcedureRow } from "types/CreateOrder";

import { buildSelectedProceduresObject } from "../../builders";
import { autoCompleteHandleRemoveProcedure } from "../../handlers";

interface IRemoveItem {
  row: ProcedureRow;
  autoCompleteValue: string[];
}
export function RemoveItem({ row, autoCompleteValue }: IRemoveItem) {
  const dispatch = useDispatch();
  const rows = useSelector((state: any) => state.serviceDetailsSlice.rows);

  return (
    <Button
      className="p-button-link"
      onClick={() =>
        autoCompleteHandleRemoveProcedure({
          selectedRow: row,
          rows,
          selectedProceduresObject:
            buildSelectedProceduresObject(autoCompleteValue),
          dispatch,
        })
      }
    >
      <i className="pi pi-trash" style={{ fontSize: "1.0rem" }} />
    </Button>
  );
}
