import { ItemBar } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useUpdateDiscountRulesetMutation } from "store/queries/procedureDiscountRuleset";

import { mapFormToRequestBody } from "../helpers/mapFormToRequestBody";

export function SubmitEdit({ id }: { id: string }) {
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const navigate = useNavigate();
  const [useUpdateDiscountRuleset] = useUpdateDiscountRulesetMutation();

  const { current: toastElement } = useContext(ToastContext);

  const updatePayerRule = async () => {
    const requestBody = mapFormToRequestBody(getValues());
    try {
      useUpdateDiscountRuleset({ ...requestBody, id })
        .unwrap()
        .then(() => {
          toastElement?.show({
            severity: "success",
            summary: "Payer Ruleset updated",
            detail: "Payer Ruleset updated successfully",
          });

          navigate("/payer-rules");
        })
        .catch(() =>
          toastElement?.show({
            severity: "error",
            summary: "Error",
            detail:
              "An error has occurred attempting to update the discount ruleset",
          })
        );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ItemBar>
      <div
        data-testid="submit__Hospital"
        className="flex flex-1 justify-content-end"
      >
        <Button
          label="Update Payer Rule"
          onClick={updatePayerRule}
          disabled={!isValid}
        />
      </div>
    </ItemBar>
  );
}
