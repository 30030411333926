import { ItemBar } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { useQuery } from "hooks/useQuery";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export function Header() {
  const navigate = useNavigate();
  const { current: toastElement } = useContext(ToastContext);
  const query = useQuery();
  const accountId = query.get("accountId") || undefined;

  const backToProvidersList = () => {
    navigate(`/hospitals/view/${accountId}?tab=1`);
    toastElement?.show({
      severity: "warn",
      summary: "Exit creation",
      detail: "Progress not saved",
    });
  };

  return (
    <ItemBar>
      <div
        data-testid="header__ProvidersCreate"
        className="flex flex-1 justify-content-end"
      >
        <Button label="Cancel" onClick={backToProvidersList} />
      </div>
    </ItemBar>
  );
}
