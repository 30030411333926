import { sizer } from "layout/styles/styled/sizer";
import styled from "styled-components";

const StyledMessageWrapper = styled.div`
  margin-bottom: ${sizer(4)};
  font-weight: 500;
  font-size: 18px;
`;

export function Message({
  eligibilityMessage,
}: {
  eligibilityMessage: string;
}) {
  return (
    <StyledMessageWrapper>
      <p style={{ color: "#ff0000" }}>{eligibilityMessage}</p>
    </StyledMessageWrapper>
  );
}
