import { IUserResponse } from "types/User";

import { api } from ".";

const userQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<IUserResponse, null | void>({
      query: () => `user/current/overview`,
    }),
    createUser: builder.mutation({
      query: (newUser) => ({
        url: "user/create",
        method: "POST",
        body: newUser,
      }),
    }),
    resetPassword: builder.mutation({
      query: (userId) => ({
        method: "PUT",
        url: `user/resetPassword/${userId}`,
      }),
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        method: "DELETE",
        url: `user/${userId}`,
      }),
    }),
    editUser: builder.mutation({
      query: ({ id, user }) => ({
        method: "PUT",
        body: user,
        url: `user/update/${id}`,
      }),
    }),
    getUserById: builder.query<
      { data: IUserResponse; message: string },
      string | undefined
    >({
      query: (id) => `user/${id}`,
    }),
    getAllUsers: builder.query<
      { data: IUserResponse[]; message: string },
      null | void
    >({
      query: () => `user`,
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useGetAllUsersQuery,
  useCreateUserMutation,
  useGetUserByIdQuery,
  useEditUserMutation,
  useResetPasswordMutation,
  useDeleteUserMutation,
} = userQueries;
