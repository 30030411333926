import { LoadingSpinner } from "components/LoadingSpinner";
import { adjustmentTypesMapping } from "constants/order";
import { centsToDollars, parsePrice } from "helpers/price";
import { sizer } from "layout/styles/styled/sizer";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { AnyAction, Dispatch } from "redux";
import {
  IPriceBreakdownRows,
  toggleDisplayAdjustments,
} from "store/slices/serviceDetails";
import styled from "styled-components";

import { buildSimpleFirstColumnTemplate } from "../../builders";

const CardHeader = styled.div``;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export function AdjustmentDetails({
  displayAdjustments,
  priceBreakdownRows,
  dispatch,
  priceExecuting,
}: {
  displayAdjustments: boolean;
  priceBreakdownRows: IPriceBreakdownRows;
  dispatch: Dispatch<AnyAction>;
  priceExecuting: boolean;
}) {
  return (
    <>
      <CardHeader
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          marginTop: sizer(5),
        }}
        onClick={() => {
          dispatch(toggleDisplayAdjustments());
        }}
      >
        <span
          style={{
            fontSize: "1.1rem",
            fontWeight: 700,
          }}
        >
          Adjustments
        </span>
        {displayAdjustments ? (
          <i
            className="pi pi-angle-up
"
            style={{ fontSize: "1.5rem" }}
          />
        ) : (
          <i
            className="pi pi-angle-down
"
            style={{ fontSize: "1.5rem" }}
          />
        )}
      </CardHeader>

      {!priceExecuting ? (
        <DataTable
          stripedRows
          value={priceBreakdownRows.adjustments}
          rowGroupMode="rowspan"
          responsiveLayout="scroll"
          emptyMessage="No adjustments available."
          style={{
            marginLeft: sizer(-4),
            marginRight: sizer(-4),
          }}
        >
          <Column
            field="providerName"
            style={{ width: "20%" }}
            body={(row) => buildSimpleFirstColumnTemplate(row)}
          />
          <Column
            field="internalId"
            style={{ width: "15%" }}
            body={(row) => row.cptCode}
          />
          <Column
            field="amount"
            style={{ width: "15%" }}
            body={(row) => parsePrice(centsToDollars(row.amount))}
          />
          <Column
            field="specialty"
            style={{ width: "50%" }}
            body={(row) => adjustmentTypesMapping[row.specialty]}
          />
        </DataTable>
      ) : (
        <LoadingContainer>
          <LoadingSpinner customStyle={{ width: "60px", height: "60px" }} />
          <h3>Calculating Adjustments</h3>
        </LoadingContainer>
      )}
    </>
  );
}
