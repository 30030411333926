import { states } from "constants/states";
import { Label } from "layout/typography/Label";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

const Title = styled.h3`
  margin-bottom: 16px;
`;

type ProvidersFields = "address" | "state" | "city" | "zipcode";

export function AddressForm() {
  const { control, trigger } = useFormContext<any>();

  async function handleOnBlurField({
    onBlur,
    field,
  }: {
    onBlur: () => void;
    field: ProvidersFields;
  }) {
    trigger(field);
    onBlur();
  }

  return (
    <div className="block bg-white border-round p-4">
      <Title>Address</Title>
      <div className="formgrid grid">
        <div className="field col-4">
          <Label htmlFor="address">Address line 1</Label>
          <Controller
            name="address"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="address"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter address here"
                aria-labelledby="address"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "address" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="city">City</Label>
          <Controller
            data-testid="city"
            name="city"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="city"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter city here"
                aria-labelledby="city"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "city" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="field col-4" />
        <div className="field col-4">
          <Label htmlFor="state">State</Label>
          <Controller
            data-testid="state"
            name="state"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                id="state"
                name="state"
                data-testid="states__Dropdown"
                value={value}
                onChange={onChange}
                options={states}
                optionLabel="name"
                placeholder="Select"
                className="w-full"
              />
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="zipcode">Zip Code</Label>
          <Controller
            name="zipcode"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="zipcode"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter postal code here"
                aria-labelledby="zipcode"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "zipcode" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="field col-4" />
      </div>
    </div>
  );
}
