import PingAnimation from "components/PingAnimation/PingAnimation";

function PendingNotificationIcon({
  count,
  className,
  tooltipText,
  tooltipPosition,
}: {
  count: number;
  className?: string;
  tooltipText?: string;
  tooltipPosition?: string;
}) {
  return (
    <div
      className={`flex relative ${className}`}
      data-pr-tooltip={tooltipText}
      data-pr-position={tooltipPosition}
    >
      <i
        className="pi pi-bell"
        style={{ fontSize: "1.5rem", color: "var(--bluegray-500)" }}
      />
      <PingAnimation count={count} position="absolute" />
    </div>
  );
}

export default PendingNotificationIcon;
