import { api } from ".";

const moneyTransferQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    createMoneyTransfers: builder.mutation<any, any>({
      query: (moneyTransfer) => ({
        url: "/stripe/money_transfers",
        method: "POST",
        body: moneyTransfer,
      }),
    }),
  }),
});

export const { useCreateMoneyTransfersMutation } = moneyTransferQueries;
