import { PropsWithChildren } from "react";
import styled from "styled-components";

const StyledLabel = styled.label`
  color: var(--color-label);
`;

export interface ILabelProps {
  htmlFor: string;
}

export function Label({
  htmlFor,
  children,
}: PropsWithChildren<ILabelProps>): JSX.Element {
  return <StyledLabel htmlFor={htmlFor}>{children}</StyledLabel>;
}
