import { IPatient, IUpdatePatient } from "types/Patient";

import { publicApi, api } from ".";

const patientQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    updatePatientOverview: builder.mutation<IPatient, IUpdatePatient>({
      query: (patientToUpdate) => ({
        method: "PUT",
        url: `/patient/${patientToUpdate.id}/orderview`,
        body: {
          ...patientToUpdate.data,
          orderId: patientToUpdate.orderId,
          userId: patientToUpdate.userId,
        },
      }),
    }),
    verifyPatientPhone: builder.mutation<any, string>({
      query: (phoneNumber) => ({
        method: "POST",
        url: `/patient/verify/phone`,
        body: { phoneNumber },
      }),
    }),
  }),
});

const publicPatientQueries = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    updatePatient: builder.mutation<IPatient, IUpdatePatient>({
      query: (patientToUpdate) => ({
        method: "PUT",
        url: `/patient/${patientToUpdate.id}`,
        body: {
          ...patientToUpdate.data,
          orderId: patientToUpdate.orderId,
          userId: patientToUpdate.userId,
        },
      }),
    }),
  }),
});

export const {
  useUpdatePatientOverviewMutation,
  useVerifyPatientPhoneMutation,
} = patientQueries;

export const { useUpdatePatientMutation } = publicPatientQueries;
