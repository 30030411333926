import { ItemBar } from "components/ItemBar";
import { useLoading } from "context/LoadingContext";
import { ToastContext } from "context/ToastContext";
import { Button } from "primereact/button";
import { useContext, useMemo } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  useGetAccountCustomsQuery,
  useUpsertCustomMutation,
} from "store/queries/customize";
import { ICustomizeResponse } from "types/Customize";

import { mapFormToApi } from "../helper";

export function Submit({
  defaultValues,
}: {
  defaultValues: { [key: string]: ICustomizeResponse };
}) {
  const [upsertCustomByOrderTypes] = useUpsertCustomMutation();
  const { setIsLoading } = useLoading();
  const { id: accountId } = useParams();

  const { refetch } = useGetAccountCustomsQuery(accountId as string);

  const { current: toastElement } = useContext(ToastContext);
  const { getValues } = useFormContext();
  const { isValid } = useFormState();

  const isButtonDisabled = useMemo(() => {
    return !isValid;
  }, [isValid]);

  const onSubmit = async () => {
    const objectsToSave = mapFormToApi(getValues());
    const defaultArrayItem = Object.values(defaultValues).map(
      (defaultObject) => ({
        dobPage: defaultObject.dobPage,
        orderType: defaultObject.orderType,
        overviewPage: defaultObject.overviewPage,
      })
    );

    // Create a set of unique orderType values from objectsToSave
    const orderTypesToExclude = new Set(
      objectsToSave.map((obj) => obj.orderType)
    );

    // Filter defaultArrayItem, removing objects with an orderType in orderTypesToExclude
    const filteredDefaultArrayItem = defaultArrayItem.filter(
      (obj) => !orderTypesToExclude.has(obj.orderType)
    );

    try {
      setIsLoading(true);
      await Promise.all(
        [...filteredDefaultArrayItem, ...objectsToSave].map((object) =>
          upsertCustomByOrderTypes({ ...object, accountId })
        )
      );
      refetch();
      toastElement?.show({
        severity: "success",
        summary: "Success",
        detail: "Changes have been saved",
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toastElement?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${error}`,
      });
    }
  };

  return (
    <ItemBar>
      <div
        data-test-id="customize-submit"
        className="flex flex-1 justify-content-end"
      >
        <Button
          label="Save Changes"
          onClick={onSubmit}
          disabled={isButtonDisabled}
        />
      </div>
    </ItemBar>
  );
}
