import { LoadingSpinner } from "components/LoadingSpinner";
import useDocumentTitle from "hooks/useDocumentTitle";
import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useGetPayerByIdQuery } from "store/queries/payers";

import { Header } from "./components/Header";
import { MedtelIds } from "./components/MedtelIds";
import { Parent } from "./components/Parent";
import { PayerName } from "./components/PayerName";
import { PverifyId } from "./components/PverifyId";
import { SubmitEditPayer } from "./components/SubmitEditPayer";

export function EditPayerPage() {
  useDocumentTitle("Edit Payer");
  const { id } = useParams();
  const { data: payer, isLoading } = useGetPayerByIdQuery(id);
  const methods = useForm({
    defaultValues: {
      name: payer?.data.name,
      parent: payer?.data.parent,
      pverifyId: payer?.data.pverifyId,
      medtelIds: payer?.data.medtelIds,
    },
  });

  useEffect(() => {
    if (payer) {
      methods.reset({
        name: payer?.data.name,
        parent: payer?.data.parent,
        pverifyId: payer?.data.pverifyId,
        medtelIds: payer?.data.medtelIds,
      });
    }
  }, [payer, methods]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <FormProvider {...methods}>
      <Header isPayer isEditing name={payer?.data.name} />
      <PayerName />
      <Parent />
      <PverifyId />
      <MedtelIds />
      {id && payer && <SubmitEditPayer id={id} />}
    </FormProvider>
  );
}
