export const formValidationConstants = {
  PROVIDER: {
    PROVIDER: "Provider is required.",
  },
  PAYER_NAME: {
    NAME: "Payer name is required.",
  },
  CPT_CODES: {
    CODES: "CPT Codes are required.",
  },
  PROCEDURE_DISCOUNT: {
    DISCOUNT: "Discount is required.",
  },
  PARENT: {
    PARENT: "Parent is required.",
  },
  PAYER: {
    NAME: "Payer name is required.",
    PVERIFY_ID: "Pverify Id is required.",
  },
};
