import { UserRole } from "constants/userRole";
import { useLoading } from "context/LoadingContext";
import { useUserWithAccount } from "context/UserAccountProvider";
import {
  AutoComplete,
  AutoCompleteChangeParams,
} from "primereact/autocomplete";
import { Dialog } from "primereact/dialog";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IAccount } from "types/Account/Account";

const StyledDialog = styled(Dialog)`
  width: 40vw;

  @media (max-width: 720px) {
    width: 90vw;
  }
`;

type SwitchAccountModalProps = {
  visible: boolean;
  onClose: () => void;
};

export function SwitchAccountModal({
  visible,
  onClose,
}: SwitchAccountModalProps) {
  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null);
  const [filteredAccounts, setFilteredAccounts] = useState<IAccount[]>([]);
  const { accounts: accountsFromAPI, userInfo } = useUserWithAccount();

  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const accounts = useMemo(() => {
    if (userInfo?.scope === UserRole.SUPER_ADMIN) {
      return accountsFromAPI?.data || [];
    }
    return (
      accountsFromAPI?.data.filter((aAccount) =>
        userInfo?.accountIds.includes(aAccount.id)
      ) || []
    );
  }, [accountsFromAPI]);

  const parsedFilteredAccounts = useMemo(() => {
    return filteredAccounts.map((account: IAccount) => account.name);
  }, [filteredAccounts]);

  const searchAccount = (event: { query: string }) => {
    setTimeout(() => {
      let filteredAccountsInternal;

      if (!event.query.trim().length) {
        filteredAccountsInternal = [...accounts];
      } else {
        filteredAccountsInternal = accounts.filter((account: IAccount) => {
          return account.name
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredAccounts(filteredAccountsInternal);
    }, 250);
  };

  const onAutoCompleteChange = (e: AutoCompleteChangeParams) => {
    const selectedAccount = accounts.find(
      (theAccount) => theAccount.name === e.value
    );

    if (!selectedAccount) return;

    setSelectedAccount(e.value);
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);

      navigate(`/customize/new/${selectedAccount?.id}`);

      onClose();
    }, 1000);
  };

  return (
    <StyledDialog
      closable
      onHide={onClose}
      visible={visible}
      header="Select Account"
    >
      <AutoComplete
        dropdown
        className="w-full"
        value={selectedAccount}
        completeMethod={searchAccount}
        placeholder="Ex: Hospital Clear"
        suggestions={parsedFilteredAccounts}
        onChange={onAutoCompleteChange}
      />
    </StyledDialog>
  );
}
