import { ProgressSpinner } from "primereact/progressspinner";

export function LoadingSpinner({
  customStyle,
}: {
  customStyle?: { width: string; height: string };
}): JSX.Element {
  return (
    <div className="flex h-full justify-content-center align-self-center flex-column">
      <ProgressSpinner style={customStyle} aria-label="Loading" />
    </div>
  );
}
