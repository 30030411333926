import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import checkedImage from "assets/images/checked.svg";
import { orderTypeConstants } from "constants/order";
import { events } from "constants/tagManager";
import { ToastContext } from "context/ToastContext";
import { useUserWithAccount } from "context/UserAccountProvider";
import dayjs from "dayjs";
import { centsToDollars, parsePrice } from "helpers/price";
import { useAnalytics } from "hooks/useAnalytics";
import useDocumentTitle from "hooks/useDocumentTitle";
import { Label } from "layout/typography/Label";
import { debounce } from "lodash";
import { VerificationSteps } from "pages/VerificationPage/utils/constants";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useGetCustomsByOrderTypeAndAccountIdQuery } from "store/queries/customize";
import { useCreatePaymentMutation } from "store/queries/order";
import { useUpdatePatientMutation } from "store/queries/patient";
import { useAttachPaymentMethodMutation } from "store/queries/stripe";
import { onVerificationStepChange } from "store/slices/verification";
import { IOrder } from "types/Order/Order";

import {
  ContainerForm,
  Pill,
  EmailContainer,
  StyledDialog,
  DialogContainer,
} from "./styled";

type PaymentSectionProps = {
  prices: {
    clearPrice: number;
    totalSaving: number;
    fullPrice: number;
  };
  order: IOrder;
  expirationDays: number;
  onReturnClick: () => void;
  showPaymentSection: { paymentPlan: boolean; paymentSection: boolean };
};

export function PaymentSection({
  order,
  prices,
  onReturnClick,
  expirationDays,
  showPaymentSection,
}: PaymentSectionProps) {
  useDocumentTitle("Payment Page");
  const navigate = useNavigate();
  const { userInfo } = useUserWithAccount();

  const { pageViewEvents } = useAnalytics();

  const { data: contentData } = useGetCustomsByOrderTypeAndAccountIdQuery(
    { orderType: order.orderType, accountId: order.account.id },
    {
      skip: !order,
    }
  );

  const { overviewPage: overviewContent } = contentData?.data || {};

  const [updatePatient, { isError: isUpdatePatientError }] =
    useUpdatePatientMutation();

  const [createPayment, { isError: isCreatePaymentError }] =
    useCreatePaymentMutation();

  const [attachPaymentMethodToCustomer, { isError: isAttachedPaymentError }] =
    useAttachPaymentMethodMutation();

  const { current: toastElement } = useContext(ToastContext);

  const [isProcessing, setIsProcessing] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [patientEmail, setPatientEmail] = useState(order.patient.email);

  const elements = useElements();
  const stripe = useStripe();

  const dispatch = useDispatch();

  const activePaymentPlan = useMemo(() => {
    return order.paymentPlan.find((plan) => plan.active);
  }, [order]);

  const initialPayment = useMemo(() => {
    const orderTypeAmountMap = new Map<string, number>([
      [orderTypeConstants.BARIATRICS, prices.clearPrice],
      [orderTypeConstants.BUNDLED, prices.clearPrice],
      [orderTypeConstants.PATIENT_RESPONSIBILITY, prices.fullPrice],
      [orderTypeConstants.GFE, prices.fullPrice],
      [orderTypeConstants.ED, prices.clearPrice],
    ]);
    const estimatedCost = orderTypeAmountMap.get(order.orderType) || 0;

    const minDownPaymentPercent =
      (activePaymentPlan?.paymentPlanMinDownPaymentPercent ?? 0) || 0;
    const minDownPaymentFlat = activePaymentPlan?.paymentPlanMinDownPaymentFlat;

    const minDownPayment =
      minDownPaymentFlat || estimatedCost * minDownPaymentPercent;

    return minDownPayment;
  }, [prices, order, activePaymentPlan]);

  const debouncedPageViewEvents = debounce((order, event: string) => {
    pageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.communicationMethod,
      },
      event
    );
  }, 500);

  const finishPayment = async () => {
    if (stripe && elements) {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (error) {
        setIsProcessing(false);

        toastElement?.show({
          severity: "error",
          detail: error.message,
          summary: "Payment failed",
        });
      }

      pageViewEvents(
        {
          accountName: order.account.name,
          orderType: order.orderType,
          communicationMethod: order.patient.preferredContactMethod,
        },
        events.PURCHASE
      );

      if (paymentIntent && paymentIntent.status === "succeeded") {
        createPayment({
          orderId: order.id,
          amountInCents: paymentIntent.amount,
          stripeBalanceTransactionId: paymentIntent.id,
          paymentType: paymentIntent.payment_method_types[0],
          userId: userInfo?.id,
          paymentMethodId: paymentIntent?.payment_method as string,
        })
          .unwrap()
          .then(() => {
            setShowDialog(true);
          })
          .catch(() => {
            setShowDialog(false);
            setIsProcessing(false);

            toastElement?.show({
              severity: "warn",
              summary: "Receipt Failed",
              detail:
                "We encountered a challenge while trying to update the payment status. Please allow us approximately 10 minutes as we work to rectify the issue and update your payment status. We appreciate your understanding and patience.",
            });
          });
      }
    }
  };

  const finishInitialPayment = async () => {
    if (stripe && elements) {
      // CONFIRM PAYMENT FOR INITIAL PAYMENT
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: `${order.patient.firstName} ${order.patient.lastName}`,
              email: patientEmail,
            },
          },
        },
      });

      if (error) {
        setIsProcessing(false);

        toastElement?.show({
          severity: "error",
          detail: error.message,
          summary: "Payment failed",
        });
      }

      pageViewEvents(
        {
          accountName: order.account.name,
          orderType: order.orderType,
          communicationMethod: order.patient.preferredContactMethod,
        },
        events.PURCHASE
      );

      if (paymentIntent && paymentIntent.status === "succeeded") {
        await attachPaymentMethodToCustomer({
          paymentMethodId: paymentIntent?.payment_method,
          customerId: order.patient.email,
        });
        if (isAttachedPaymentError) {
          toastElement?.show({
            severity: "warn",
            summary: "Payment Method Attachment Failed",
            detail: `We encountered a challenge while trying to save your payment method. Please try again later. Error: ${isAttachedPaymentError}`,
          });
        }
        // CREATE PAYMENT FOR INITIAL PAYMENT
        createPayment({
          orderId: order.id,
          amountInCents: paymentIntent.amount,
          stripeBalanceTransactionId: paymentIntent.id,
          paymentType: paymentIntent.payment_method_types[0],
          userId: userInfo?.id,
          paymentMethodId: paymentIntent?.payment_method as string,
          paymentPlan: true,
        })
          .unwrap()
          .then(() => {
            setShowDialog(true);
          })
          .catch(() => {
            setShowDialog(false);
            setIsProcessing(false);

            toastElement?.show({
              severity: "warn",
              summary: "Receipt Failed",
              detail:
                "We encountered a challenge while trying to update the payment status. Please allow us approximately 10 minutes as we work to rectify the issue and update your payment status. We appreciate your understanding and patience.",
            });
          });
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsProcessing(true);

    debouncedPageViewEvents(
      {
        accountName: order.account.name,
        orderType: order.orderType,
        communicationMethod: order.patient.preferredContactMethod,
      },
      events.CLICK_PURCHASE
    );
    const emailIsChanged = patientEmail !== order.patient.email;
    const orderId = emailIsChanged ? order.id : undefined;
    const userId = userInfo?.id;
    updatePatient({
      id: order.patient.id,
      data: { email: patientEmail },
      orderId,
      userId,
    })
      .unwrap()
      .then(async () => {
        await finishPayment();
      })
      .catch(() => {
        if (isUpdatePatientError) {
          toastElement?.show({
            summary: "Error",
            severity: "error",
            detail: "Your payment cannot be completed. Please try again.",
          });
        }

        setIsProcessing(false);
      });
  };

  const handleSubmitPaymentPlan = async (e: any) => {
    e.preventDefault();

    setIsProcessing(true);

    debouncedPageViewEvents(
      {
        accountName: order.account.name,
        orderType: order.orderType,
        communicationMethod: order.patient.preferredContactMethod,
      },
      events.CLICK_PURCHASE
    );
    const emailIsChanged = patientEmail !== order.patient.email;
    const orderId = emailIsChanged ? order.id : undefined;
    const userId = userInfo?.id;
    updatePatient({
      id: order.patient.id,
      data: { email: patientEmail },
      orderId,
      userId,
    })
      .unwrap()
      .then(async () => {
        await finishInitialPayment();
      })
      .catch((error) => {
        if (isUpdatePatientError) {
          toastElement?.show({
            summary: "Error",
            severity: "error",
            detail: "Your payment cannot be completed. Please try again.",
          });
        }
        setIsProcessing(false);
      });
  };

  return (
    <ContainerForm>
      {!!showPaymentSection.paymentSection && (
        <>
          <h2>Pay Now Amount</h2>
          <p>{parsePrice(prices.clearPrice)}</p>
        </>
      )}

      {!!showPaymentSection.paymentPlan && (
        <>
          <h2>Initial Payment</h2>
          <p>{parsePrice(centsToDollars(initialPayment))}</p>
        </>
      )}

      {overviewContent?.discountExpires && (
        <Pill className="mt-3 mb-3 border-round-md text-xs py-1 px-2 text-center">
          Discount expires on{" "}
          {dayjs
            .utc(order.dateOfService)
            .add(expirationDays, "day")
            .format("MMMM D, YYYY")}
        </Pill>
      )}

      <EmailContainer className="flex flex-column mb-2">
        <Label htmlFor="email">Email Address</Label>

        <InputText
          id="email"
          autoComplete="off"
          data-testid="email-input"
          value={patientEmail || ""}
          placeholder="your@email.com"
          onChange={(event) => setPatientEmail(event.target.value)}
        />
      </EmailContainer>

      {/* START STRIPE ELEMENTS FOR CARD PAYMENT */}
      <div className="sensitive">
        <PaymentElement />
      </div>
      {/* END STRIPE ELEMENTS FOR CARD PAYMENT */}

      {!!showPaymentSection.paymentPlan && (
        <>
          <p className="text-sm text-left mt-4 mb-4 text-orange-300">
            {`By clicking Pay Now, you authorize Clear
        Health to automatically charge the credit card you have provided for the
        amount specified in your Payment Plan for each billing period.`}
          </p>

          <Button
            type="submit"
            data-testid="button-submit-payment-plan"
            className="w-full justify-content-center  mb-1"
            loading={isProcessing}
            onClick={handleSubmitPaymentPlan}
          >
            <span className="font-bold">Pay Now</span>
          </Button>
        </>
      )}
      {!!showPaymentSection.paymentSection && (
        <Button
          type="submit"
          loading={isProcessing}
          data-testid="button-submit-pay-now"
          className="w-full justify-content-center mt-4"
          onClick={handleSubmit}
        >
          <span>Pay Now</span>
        </Button>
      )}

      <div className="flex align-items-center justify-content-center mt-3">
        <Button
          className="p-button-text mb-0 p-button-secondary p-0"
          onClick={onReturnClick}
        >
          <p className="text-xs purple font-medium">Back to details</p>
        </Button>
      </div>

      <p className="text-xs gray mt-2 text-center font-normal">
        Thousands of patients have used Clear to reduce their medical bills and
        you can too.
      </p>

      <StyledDialog closable={false} onHide={() => {}} visible={showDialog}>
        <DialogContainer>
          <img src={checkedImage as string} alt="Payment Successful" />

          <h2 className="mb-4">Payment Successful! You are all set!</h2>

          <p className="mb-4">
            Your payment has been successfully processed! General Hospital will
            be notified of your payment and your account will be updated prior
            to your visit.
          </p>

          <p className="mb-4">
            A copy of your payment receipt has been sent to the email provided
            with payment.
          </p>

          {!isCreatePaymentError && (
            <Button
              label="View Receipt"
              onClick={() => {
                navigate(`/receipt/${order.externalId}`);
                dispatch(
                  onVerificationStepChange({ step: VerificationSteps.RECEIPT })
                );
              }}
            />
          )}
        </DialogContainer>
      </StyledDialog>
    </ContainerForm>
  );
}
