import { ItemBar } from "components/ItemBar";
import { UserRole, userRoleMapping } from "constants/userRole";
import { useLoading } from "context/LoadingContext";
import { ToastContext } from "context/ToastContext";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateUserMutation,
  useEditUserMutation,
  useResetPasswordMutation,
} from "store/queries/users";

import { mapFormToRequestBody } from "../helpers/mapFormToRequestBody";

export function Submit() {
  const {
    watch,
    getValues,
    formState: { isValid },
  } = useFormContext();
  const { current: toastElement } = useContext(ToastContext);
  const { setIsLoading } = useLoading();
  const { id } = useParams();

  const [createMutation] = useCreateUserMutation();
  const [editMutation] = useEditUserMutation();
  const [resetPasswordMutation] = useResetPasswordMutation();

  const navigate = useNavigate();

  const accountIds = watch("accountIds");
  const scope = watch("scope");

  const isButtonEnabled =
    scope === userRoleMapping[UserRole.SUPER_ADMIN]
      ? isValid
      : isValid && accountIds?.length;

  const handleOnResetPassword = () => {
    setIsLoading(true);

    resetPasswordMutation(id)
      .unwrap()
      .then(() => {
        toastElement?.show({
          severity: "success",
          summary: "Success!",
          detail: "Email sent to the user requesting to reset their password.",
        });

        setIsLoading(false);
      })
      .catch((error) => {
        const errorMessage =
          Array.isArray(error?.data?.messages) && error.data.messages[0];

        setIsLoading(false);
        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail:
            errorMessage ||
            "An error has occurred attempting to reset the password",
        });
      });
  };

  const handleOnEditUser = async () => {
    setIsLoading(true);
    const requestBody = await mapFormToRequestBody(getValues(), {
      editMode: true,
    });

    editMutation({ id, user: requestBody })
      .unwrap()
      .then(() => {
        toastElement?.show({
          severity: "success",
          summary: "Success!",
          detail: "User edited successfully",
        });

        setIsLoading(false);
        navigate("/users");
      })
      .catch((error) => {
        const errorMessage =
          Array.isArray(error?.data?.messages) && error.data.messages[0];

        setIsLoading(false);
        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail:
            errorMessage || "An error has occurred attempting to edit the user",
        });
      });
  };

  const handleOnCreateUser = async () => {
    setIsLoading(true);
    const requestBody = await mapFormToRequestBody(getValues(), {
      editMode: false,
    });

    createMutation(requestBody)
      .unwrap()
      .then(() => {
        toastElement?.show({
          severity: "success",
          summary: "Success!",
          detail: "User created successfully",
        });

        setIsLoading(false);
        navigate("/users");
      })
      .catch((error) => {
        setIsLoading(false);

        const errorMessage =
          Array.isArray(error?.data?.messages) && error.data.messages[0];

        const isConflictError =
          error?.data?.messages[1]?.message === "The user already exists.";

        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail: isConflictError
            ? "The user already exists. Please use another email."
            : errorMessage ||
              "An error has occurred attempting to reset the password",
        });
      });
  };

  return (
    <ItemBar>
      <div className="flex gap-2 flex-1 justify-content-end">
        <Button
          disabled={!id}
          label="Reset Password"
          className="p-button-text"
          onClick={handleOnResetPassword}
        />

        <Button
          disabled={!isButtonEnabled}
          label={id ? "Edit User" : "Create User"}
          onClick={id ? handleOnEditUser : handleOnCreateUser}
        />
      </div>
    </ItemBar>
  );
}
