import { useAuth0 } from "@auth0/auth0-react";
import { Image } from "primereact/image";
import { Menu } from "primereact/menu";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "store/slices/user";
import styled from "styled-components";

const UserStyled = styled.p`
  line-height: var(--lineheight-subtitle);
  font-size: var(--fontsize-contents);
  font-weight: var(--font-weight-bold);

  color: (--color-matte-black);

  margin: 0;
  margin-bottom: 4px;
`;

const RoleStyled = styled.p`
  margin: 0;

  line-height: var(--lineheight-subtitle);
  font-size: var(--fontsize-contents);

  color: var(--color-black-4);
`;

const UserInfoContainer = styled.div`
  gap: 8px;

  display: flex;
  align-items: center;
`;

const StyledMenu = styled(Menu)`
  margin-top: 16px;
`;

const transformScope = (scope: string | undefined) => {
  if (!scope) return "Not Identified";
  return scope.replace(/([A-Z])/g, " $1").replace(/^./, (str) => {
    return str.toUpperCase();
  });
};

export function UserDropdown() {
  const menu = useRef<Menu>(null);

  const { isAuthenticated, logout } = useAuth0();
  const { scope, name } = useSelector(userSelector);

  const items = [
    {
      label: "Logout",
      adminFeature: false,
      icon: "pi pi-power",
      command: () => {
        logout();
      },
    },
  ];

  if (!isAuthenticated) {
    return <div />;
  }

  return (
    <div
      tabIndex={0}
      role="button"
      onClick={(e) => menu.current?.toggle(e)}
      onKeyDown={(e) => menu.current?.toggle(e)}
      className="flex align-items-center cursor-pointer"
    >
      <StyledMenu model={items} popup ref={menu} />
      <UserInfoContainer>
        <div>
          <UserStyled>{name}</UserStyled>
          <RoleStyled>{transformScope(scope)}</RoleStyled>
        </div>

        <Image className="ml-2" src="/icons/caret-down-solid.svg" />
      </UserInfoContainer>
    </div>
  );
}
