import useDocumentTitle from "hooks/useDocumentTitle";
import { useQuery } from "hooks/useQuery";
import { useForm, FormProvider } from "react-hook-form";
import styled from "styled-components";

import { AddressForm } from "./components/AddressForm";
import { Header } from "./components/Header";
import { MoreInfoForm } from "./components/MoreInfoForm";
import { ProviderDetailsForm } from "./components/ProviderDetailsForm";
import { Submit } from "./components/Submit";

const Space = styled.div`
  height: 20px;
`;
export function CreateProvider() {
  const query = useQuery();
  const accountId = query.get("accountId");
  useDocumentTitle("Create Provider");

  const methods = useForm({
    defaultValues: {
      name: null,
      specialty: null,
      address: null,
      city: null,
      state: null,
      zipcode: null,
      npi: null,
      contactName: null,
      contactPhone: null,
      contactEmail: null,
      taxPayorIdNumber: null,
      stripeAccountNumber: null,
      type: "Person",
      emailToReceiveReports: [],
      emailToReceiveNotification: [],
      accountId,
    },
  });

  return (
    <FormProvider {...methods}>
      <Header />
      <ProviderDetailsForm />
      <Space />
      <AddressForm />
      <Space />
      <MoreInfoForm />
      <Space />
      <Submit />
    </FormProvider>
  );
}
