import { ItemBar } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledH2 = styled.h2`
  margin-bottom: ${sizer(8)};
`;

export function Header({
  isEditing = false,
  accountName = "",
}: {
  isEditing?: boolean;
  accountName?: string;
}) {
  const navigate = useNavigate();
  const { current: toastElement } = useContext(ToastContext);

  const backToHospitalList = () => {
    navigate("/hospitals");
    toastElement?.show({
      severity: "warn",
      summary: "Exit creation",
      detail: "Progress not saved",
    });
  };

  return (
    <ItemBar>
      {isEditing && <StyledH2>{accountName}</StyledH2>}
      <div
        data-testid="header__HospitalCreate"
        className="flex flex-1 justify-content-end"
      >
        <Button label="Cancel" onClick={backToHospitalList} />
      </div>
    </ItemBar>
  );
}
