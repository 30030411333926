/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LoadingSpinner } from "components/LoadingSpinner";
import { orderTypeConstants } from "constants/order";
import { ToastContext } from "context/ToastContext";
import { parseDate } from "helpers/date";
import useDocumentTitle from "hooks/useDocumentTitle";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useContext, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPublicOrderQuery } from "store/queries/order";
import { useUpdatePaymentHospitalVerificationMutation } from "store/queries/payment";
import styled from "styled-components";
import { IDefaultProcedure } from "types/Order/Order";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 2em 6em;
`;

const Title = styled.h1`
  margin-bottom: 1em;
`;

const SubTitle = styled.h2`
  font-size: 12pt;
  margin-bottom: 0.5em;
  color: var(--color-label);
`;

const Content = styled.h3`
  text-align: center;
  margin-bottom: 2em;
`;

const OrderedList = styled.ol`
  list-style: none;
  margin-bottom: 2em;
`;

const ListItem = styled.li`
  font-weight: bold;
  font-size: 1.17em;
`;

const StyledInputText = styled(InputText)`
  margin-bottom: 2em;
`;

const CheckboxWrapper = styled.div`
  display: flex;
`;

const CheckboxText = styled.span`
  font-size: 12pt;
  color: var(--color-label);
`;

export function HospitalConfirmation() {
  const { id } = useParams();
  const { current: toastElement } = useContext(ToastContext);
  const navigate = useNavigate();
  useDocumentTitle("Hospital Confirmation Page");
  const { data, isLoading } = useGetPublicOrderQuery(id || "", {
    refetchOnMountOrArgChange: true,
  });

  const isDefaultPackage: boolean = useMemo(() => {
    return (
      data?.data.orderType === orderTypeConstants.BUNDLED ||
      data?.data.orderType === orderTypeConstants.BARIATRICS ||
      data?.data.orderType === orderTypeConstants.ED
    );
  }, [data]);

  const defaultValues = {
    checked: false,
  };
  const [mutation, { isError: isUpdatePaymentHospitalError }] =
    useUpdatePaymentHospitalVerificationMutation();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  if (isLoading) return <LoadingSpinner />;

  const getFormErrorMessage = () => {
    return errors.checked ? (
      <small className="p-error">{errors.checked.message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const onSubmit = (event: { checked: boolean }) => {
    if (!data?.data.payments[0].id) {
      toastElement?.show({
        summary: "Error",
        severity: "error",
        detail: "Please provide payment identifier.",
      });
      return;
    }
    mutation({
      paymentId: data?.data.payments[0].id,
      hospitalCheck: event.checked,
    })
      .unwrap()
      .then(async () => {
        navigate("/confirmation/success");
      })
      .catch(() => {
        if (isUpdatePaymentHospitalError) {
          toastElement?.show({
            summary: "Error",
            severity: "error",
            detail: "Your payment cannot be verify. Please try again.",
          });
        }
      });
  };

  return (
    <Container>
      <Title>Payment Verification</Title>
      <SubTitle>Patient Name</SubTitle>
      <Content className="sensitive">{`${data?.data.patient.firstName} ${data?.data.patient.lastName}`}</Content>
      <SubTitle>Date of Birth</SubTitle>
      <Content className="sensitive">
        {parseDate(data!.data.patient.dateOfBirth)}
      </Content>
      <SubTitle>Hospital</SubTitle>
      <Content>{data?.data.account.name}</Content>
      <SubTitle>Procedures</SubTitle>
      <OrderedList>
        {data?.data.serviceDetails.procedures.map((aProcedure, index) => {
          const theDescription = isDefaultPackage
            ? (aProcedure as IDefaultProcedure).providerName
            : aProcedure.cptDescription;

          return <ListItem key={index}>{theDescription}</ListItem>;
        })}
      </OrderedList>
      <StyledInputText
        data-testid="patientAccountNumber"
        id="patientAccountNumber"
        autoComplete="off"
        disabled
        value={data?.data?.accountNumber}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-column align-items-center gap-2"
      >
        <Controller
          name="checked"
          control={control}
          rules={{ required: "Accept is required." }}
          render={({ field, fieldState }) => (
            <>
              <CheckboxWrapper>
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.checked })}
                />
                <Checkbox
                  inputId={field.name}
                  checked={field.value}
                  inputRef={field.ref}
                  style={{ marginRight: 10 }}
                  className={classNames({ "p-invalid": fieldState.error })}
                  onChange={(e: { checked: any }) => field.onChange(e.checked)}
                />
                <CheckboxText>{`I entered the Clear Health Payor ID into the patient's account in the EHR`}</CheckboxText>
              </CheckboxWrapper>
              {getFormErrorMessage()}
            </>
          )}
        />

        <Button label="Submit" type="submit" icon="pi pi-check" />
      </form>
    </Container>
  );
}
