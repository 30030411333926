import { Label } from "layout/typography/Label";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";

const StyledTextArea = styled(InputTextarea)``;

export function CoinsuranceItem({ coinsurance }: { coinsurance: any }) {
  return (
    <div className="flex flex-column">
      <Label htmlFor="value">Value</Label>
      <InputText
        value={coinsurance.percent ? `${coinsurance.percent}%` : "N/A"}
        disabled
        className="mt-1 mb-2"
        aria-labelledby="deductible"
      />
      {coinsurance?.benefitEntities?.map((benefit: any, index: number) => (
        <div
          className="flex flex-column"
          key={`${index}_additionalCoinsurance_benefit`}
        >
          <Label htmlFor="entityType">Entity Type</Label>
          <StyledTextArea
            value={benefit?.entityType || "N/A"}
            contentEditable={false}
            className="mt-1 mb-2"
            aria-labelledby="entityType"
            autoResize
          />
          <Label htmlFor="entityName">Name</Label>
          <StyledTextArea
            value={benefit?.name || "N/A"}
            contentEditable={false}
            className="mt-1 mb-2"
            aria-labelledby="entityName"
            autoResize
          />
        </div>
      ))}
    </div>
  );
}
