import { ItemBar } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useUpdatePayerMutation } from "store/queries/payers";

export function SubmitEditPayer({ id }: { id: string }) {
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const navigate = useNavigate();
  const [updatePayer] = useUpdatePayerMutation();

  const { current: toastElement } = useContext(ToastContext);

  const handleUpdatePayer = async () => {
    const values = getValues();
    try {
      updatePayer({
        id,
        name: values.name,
        parent: values.parent,
        pverifyId: values.pverifyId,
        locality: [],
        placeOfService: [],
        medtelIds: values.medtelIds,
      })
        .unwrap()
        .then(() => {
          toastElement?.show({
            severity: "success",
            summary: "Payer updated",
            detail: "Payer updated successfully",
          });

          navigate("/payer-rules?tab=2");
        })
        .catch(() =>
          toastElement?.show({
            severity: "error",
            summary: "Error",
            detail: "An error has occurred attempting to update the payer",
          })
        );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ItemBar>
      <div
        data-testid="submit__Payer"
        className="flex flex-1 justify-content-end"
      >
        <Button
          label="Update Payer"
          onClick={handleUpdatePayer}
          disabled={!isValid}
        />
      </div>
    </ItemBar>
  );
}
