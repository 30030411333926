import { LoadingSpinner } from "components/LoadingSpinner";
import { useLoading } from "context/LoadingContext";
import { ToastContext } from "context/ToastContext";
import { centsToDollars, parsePrice } from "helpers/price";
import { debounce } from "lodash";
import { Button } from "primereact/button";
import { Column, ColumnEventParams } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Paginator, PaginatorPageState } from "primereact/paginator";
import { ToggleButton } from "primereact/togglebutton";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  IProviderProcedureRequest,
  useDeleteAllProviderProcedureMutation,
  useDeleteProviderProcedureMutation,
  useGetProviderProcedureQuery,
  useUpdateProviderProcedurePriceMutation,
} from "store/queries/providerProcedure";
import styled from "styled-components";
import { SpecialtyList, SpecialtyListDict } from "types/Provider/Provider";

type FeeSchedule = {
  id: number;
  cptCode: number;
  procedure: string;
  provider: string;
  specialty: SpecialtyList;
  price: number;
  payerName?: string;
  placeOfService?: string;
  locality?: string;
  toBeEdited?: boolean;
};

const StyledToggleButton = styled(ToggleButton)`
  max-width: 50%;
  height: 22px;
`;

export function FeeScheduleTable({
  hospitalToFilter,
}: {
  hospitalToFilter?: string;
}) {
  const { current: toastElement } = useContext(ToastContext);

  const [selectedFeeSchedule, setSelectedFeeSchedule] = useState<FeeSchedule[]>(
    []
  );
  const [isExporting, setIsExporting] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows] = useState(25);
  const [filters, setFilters] = useState<IProviderProcedureRequest>({
    payerNameField: undefined,
    cptCodeField: undefined,
    cptDescription: undefined,
    specialty: undefined,
    providerName: undefined,
    connectedHospital: hospitalToFilter,
  });
  const [inputValues, setInputValues] = useState<{ [key: string]: any }>({}); // Store input values

  const {
    refetch,
    isLoading,
    data: feeSchedulesFromAPI,
  } = useGetProviderProcedureQuery({ page: first, pageSize: rows, ...filters });
  const { isLoading: isLoadingAll, data: allFeeSchedulesFromAPI } =
    useGetProviderProcedureQuery({
      ...filters,
    });
  const { setIsLoading } = useLoading();

  const [mutation] = useUpdateProviderProcedurePriceMutation();
  const [deleteMutation] = useDeleteProviderProcedureMutation();
  const [deleteAllFilteredMutation] = useDeleteAllProviderProcedureMutation();

  const feeSchedules = useMemo(() => {
    return feeSchedulesFromAPI?.data.map((feeSchedule) => {
      return {
        id: feeSchedule.id,
        cptCode: feeSchedule?.procedure?.cptCode,
        price: centsToDollars(feeSchedule?.priceInCents),
        procedure: feeSchedule?.procedure?.description,
        provider: feeSchedule?.provider?.name,
        specialty: SpecialtyListDict[feeSchedule?.provider?.specialty],
        connectedHospital: feeSchedule?.provider?.account.name,
        payerName: feeSchedule?.payer.name,
        toBeEdited: feeSchedule?.toBeEdited,
      };
    });
  }, [feeSchedulesFromAPI]);

  const handleOnClickDeleteFeeSchedule = async () => {
    setIsLoading(true);

    try {
      const deletePromises = selectedFeeSchedule.map((feeSchedule) =>
        deleteMutation(feeSchedule.id)
      );

      await Promise.all(deletePromises);

      await refetch();

      toastElement?.show({
        severity: "success",
        summary: "Deleted!",
        detail: "Fee Schedule(s) has been deleted successfully.",
      });
    } catch (error) {
      await refetch();

      toastElement?.show({
        severity: "error",
        summary: "Error",
        detail:
          "An error occurred while deleting fee schedules. Please try again.",
      });
    } finally {
      setIsLoading(false);
      setSelectedFeeSchedule([]);
    }
  };

  const handleOnClickDeleteAllFilteredFeeSchedule = async () => {
    setIsLoading(true);

    try {
      await deleteAllFilteredMutation(filters);

      await refetch();

      toastElement?.show({
        severity: "success",
        summary: "Deleted!",
        detail: "Fee Schedule(s) has been deleted successfully.",
      });
    } catch (error) {
      await refetch();

      toastElement?.show({
        severity: "error",
        summary: "Error",
        detail:
          "An error occurred while deleting fee schedules. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const textEditor = (options: any) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const debouncedSetFilters = debounce((newFilters) => {
    setFilters({
      ...newFilters,
      connectedHospital: hospitalToFilter || newFilters.connectedHospital,
    });
  }, 500); // delay in ms

  useEffect(() => {
    if (!Object.keys(inputValues).length) {
      return undefined;
    }
    debouncedSetFilters(inputValues);
    return () => {
      debouncedSetFilters.cancel();
    };
  }, [inputValues]);

  const genericInputFilterTemplate = (
    fieldName: string,
    filterName: string
  ) => {
    return (
      <InputText
        className="p-column-filter no-icon"
        id={`${fieldName}-filter`}
        autoComplete="off"
        placeholder={`Search by ${fieldName}`}
        aria-labelledby="filter"
        value={inputValues[filterName] || ""}
        onChange={(e) => {
          setInputValues((prevState) => ({
            ...prevState,
            [filterName]: e.target.value,
          }));
        }}
      />
    );
  };

  const specialtyFilterTemplate = () => {
    return (
      <Dropdown
        value={inputValues.specialty}
        className="p-column-filter"
        showClear
        placeholder="Select by Specialty"
        options={Object.entries(SpecialtyListDict).map(([value, label]) => ({
          value,
          label,
        }))}
        onChange={(e) => {
          setInputValues((prevState) => ({
            ...prevState,
            specialty: e.target.value,
          }));
        }}
      />
    );
  };

  const onCellEditComplete = (e: ColumnEventParams) => {
    const { rowData, newValue, field } = e;
    rowData[field] = Number(newValue);
    const isPriceChanged = field === "price";
    const isToBeEditedChanged = field === "toBeEdited";
    const priceInCents = isPriceChanged
      ? Number(newValue) * 100
      : Number(rowData.price) * 100;
    const toBeEdited = isToBeEditedChanged ? !!newValue : !!rowData.toBeEdited;
    mutation({ id: rowData.id, priceInCents, toBeEdited }).then(() => {
      toastElement?.show({
        severity: "success",
        summary: isPriceChanged ? "Price updated!" : "Price Editable updated!",
        detail: `The ${isPriceChanged ? "price" : "price editable"} for the ${
          rowData.procedure
        }  has been updated successfully.`,
      });
    });
  };

  const onPageChange = (event: PaginatorPageState) => {
    setFirst(event.first);
  };

  const editableBodyTemplate = (options: any) => {
    return (
      <StyledToggleButton
        checked={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
      />
    );
  };

  const handleExportFilteredProcedures = () => {
    setIsExporting(true);
  };

  useEffect(() => {
    if (isExporting && !isLoadingAll && allFeeSchedulesFromAPI) {
      const sortedData = [...allFeeSchedulesFromAPI.data].sort(
        (a, b) => a.id - b.id
      );
      const headers = [
        "Procedure",
        "CPT Code",
        "Price",
        "Price editable",
        "Provider/Hospital Name",
        "Specialty",
        "Connected Hospital",
        "Payer Name",
      ];
      const csvRows = [
        headers.join(","),
        ...sortedData.map((feeSchedule) =>
          [
            feeSchedule?.procedure?.description,
            feeSchedule?.procedure?.cptCode,
            centsToDollars(feeSchedule?.priceInCents),
            feeSchedule?.toBeEdited ? "Yes" : "No",
            feeSchedule?.provider?.name,
            SpecialtyListDict[feeSchedule?.provider?.specialty],
            feeSchedule?.provider?.account.name,
            feeSchedule?.payer.name,
          ].join(",")
        ),
      ];
      const csvString = csvRows.join("\n");
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${hospitalToFilter}-FeeSchedules.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsExporting(false);
    }
  }, [isLoadingAll, isExporting, allFeeSchedulesFromAPI]);

  const exportButton = () => {
    return isExporting ? (
      <LoadingSpinner customStyle={{ width: "44px", height: "44px" }} />
    ) : (
      <Button
        label="Export All Filtered Procedures"
        className="p-button-outlined mt-4"
        onClick={handleExportFilteredProcedures}
      />
    );
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <DataTable
        rows={rows}
        rowHover
        stripedRows
        dataKey="id"
        editMode="cell"
        className="p-fluid datatable__FeeScheduleTable"
        filterDisplay="row"
        value={feeSchedules}
        responsiveLayout="scroll"
        selection={selectedFeeSchedule}
        emptyMessage="No fee schedule to show."
        onSelectionChange={(event) => {
          setSelectedFeeSchedule(event.value);
        }}
      >
        <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
        <Column
          filter
          field="procedure"
          header="Procedure"
          style={{ minWidth: "320px" }}
          filterElement={() =>
            genericInputFilterTemplate("Procedure", "cptDescription")
          }
        />
        <Column
          filter
          field="cptCode"
          header="CPT Code"
          style={{ minWidth: "280px" }}
          filterElement={() =>
            genericInputFilterTemplate("CPT Code", "cptCodeField")
          }
        />
        <Column
          field="price"
          header="Price"
          {...{ onCellEditComplete }}
          style={{ minWidth: "280px" }}
          body={(row) => parsePrice(row.price)}
          editor={(options) => textEditor(options)}
        />
        <Column
          filter
          field="toBeEdited"
          header="Price Editable"
          style={{ minWidth: "280px" }}
          {...{ onCellEditComplete }}
          editor={(options) => editableBodyTemplate(options)}
          body={(row) => (row.toBeEdited ? "Yes" : "No")}
        />
        <Column
          filter
          field="provider"
          style={{ minWidth: "280px" }}
          header="Provider/Hospital Name"
          filterElement={() =>
            genericInputFilterTemplate("Provider", "providerName")
          }
        />
        <Column
          filter
          field="specialty"
          header="Specialty"
          style={{ minWidth: "280px" }}
          filterElement={specialtyFilterTemplate}
        />
        <Column
          filter={!hospitalToFilter}
          field="connectedHospital"
          header="Connected Hospital"
          style={{ minWidth: "280px" }}
          filterElement={() =>
            genericInputFilterTemplate("Hospital", "connectedHospital")
          }
        />
        <Column
          filter
          field="payerName"
          header="Payer Name"
          style={{ minWidth: "280px" }}
          filterElement={() =>
            genericInputFilterTemplate("Payer Name", "payerNameField")
          }
        />
      </DataTable>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={feeSchedulesFromAPI?.total}
        onPageChange={onPageChange}
      />
      <div className="flex justify-content-between">
        {hospitalToFilter ? (
          exportButton()
        ) : (
          <Button
            label="Delete Selected Filter"
            className="p-button-outlined mt-4"
            onClick={() => {
              confirmDialog({
                header: "Confirmation",
                icon: "pi pi-exclamation-triangle",
                message: `Are you sure you want delete the filtered procedures?
              Cautions: if no filter it means all procedures to be deleted.`,
                accept: handleOnClickDeleteAllFilteredFeeSchedule,
              });
            }}
          />
        )}
        <Button
          label="Delete"
          className="p-button-outlined mt-4"
          disabled={!selectedFeeSchedule.length}
          onClick={() => {
            confirmDialog({
              header: "Confirmation",
              icon: "pi pi-exclamation-triangle",
              message: `Are you sure you want delete?`,
              accept: handleOnClickDeleteFeeSchedule,
            });
          }}
        />
      </div>
    </>
  );
}
