import { Fieldset } from "primereact/fieldset";
import { useDispatch } from "react-redux";
import { onIntegrationChange } from "store/slices/order";
import styled from "styled-components";
import { IIntegrationError } from "types/Order/Order";

const FieldsetImplemented = styled(Fieldset)`
  background-color: rgba(244, 5, 5, 0.245);
  border-radius: 4px;
`;

const ButtonDismiss = styled.div``;

export function ErrorBanner({
  integrationErrors,
}: {
  integrationErrors: { [key: string]: string };
}) {
  const dispatch = useDispatch();

  const onDismissClick = (error: [string, unknown]) => {
    const theErrorToDismiss = {
      errorType: error[0],
      message: error[1],
      active: false,
    } as IIntegrationError;
    dispatch(onIntegrationChange(theErrorToDismiss));
  };
  return (
    <FieldsetImplemented
      className="orderType mb-3"
      legend="Integration Issues"
      toggleable
    >
      {Object.entries(integrationErrors).map((error) => (
        <div
          key={error[0]}
          className="flex flex-row justify-content-between items-center mb-2"
        >
          <p className="text-balance">{error[1] as string}</p>
          <ButtonDismiss onClick={() => onDismissClick(error)}>
            <i
              className="pi pi-times"
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
            />
          </ButtonDismiss>
        </div>
      ))}
    </FieldsetImplemented>
  );
}
