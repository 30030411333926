import { parsePrice } from "helpers/price";
import { Label } from "layout/typography/Label";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";

const StyledTextArea = styled(InputTextarea)``;

export function CopayItem({ copay }: { copay: any }) {
  return (
    <div className="flex flex-column">
      <Label htmlFor="value">Value</Label>
      <InputText
        value={parsePrice(Number(copay.monetaryAmount)) || "N/A"}
        disabled
        className="mt-1 mb-2"
        aria-labelledby="deductible"
      />
      {copay.messages?.length ? (
        <>
          <Label htmlFor="notes">Notes</Label>
          <StyledTextArea
            value={copay.messages.join("\n") || "N/A"}
            contentEditable={false}
            className="mt-1 mb-2"
            aria-labelledby="notes"
            autoResize
          />
        </>
      ) : null}
    </div>
  );
}
