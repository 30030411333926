import { validationConstants } from "constants/formValidation";
import useFormValidation from "hooks/useFormValidation";
import { Label } from "layout/typography/Label";
import { InputText } from "primereact/inputtext";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IDobPage } from "types/Customize";
import { OrderType } from "types/Order/OrderType";

import { StyledCard } from "../styled";

interface IDOBPageFields {
  "PatientResponsibility-headline"?: string;
  "Bundled-headline"?: string;
  "Bariatrics-headline"?: string;
  "ED-headline"?: string;
  "GFE-headline"?: string;
  "PatientResponsibility-description"?: string;
  "Bundled-description"?: string;
  "Bariatrics-description"?: string;
  "ED-description"?: string;
  "GFE-description"?: string;
  "PatientResponsibility-verifyButton"?: string;
  "Bundled-verifyButton"?: string;
  "Bariatrics-verifyButton"?: string;
  "ED-verifyButton"?: string;
  "GFE-verifyButton"?: string;
}

export function DOBForm({
  customFields,
  orderType,
}: {
  customFields: IDobPage;
  orderType: OrderType;
}) {
  const {
    control,
    trigger,
    formState: { errors },
    setValue,
  } = useFormContext<IDOBPageFields>();

  const { handleOnBlurField, getFormErrorMessage } = useFormValidation();

  useEffect(() => {
    setValue(`${orderType}-headline`, customFields?.headline);
    setValue(`${orderType}-description`, customFields?.description);
    setValue(`${orderType}-verifyButton`, customFields?.verifyButton);
  }, [customFields, setValue, orderType]);

  return (
    <StyledCard
      data-test-id="customize-dob-form"
      title="Verify Page Information"
    >
      <div className="flex flex-column gap-4 w-100">
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-headline`}
              data-test-id={`${orderType}-headline-label`}
            >
              Headline
            </Label>

            <Controller
              name={`${orderType}-headline`}
              control={control}
              rules={{
                required: validationConstants.CUSTOMIZE_FORM.HEADLINE,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id={`${orderType}-headline`}
                    autoComplete="off"
                    value={value || ""}
                    aria-labelledby={`${orderType}-headline`}
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field: `${orderType}-headline`,
                        trigger,
                      });
                    }}
                    onChange={onChange}
                  />

                  {getFormErrorMessage(`${orderType}-headline`, errors)}
                </>
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-description`}
              data-test-id={`${orderType}-description-label`}
            >
              Description
            </Label>

            <Controller
              name={`${orderType}-description`}
              control={control}
              rules={{
                required: validationConstants.CUSTOMIZE_FORM.DESCRIPTION,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id={`${orderType}-description`}
                    autoComplete="off"
                    value={value || ""}
                    aria-labelledby={`${orderType}-description`}
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field: `${orderType}-description`,
                        trigger,
                      });
                    }}
                    onChange={onChange}
                  />

                  {getFormErrorMessage(`${orderType}-description`, errors)}
                </>
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-verifyButton`}
              data-test-id={`${orderType}-verifyButton-label`}
            >
              Verify Button
            </Label>

            <Controller
              name={`${orderType}-verifyButton`}
              control={control}
              rules={{
                required: validationConstants.CUSTOMIZE_FORM.VERIFY_BUTTON,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id={`${orderType}-verifyButton`}
                    autoComplete="off"
                    value={value || ""}
                    aria-labelledby={`${orderType}-verifyButton`}
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field: `${orderType}-verifyButton`,
                        trigger,
                      });
                    }}
                    onChange={onChange}
                  />

                  {getFormErrorMessage(`${orderType}-verifyButton`, errors)}
                </>
              )}
            />
          </div>
        </div>
      </div>
    </StyledCard>
  );
}
