import { ItemBar } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { useQuery } from "hooks/useQuery";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCreateProviderMutation } from "store/queries/providers";

import { mapFormToRequestBody } from "../helpers/mapFormToRequestBody";

export function Submit() {
  const query = useQuery();
  const accountId = query.get("accountId") || undefined;
  const [useCreateProvider] = useCreateProviderMutation();
  const { current: toastElement } = useContext(ToastContext);
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const navigate = useNavigate();

  const createProvider = async () => {
    const requestBody = await mapFormToRequestBody(getValues());

    try {
      useCreateProvider(requestBody)
        .unwrap()
        .then(() => {
          toastElement?.show({
            severity: "success",
            summary: "Provider created",
            detail: "Provider created successfully",
          });
          navigate(`/hospitals/view/${accountId}?tab=1`);
        })
        .catch(() =>
          toastElement?.show({
            severity: "error",
            summary: "Error",
            detail: "An error has occurred attempting to create the provider",
          })
        );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ItemBar>
      <div
        data-testid="submit__Provider"
        className="flex flex-1 justify-content-end"
      >
        <Button
          label="Create Provider"
          onClick={createProvider}
          disabled={!isValid}
        />
      </div>
    </ItemBar>
  );
}
