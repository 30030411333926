import {
  IPayer,
  IPayerCreateRequest,
  IPayerUpdateRequest,
} from "types/Payers/Payers";
import { IPayerResponse } from "types/Payers/PayersResponse";

import { api } from ".";

const payerQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllPayers: builder.query<any, void>({
      query: () => "payer/",
    }),
    getPayersByAccount: builder.query<any, string>({
      query: (accountId) => `payer/account/${accountId}`,
    }),
    getPayerById: builder.query<IPayerResponse, string | undefined>({
      query: (id: string) => `payer/${id}`,
    }),
    createPayer: builder.mutation<IPayer, IPayerCreateRequest>({
      query: (newPayer) => ({
        url: "payer/",
        method: "POST",
        body: newPayer,
      }),
    }),
    updatePayer: builder.mutation<IPayer, IPayerUpdateRequest>({
      query: (updatedPayer) => ({
        url: `payer/${updatedPayer.id}`,
        method: "PUT",
        body: updatedPayer,
      }),
    }),
    deletePayer: builder.mutation<any, string>({
      query: (id) => ({
        url: `payer/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllPayersQuery,
  useGetPayersByAccountQuery,
  useDeletePayerMutation,
  useCreatePayerMutation,
  useGetPayerByIdQuery,
  useUpdatePayerMutation,
} = payerQueries;
