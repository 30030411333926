export function SmsIcon({ ...rest }) {
  return (
    <svg
      {...rest}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3335 2.8335H4.66683C2.66683 2.8335 1.3335 3.8335 1.3335 6.16683V10.8335C1.3335 13.1668 2.66683 14.1668 4.66683 14.1668H11.3335C13.3335 14.1668 14.6668 13.1668 14.6668 10.8335V6.16683C14.6668 3.8335 13.3335 2.8335 11.3335 2.8335ZM11.6468 6.8935L9.56016 8.56016C9.12016 8.9135 8.56016 9.08683 8.00016 9.08683C7.44016 9.08683 6.8735 8.9135 6.44016 8.56016L4.3535 6.8935C4.14016 6.72016 4.10683 6.40016 4.2735 6.18683C4.44683 5.9735 4.76016 5.9335 4.9735 6.10683L7.06016 7.7735C7.56683 8.18016 8.42683 8.18016 8.9335 7.7735L11.0202 6.10683C11.2335 5.9335 11.5535 5.96683 11.7202 6.18683C11.8935 6.40016 11.8602 6.72016 11.6468 6.8935Z"
        fill="#5B4DFD"
      />
    </svg>
  );
}
