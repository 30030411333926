import { DataTable } from "primereact/datatable";
import React, { ComponentProps } from "react";

type PageDataTableProps = ComponentProps<typeof DataTable> & {
  value: unknown[];
  children: React.ReactNode;
};

export function PageDataTable({
  value,
  children,
  ...rest
}: PageDataTableProps) {
  return (
    <DataTable
      {...rest}
      stripedRows
      value={value || []}
      className="p-fluid"
      responsiveLayout="scroll"
      style={{ marginLeft: "-1.2rem", marginRight: "-1.2rem" }}
    >
      {children}
    </DataTable>
  );
}
