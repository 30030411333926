import useDocumentTitle from "hooks/useDocumentTitle";
import { TabPanel, TabView } from "primereact/tabview";

import { ReportsTable } from "./component/ReportsTable";

export function Reports() {
  useDocumentTitle("List Reports");

  return (
    <TabView className="w-full">
      <TabPanel header="Reporting">
        <ReportsTable />
      </TabPanel>
    </TabView>
  );
}
