import { useAuth0 } from "@auth0/auth0-react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { getAuth0Config } from "config/api";
import { ToastContext } from "context/ToastContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Logo } from "layout/components/Logo";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import {
  usePreloginMutation,
  usePreloginVerifyMutation,
} from "store/queries/auth";

import {
  GenericLoginErrorConst,
  InvalidCodeErrorConst,
  TooManyAttemptsForCodeErrorConst,
  TooManyRequestsForCodeErrorConst,
} from "./mfaValidationConstants";

const mfaCookieName = "mfa-last-send-attempts";
dayjs.extend(utc);

export function ValidateMFA(): JSX.Element {
  const { logout } = useAuth0();
  const [code, setCode] = useState<string>("");
  const { current: toastElement } = useContext(ToastContext);
  const [codeError, setCodeError] = useState<string>("");

  const [searchParams] = useSearchParams();

  const state = searchParams.get("state");
  const token = searchParams.get("session_token") || "";

  const [preLogin, { error: preLoginError }] = usePreloginMutation();
  const [resendLoading, setResendLoading] = useState<boolean>(false);
  const [
    preLoginVerify,
    { error: preLoginVerifyError, isLoading: preLoginVerifyLoading },
  ] = usePreloginVerifyMutation();

  const [cookies, setCookie] = useCookies([mfaCookieName]);
  const sendCode = async (autoCodeSend: boolean) => {
    if (autoCodeSend) {
      // don't auto code send if the code has been sent already in last 5 minutes
      // this is to avoid situations where the user is just refereshing or navigating back and forth
      const lastSendTimestamp = cookies[mfaCookieName];
      if (
        lastSendTimestamp &&
        dayjs.utc(lastSendTimestamp).add(3, "minute").isAfter(dayjs.utc())
      ) {
        return;
      }
    }
    await preLogin({ token }).unwrap();
    setResendLoading(false);
    if (autoCodeSend) {
      setCookie(mfaCookieName, `${dayjs.utc().toISOString()}`);
    }
  };

  const verifyCode = async () => {
    try {
      await preLoginVerify({ token, code }).unwrap();
      window.location.assign(`${getAuth0Config().url}/continue?state=${state}`);
    } catch (e) {
      console.error(e);
    }
  };

  const resendCode = async () => {
    setResendLoading(true);
    await sendCode(false);
  };

  const reSignIn = async () => {
    logout();
  };

  useEffect(() => {
    async function sendCodeToEmail() {
      await sendCode(true);
    }

    sendCodeToEmail();
  }, [state]);

  useEffect(() => {
    if (
      preLoginVerifyError &&
      (preLoginVerifyError as FetchBaseQueryError)?.status === 400
    ) {
      setCodeError(InvalidCodeErrorConst.message);
      return;
    }
    if (
      preLoginVerifyError &&
      (preLoginVerifyError as FetchBaseQueryError)?.status === 429
    ) {
      setCodeError(TooManyAttemptsForCodeErrorConst.message);
      return;
    }

    setCodeError("");
    if (
      preLoginError &&
      (preLoginError as FetchBaseQueryError)?.status === 429
    ) {
      toastElement?.show({
        severity: "error",
        summary: TooManyRequestsForCodeErrorConst.title,
        sticky: true,
        detail: TooManyRequestsForCodeErrorConst.message,
      });
      return;
    }

    if (
      (preLoginError &&
        (preLoginError as FetchBaseQueryError)?.status === 401) ||
      (preLoginVerifyError &&
        (preLoginVerifyError as FetchBaseQueryError)?.status === 401)
    ) {
      // auth0 code has expired because the user has been sitting on the page for too long
      reSignIn();
    }

    if (preLoginVerifyError || preLoginError) {
      toastElement?.show({
        severity: "error",
        summary: GenericLoginErrorConst.title,
        sticky: true,
        detail: GenericLoginErrorConst.message,
      });
    }
  }, [preLoginError, preLoginVerifyError]);

  return (
    <div className="flex align-items-center justify-content-center flex-column bg-white pt-4 gap-2">
      <Logo />

      <Card>
        <h3>
          Your account is protected with two-factor authentication. We have sent
          you a code to the email address on file.
        </h3>
        <div className="flex flex-column gap-3 pt-4 pb-4">
          <div className="flex flex-column gap-1">
            <label htmlFor="code">Enter six digit code:</label>
            <InputText
              id="code"
              onChange={(e) => setCode(e.target.value)}
              required
              maxLength={6}
              className={codeError !== "" ? "p-invalid" : ""}
            />
            {codeError !== "" && <span className="p-error">{codeError}</span>}
          </div>
          <Button
            onClick={verifyCode}
            className="flex align-content-center justify-content-center gap-2"
            loading={preLoginVerifyLoading}
          >
            Continue
          </Button>
          <div className="flex gap-4">
            <Button
              onClick={resendCode}
              className="p-button-link p-0 flex gap-2"
              loading={resendLoading}
            >
              Resend Code
            </Button>
            <Button onClick={reSignIn} className="p-button-link p-0">
              Back to Sign-in
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
