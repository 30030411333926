export const formValidationConstants = {
  HOSPITAL_DETAILS: {
    NAME: "Hospital name is required.",
    NPI: "NPI is required.",
  },
  PAYMENT_AND_REMIT_DETAILS: {
    TAX_ID: "Tax Id is required.",
    EMAIL_TO_RECEIVE_REPORTS: "Email to receive reports is required.",
    REPORT_TYPE: "Report type is required.",
  },
  CLEAR_FEE: {
    CLEAR_FEE_FOR_CLEAR_PACKAGE: "Clear fee for clear package is required.",
    CLEAR_TRANSACTION_FEE_FOR_PR: "Clear transaction fee for PR is required.",
    PATIENT_RESPONSIBILITY_DISCOUNT:
      "Patient responsibility discount is required.",
    CLEAR_FINANCING_FEE: "Clear financing fee is required.",
    CLEAR_HEALTH_FEE_FOR_GFE: "Clear Health fee for GFE is required.",
    CLEAR_HEALTH_DISCOUNT_FOR_GFE: "Clear Health discount for GFE is required.",
  },
};
