import checkedImage from "assets/images/checked.svg";
import styled from "styled-components";

const StyledImg = styled.img`
  width: 160px;
  height: 160px;
`;

export function ConfirmationSuccess() {
  return (
    <>
      <StyledImg src={checkedImage as string} alt="Payment Successful" />
      <h2 className="mb-4">Payment Verified Successfully! You are all set!</h2>
    </>
  );
}
