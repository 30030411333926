import useFormValidation from "hooks/useFormValidation";
import { Label } from "layout/typography/Label";
import { AutoComplete } from "primereact/autocomplete";
import { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useGetAllPayersQuery } from "store/queries/payers";
import { useGetAllProvidersQuery } from "store/queries/providers";
import styled from "styled-components";

import { formValidationConstants } from "../helpers/formValidations";

const Title = styled.h3`
  margin-bottom: 16px;
`;

export function ProviderAndPayerEditSection() {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext<any>();
  const { getFormErrorMessage, handleOnBlurField } = useFormValidation();
  const { data, isLoading } = useGetAllProvidersQuery();
  const { data: allPayers, isLoading: allPayersLoading } =
    useGetAllPayersQuery();
  const [filteredProviders, setFilteredProviders] = useState<
    { name: string; value: string }[]
  >([]);
  const [filteredPayers, setFilteredPayers] = useState<
    { name: string; value: number }[]
  >([]);

  const providers = useMemo(() => {
    if (isLoading || !data) return [];

    return data?.data.map((provider) => {
      return { name: provider.name, value: provider.id };
    });
  }, [data]);

  const payers = useMemo(() => {
    if (allPayersLoading || !allPayers) return [];
    return allPayers?.data.map((payer: any) => {
      return { name: payer.name, value: payer.id };
    });
  }, [allPayers]);

  const searchProvider = (event: { query: string }) => {
    setTimeout(() => {
      let filteredProvidersInternal;

      if (!event.query.trim().length) {
        filteredProvidersInternal = [...providers];
      } else {
        filteredProvidersInternal = providers.filter((provider) => {
          return provider.name
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredProviders(filteredProvidersInternal);
    }, 250);
  };

  const searchPayer = (event: { query: string }) => {
    setTimeout(() => {
      let filteredPayersInternal;

      if (!event.query.trim().length) {
        filteredPayersInternal = [...payers];
      } else {
        filteredPayersInternal = payers.filter((payer: any) => {
          return payer.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }

      setFilteredPayers(filteredPayersInternal);
    }, 250);
  };
  return (
    <div className="block bg-white border-round p-4 mb-4">
      <Title>Provider and Payer Section</Title>
      <div className="formgrid grid">
        <div className="field col-6">
          <Label htmlFor="hospitalName">Provider</Label>
          <Controller
            data-testid="provider"
            name="provider"
            control={control}
            rules={{
              required: formValidationConstants.PROVIDER.PROVIDER,
            }}
            render={({ field: { onChange, value, onBlur } }) => (
              <>
                <AutoComplete
                  dropdown
                  field="name"
                  id="provider"
                  value={value}
                  className="w-full"
                  suggestions={filteredProviders}
                  completeMethod={searchProvider}
                  dropdownAriaLabel="Select Provider"
                  placeholder="Select Provider"
                  onChange={onChange}
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "provider", trigger });
                  }}
                />
                {getFormErrorMessage("provider", errors)}
              </>
            )}
          />
        </div>
        <div className="field col-6">
          <Label htmlFor="payerName">Payer Name</Label>
          <Controller
            name="payerName"
            control={control}
            rules={{
              required: formValidationConstants.PAYER_NAME.NAME,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <AutoComplete
                  dropdown
                  field="name"
                  id="payerName"
                  value={value}
                  className="w-full"
                  suggestions={filteredPayers}
                  completeMethod={searchPayer}
                  dropdownAriaLabel="Select Payer"
                  placeholder="Select Payer"
                  onChange={onChange}
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "payerName", trigger });
                  }}
                />
                {getFormErrorMessage("payerName", errors)}
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
}
