import { LoadingSpinner } from "components/LoadingSpinner";
import { UserRole, userRoleMapping } from "constants/userRole";
import useDocumentTitle from "hooks/useDocumentTitle";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useGetUserByIdQuery } from "store/queries/users";
import styled from "styled-components";

import { AccountIds } from "./components/AccountIds";
import { Header } from "./components/Header";
import { Submit } from "./components/Submit";
import { UserInfo } from "./components/UserInfo";

const Title = styled.h3`
  margin-bottom: 16px;
`;

export type UserDetailsFields = "name" | "email" | "scope" | "accountIds";

export function CreateEditUser() {
  const { id } = useParams();

  const { data, isFetching } = useGetUserByIdQuery(id, { skip: !id });
  const user = data?.data;

  useDocumentTitle(`${id ? "Edit" : "Create"} User`);

  const methods = useForm<any>({
    defaultValues: {
      name: "",
      email: "",
      scope: "",
      accountIds: [],
    },
  });

  const { reset } = methods;

  useEffect(() => {
    if (isFetching && !id) return;

    reset({
      name: user?.name,
      email: user?.email,
      accountIds: user?.accountIds,
      scope: userRoleMapping[user?.scope as UserRole],
    });
  }, [isFetching, id]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <FormProvider {...methods}>
      <Header />
      <div className="block bg-white border-round p-4">
        <Title>{id ? "Edit User" : "Create User"}</Title>

        <UserInfo />
        <AccountIds />
      </div>

      <Submit />
    </FormProvider>
  );
}
