import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { CaseRateRulesTable } from "./components/CaseRateRulesTable";
import PayerRulesTable from "./components/PayerRulesTable";
import PayersTable from "./components/PayersTable";

const StyledButton = styled(Button)`
  gap: ${sizer(2)};
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export function PayerRules() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <TabView
      className="w-full"
      activeIndex={Number(searchParams.get("tab"))}
      onTabChange={(e) => setSearchParams({ tab: e.index.toString() })}
    >
      <TabPanel header="MPPR">
        <FlexWrapper>
          <StyledButton
            icon="pi pi-plus"
            className="p-button-raised"
            onClick={() => navigate("/payer-rules/mppr/new")}
          >
            New Payer Rule
          </StyledButton>
        </FlexWrapper>
        <PayerRulesTable />
      </TabPanel>
      <TabPanel header="Case Rate">
        <FlexWrapper>
          <StyledButton
            icon="pi pi-plus"
            className="p-button-raised"
            onClick={() => navigate("/payer-rules/case-rate/new")}
          >
            New Payer Rule
          </StyledButton>
        </FlexWrapper>
        <CaseRateRulesTable />
      </TabPanel>
      <TabPanel header="Payers">
        <FlexWrapper>
          <StyledButton
            icon="pi pi-plus"
            className="p-button-raised"
            onClick={() => navigate("/payer-rules/payer/new")}
          >
            New Payer
          </StyledButton>
        </FlexWrapper>
        <PayersTable />
      </TabPanel>
    </TabView>
  );
}
