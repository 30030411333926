import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onAdminAccountChange } from "store/slices/user";
import styled from "styled-components";
import { IProvider, SpecialtyList } from "types/Provider/Provider";

import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { useUserWithAccount } from "../../../../context/UserAccountProvider";

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export function HospitalsTable() {
  const { accounts, loadingAccounts } = useUserWithAccount();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const parsedAccounts = useMemo(() => {
    if (!accounts?.data) return [];

    return accounts.data.map((aAccount) => {
      const theProvider = (aAccount.providers as unknown as IProvider[]).find(
        (aProvider) => {
          return aProvider.specialty === SpecialtyList.FACILITY;
        }
      );

      return {
        id: aAccount.id,
        hospitalName: aAccount.name,
        rcm: theProvider?.rcm,
        reportType: aAccount.reconciliationConfig?.type,
        pointOfContactName: theProvider?.paymentContact.contactName,
        pointOfContactPhone: theProvider?.paymentContact.contactPhone,
        pointOfContactEmail: theProvider?.paymentContact.contactEmail,
      };
    });
  }, [accounts]);

  function handleOnViewToViewHospital(accountId: any) {
    dispatch(onAdminAccountChange({ currentAccount: accountId }));
    navigate(`/hospitals/view/${accountId}`);
  }

  function accountAction(accountId: string) {
    return (
      <Row>
        <Button
          className="p-button-link"
          onClick={() => navigate(`/hospitals/edit/${accountId}`)}
        >
          <p className="small-text">Edit</p>
        </Button>
      </Row>
    );
  }

  function hospitalName(name: string) {
    return (
      <Row>
        <span style={{ color: "#4338ca" }}>{name}</span>
      </Row>
    );
  }

  if (loadingAccounts) {
    return <LoadingSpinner />;
  }

  return (
    <DataTable
      rows={10}
      paginator
      stripedRows
      className="p-fluid"
      filterDisplay="row"
      value={parsedAccounts}
      responsiveLayout="scroll"
      style={{ cursor: "pointer" }}
      emptyMessage="No hospitals to show."
      selectionMode="single"
      onRowClick={({ data }) => handleOnViewToViewHospital(data.id)}
    >
      <Column
        filter
        field="hospitalName"
        body={(row) => hospitalName(row.hospitalName)}
        style={{ minWidth: "320px" }}
        header="Hospital Name"
        filterPlaceholder="Search by hospital name"
      />
      <Column
        filter
        field="rcm"
        header="RCM"
        style={{ minWidth: "280px", textTransform: "capitalize" }}
        filterPlaceholder="Search by RCM"
      />
      <Column
        filter
        field="reportType"
        style={{ minWidth: "280px" }}
        header="Search by type"
        filterPlaceholder="Search by type"
      />
      <Column
        style={{ maxWidth: "100px" }}
        field="viewEditAccount"
        body={(row) => accountAction(row.id)}
      />
    </DataTable>
  );
}
