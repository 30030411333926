export const events = {
  CHECKOUT: "checkout",
  PURCHASE: "purchase",
  ORDER_CREATED: "order_created",
  ORDER_UPDATED: "order_updated",
  VIEW_VERIFY_PAGE: "view_verify_page",
  CLICK_PURCHASE: "click_purchase",
  CLICK_VERIFY_PAGE: "click_verify_button",
  CLICK_FINANCING: "click_financing",
  CLICK_ACCEPT_PAYMENT: "click_accept_payment",
  CARD_DETAILS_UPDATED: "card_details_updated",
};
