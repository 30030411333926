import undrawImage from "assets/images/undraw_not_found.svg";
import useDocumentTitle from "hooks/useDocumentTitle";
import { sizer } from "layout/styles/styled/sizer";
import styled from "styled-components";

const ContentContainer = styled.div`
  width: 100%;
  min-height: 100vh;

  padding: 48px 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  gap: ${sizer(3)};

  background: var(--bg-white);

  p {
    font-size: 24px;
    font-weight: bold;
  }

  img {
    width: 300px;
    height: 300px;
  }
`;

export function NotFound() {
  useDocumentTitle("Not Found page");
  return (
    <ContentContainer>
      <img src={undrawImage as string} alt="Not found" />
      <p>Oops! The page you&apos;re looking for doesn&apos;t exist.</p>
    </ContentContainer>
  );
}
