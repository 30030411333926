import { LoadingSpinner } from "components/LoadingSpinner";
import { parsePrice, centsToDollars } from "helpers/price";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator, PaginatorPageState } from "primereact/paginator";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetClearProviderProcedureByProviderMutation } from "store/queries/providerProcedure";
import { SpecialtyListDict, IProvider } from "types/Provider/Provider";

type ProvidersTableProps = {
  onSelectChange: (selectedRows: any) => void;
  selectedProviders: any[];
  providers?: { data: IProvider[]; total: number };
  first: number;
  rows: number;
  setFirst: (first: number) => void;
};

export function ProvidersTable({
  onSelectChange,
  selectedProviders,
  providers,
  first,
  rows,
  setFirst,
}: ProvidersTableProps) {
  const { id: accountId } = useParams();
  const navigate = useNavigate();
  const [getClearProviderProcedures, isLoading] =
    useGetClearProviderProcedureByProviderMutation();
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const parsedProviders = useMemo(() => {
    return providers?.data.map((aProvider: IProvider) => ({
      ...aProvider,
      numberOfProceduresLive: aProvider.numberOfProceduresLive,
      specialty: SpecialtyListDict[aProvider.specialty],
    }));
  }, [providers?.data]);

  const downloadProcedures = async (row: any) => {
    setSelectedRow(row);
    const providerId = row.id;
    const providerProceduresData = await getClearProviderProcedures({
      providerId,
    });
    if ("error" in providerProceduresData) {
      console.error(
        "Error fetching provider procedures:",
        providerProceduresData.error
      );
      return;
    }
    const providerProcedures = providerProceduresData.data.data;

    const headers = [
      "Provider",
      "CPT Code",
      "Description",
      "Price in USD",
      "Payer",
    ];
    const csvRows = [
      headers.join(","),
      ...providerProcedures.map((proc: any) =>
        [
          row.name,
          proc.procedure.cptCode,
          proc.procedure.description,
          `"${parsePrice(centsToDollars(proc.priceInCents))}"`,
          proc.payer.name,
        ].join(",")
      ),
    ];
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${row.name}-procedures.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setSelectedRow(null);
  };

  const onPageChange = (event: PaginatorPageState) => {
    setFirst(event.first);
  };

  function providerAction(id: string, showEdit = true) {
    if (!showEdit) {
      return null;
    }
    return (
      <Button
        className="p-button-link"
        onClick={() => navigate(`/providers/edit/${id}?accountId=${accountId}`)}
      >
        <p className="small-text">Edit</p>
      </Button>
    );
  }

  function providerProceduresDownload(row: any) {
    if (!row.hasClearHealthProcedure) {
      return <div style={{ height: "44px" }} />;
    }
    if (selectedRow === row && isLoading.isLoading) {
      return <LoadingSpinner customStyle={{ width: "44px", height: "44px" }} />;
    }
    return (
      <Button className="p-button-link" onClick={() => downloadProcedures(row)}>
        <p className="small-text">Clear Procedures</p>
      </Button>
    );
  }

  return (
    <>
      <DataTable
        rows={10}
        rowHover
        stripedRows
        dataKey="id"
        className="p-fluid"
        filterDisplay="row"
        value={parsedProviders || []}
        responsiveLayout="scroll"
        selection={selectedProviders}
        onSelectionChange={onSelectChange}
        emptyMessage="No providers to show."
      >
        <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
        <Column
          filter
          field="name"
          header="Provider Name"
          style={{ minWidth: "320px" }}
          filterPlaceholder="Search by provider name"
        />
        <Column
          filter
          field="specialty"
          header="Specialty"
          style={{ minWidth: "320px" }}
          filterPlaceholder="Search by specialty"
        />
        <Column
          field="numberOfProceduresLive"
          header="Number of procedures live"
          style={{ minWidth: "240px" }}
        />
        <Column
          style={{ minWidth: "200px" }}
          field="editProvider"
          body={(row) => providerAction(row.id, !row.mainFacility)}
        />
        <Column
          style={{ minWidth: "200px" }}
          field="clearProcedures"
          body={(row) => providerProceduresDownload(row)}
        />
      </DataTable>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={providers?.total}
        onPageChange={onPageChange}
      />
    </>
  );
}
