import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { Timeline } from "primereact/timeline";
import styled from "styled-components";

export const PageLayout = styled.div`
  padding: 5px;
  font-size: 14px;
  width: 90%;
  @media (min-width: 640px) {
    min-width: 80%;
  }
  @media (min-width: 870px) {
    min-width: 50% !important;
    max-width: 1000px !important;
  }
  .purple {
    color: #5b4dfd;
  }

  .black {
    color: black;
  }

  .gray {
    color: #766b6b;
  }

  .red {
    color: #ff0000;
  }

  .green {
    color: #35c92b;
  }

  .orange {
    color: #ff8c00;
  }
`;

export const StyledButton = styled(Button)`
  gap: ${sizer(2)};
  margin-bottom: ${sizer(4)};

  span:first-child {
    font-size: ${sizer(3)};
  }
`;

export const StyledHeadlineBanner = styled.div`
  height: fit-content;
  background: rgba(4, 198, 169, 0.1);
`;

export const StyledContainer = styled.div`
  margin-top: ${sizer(4)};
  padding: 1.5rem;
  background-color: var(--color-white);
  border-radius: 0.625rem;
  border: 1px solid rgb(218, 232, 255);
  box-shadow: rgba(247, 247, 247, 0.25) 0px 17px 20px;
  height: fit-content;

  @media (max-width: 425px) {
    padding: 1.5rem 0.5rem;
  }
`;

export const HorizontalLine = styled.hr`
  border-top: 1px solid #e5e5e5;
`;

export const PriceWrapper = styled.div`
  background: rgba(71, 105, 234, 0.05);
  border-radius: 0.5rem;
  padding: 0.625rem 1rem;
`;

export const StyledTimeline = styled(Timeline)`
  font-size: 13px !important;
  max-width: 100%;
  flex-wrap: wrap;
  row-gap: 30px;
  .p-timeline-event-opposite {
    flex: 0 0;
    min-width: 90px;
    padding-top: 0px !important;
    padding-left: 0px !important;
    display: flex;
    justify-content: left;
  }
  .p-timeline-event-content {
    padding-top: 0px !important;
    padding-right: 0px !important;
  }

  .p-timeline-event {
    min-width: 130px;
  }
`;

export const CardPageLayout = styled.div`
  max-width: 720px;
  padding: 5px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-self: center;

  .purple {
    color: #5b4dfd;
  }

  .black {
    color: black;
  }

  .gray {
    color: #766b6b;
  }

  .red {
    color: #ff0000;
  }

  .green {
    color: #35c92b;
  }
`;

export const ContainerForm = styled.form`
  max-width: 500px;
  h2 {
    text-align: center;

    color: #5b4dfd;

    font-weight: 600;
    font-size: 24px;
  }

  p {
    text-align: center;
    margin-bottom: 8px;

    font-weight: 700;
    font-size: 24px;

    color: #5b4dfd;
  }

  button {
    span {
      font-weight: bold;
    }
  }
`;
