import { sortBy } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetPayersByAccountQuery } from "store/queries/payers";
import { onPayerNameLoad } from "store/slices/order";

import { useQuery } from "./useQuery";

export function usePayerInformation(id?: string) {
  const dispatch = useDispatch();
  const { payersNameInfo = [] } = useSelector((state: any) => state.orderSlice);
  const query = useQuery();
  const accountId = query.get("accountId") || id || "";

  const { data: payerByAccount, isLoading: loadingPayersByAccount } =
    useGetPayersByAccountQuery(accountId, {
      skip: !accountId,
    });

  const payersName = payerByAccount;

  const generateNameOptions = () => {
    return payersName?.data.map((item: { [x: string]: any; id: any }) => {
      return { name: item.name, value: item.name };
    });
  };

  const payerNameCodes =
    payersName?.data.map((payer: any) => ({
      name: payer.name,
      code: payer.pverifyId,
    })) || [];

  useEffect(() => {
    if (payersName) {
      const generatedPayersName = generateNameOptions();
      dispatch(onPayerNameLoad(generatedPayersName));
    }
  }, [payersName, dispatch]);

  return {
    payerNameOptions: sortBy(payersNameInfo, ["name"]),
    payerNameCodes,
    loadingPayersName: loadingPayersByAccount,
  };
}
