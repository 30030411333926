import { orderTypeConstants } from "constants/order";
import { sizer } from "layout/styles/styled/sizer";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { cleanUpServiceDetails } from "store/slices/serviceDetails";
import styled from "styled-components";

import { DefaultServiceDetails } from "./components/DefaultServiceDetails";
import { GFEServiceDetails } from "./components/GFEServiceDetails";
import { PatientResponsibilityServiceDetails } from "./components/PatientResponsibilityServiceDetails";

export const FieldsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${sizer(4)};

  &:first-of-type {
    margin-right: 50px;
  }
`;

export function ServiceDetails() {
  const { watch, setValue } = useFormContext();
  const orderTypeField = watch("orderType");
  const dispatch = useDispatch();

  useEffect(() => {
    setValue("amount", null);
    setValue("totalAmount", null);
    setValue("estimatedVisitCostForPatient", null);
    setValue("totalInsurancePortion", null);
    setValue("totalInsuranceAllowable", null);
    setValue("serviceDetails", []);

    dispatch(cleanUpServiceDetails());
  }, [orderTypeField]);

  return (
    <>
      {(() => {
        switch (orderTypeField) {
          case orderTypeConstants.PATIENT_RESPONSIBILITY:
            return <PatientResponsibilityServiceDetails />;
          case orderTypeConstants.GFE:
            return <GFEServiceDetails />;
          default:
            return <DefaultServiceDetails />;
        }
      })()}
    </>
  );
}
