import { getAPIUrl } from "config/api";
import { ToastContext } from "context/ToastContext";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onWSClose, onWSStart } from "store/slices/websocket";

interface IWebSocketMessage {
  type: string;
  payload: any;
}

export function WebSocketComponent({
  children,
}: {
  children: React.ReactElement;
}) {
  const dispatch = useDispatch();
  const apiUrl = getAPIUrl();
  const url = apiUrl.replace(/^https?:\/\//, "");
  const protocol = apiUrl.startsWith("https:") ? "wss" : "ws";
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [reconnectTimeout, setReconnectTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const { current: toastElement } = useContext(ToastContext);

  const connectWebSocket = () => {
    const newSocket = new WebSocket(`${protocol}://${url}/ws`);

    newSocket.onopen = () => {
      console.log("WebSocket connection established");
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        setReconnectTimeout(null);
      }
    };

    newSocket.onmessage = ({ data }: { data: IWebSocketMessage }) => {
      const onMessageData = JSON.parse(data.toString());
      if (onMessageData.type === "FINISH_IMPORTING_FEE_SCHEDULE") {
        toastElement?.show({
          severity: "success",
          summary: "Fee schedule imported.",
          detail: `All the fee schedules for file ${data.payload.importedFeeSchedule.uploadedFileName} were imported successfully.`,
        });
      }
    };

    newSocket.onclose = () => {
      dispatch(onWSClose());
      const timeout = setTimeout(connectWebSocket, 5000);
      setReconnectTimeout(timeout);
    };

    dispatch(onWSStart(newSocket));
    setSocket(newSocket);
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
      dispatch(onWSClose());
    };
  }, []);

  return <div>{children}</div>;
}
