import { isValidEmail } from "helpers/email";
import { Label } from "layout/typography/Label";
import { Chips } from "primereact/chips";
import { InputText } from "primereact/inputtext";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

const Title = styled.h3`
  margin-bottom: 16px;
`;

type ProvidersFields =
  | "npi"
  | "taxPayorIdNumber"
  | "stripeAccountNumber"
  | "emailToReceiveNotification"
  | "emailToReceiveReports"
  | "contactName"
  | "contactPhone"
  | "contactEmail";

export function MoreInfoForm() {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext<any>();

  async function handleOnBlurField({
    onBlur,
    field,
  }: {
    onBlur: () => void;
    field: ProvidersFields;
  }) {
    trigger(field);
    onBlur();
  }
  function getFormErrorMessage(name: string) {
    return (
      errors[name] && (
        <small className="p-error">{errors[name]?.message as string}</small>
      )
    );
  }

  return (
    <div className="block bg-white border-round p-4">
      <Title>More Info</Title>
      <div className="formgrid grid">
        <div className="field col-4">
          <Label htmlFor="contactName">Contact Name</Label>
          <Controller
            name="contactName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="contactName"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter contact name here"
                aria-labelledby="contactName"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "contactName" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="contactEmail">Contact Email</Label>
          <Controller
            name="contactEmail"
            control={control}
            rules={{
              validate: (email) => {
                if (!email) return true;
                if (isValidEmail(email)) {
                  return true;
                }
                return "Should be real email.";
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  type="email"
                  className="w-full"
                  id="contactEmail"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter contact email here"
                  aria-labelledby="contactEmail"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "contactEmail" });
                  }}
                  onChange={onChange}
                />
                {getFormErrorMessage("contactEmail")}
              </>
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="contactPhone">Contact Phone</Label>
          <Controller
            name="contactPhone"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="contactPhone"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter contact phone here"
                aria-labelledby="contactPhone"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "contactPhone" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="npi">NPI</Label>
          <Controller
            name="npi"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="npi"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter npi here"
                aria-labelledby="npi"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "npi" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="taxPayorIdNumber">Tax Id</Label>
          <Controller
            name="taxPayorIdNumber"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="taxPayorIdNumber"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter tax id here"
                aria-labelledby="taxPayorIdNumber"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "taxPayorIdNumber" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="stripeAccountNumber">Stripe Connect Account Id</Label>
          <Controller
            name="stripeAccountNumber"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="stripeAccountNumber"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter stripe account number here"
                aria-labelledby="stripeAccountNumber"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "stripeAccountNumber" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="emailToReceiveReports">
            Emails for Payment reports
          </Label>
          <Controller
            name="emailToReceiveReports"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Chips
                id="emailToReceiveReports"
                name="emailToReceiveReports"
                aria-labelledby="emailToReceiveReports"
                value={value}
                onChange={onChange}
                className="w-full"
                placeholder="Enter email to receive reports here"
                onAdd={(event) => {
                  const isValid = isValidEmail(event.value);
                  return isValid;
                }}
                onBlur={() => {
                  handleOnBlurField({
                    onBlur,
                    field: "emailToReceiveReports",
                  });
                }}
              />
            )}
          />
        </div>
        <div className="field col-8">
          <Label htmlFor="emailToReceiveNotification">
            Emails to Receive Notifications
          </Label>
          <Controller
            name="emailToReceiveNotification"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Chips
                id="emailToReceiveNotification"
                name="emailToReceiveNotification"
                aria-labelledby="emailToReceiveNotification"
                value={value}
                onChange={onChange}
                className="w-full"
                placeholder="Enter email to receive reports here"
                onAdd={(event) => {
                  const isValid = isValidEmail(event.value);
                  return isValid;
                }}
                onBlur={() => {
                  handleOnBlurField({
                    onBlur,
                    field: "emailToReceiveNotification",
                  });
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
