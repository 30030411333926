import React, { Component, ErrorInfo, ReactNode } from "react";

import { Error } from "../../pages/Error/Error";

interface IProps {
  children?: ReactNode;
}

interface IState {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(error: Error): IState {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    // TO DO: integrate with: sentry?  cloudwatch ? another one?
  }

  public render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <Error />;
    }

    return children;
  }
}

export default ErrorBoundary;
