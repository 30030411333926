import useFormValidation from "hooks/useFormValidation";
import { Label } from "layout/typography/Label";
import { InputText } from "primereact/inputtext";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { formValidationConstants } from "../helpers/formValidations";

const Title = styled.h3`
  margin-bottom: 16px;
`;

export function DiscountPercents() {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { getFormErrorMessage, handleOnBlurField } = useFormValidation();
  const discountPercentages = [
    { field: "discountPercentForRank2", label: "2nd Procedure Discount" },
    { field: "discountPercentForRank3", label: "3rd Procedure Discount" },
    { field: "discountPercentForRank4", label: "4th Procedure Discount" },
    { field: "discountPercentForRank5Plus", label: "5th Procedure Discount" },
  ];
  return (
    <div className="block bg-white border-round p-4 mb-4">
      <Title>Discount Percentages</Title>
      <div className="formgrid grid">
        {discountPercentages.map(({ field, label }) => (
          <div className="field col-6" key={`${label}_${field}`}>
            <Label htmlFor={field}>{label}</Label>
            <Controller
              name={field}
              control={control}
              rules={{
                required: formValidationConstants.PROCEDURE_DISCOUNT.DISCOUNT,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    className="w-full"
                    id={field}
                    autoComplete="off"
                    type="number"
                    value={value || ""}
                    placeholder="Enter amount here"
                    aria-labelledby={field}
                    min={0}
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field,
                        trigger,
                      });
                    }}
                    onChange={onChange}
                  />
                  {getFormErrorMessage(field, errors)}
                </>
              )}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
