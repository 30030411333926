import { SpecialtyList, SpecialtyListDict } from "types/Provider/Provider";

export enum ReportType {
  PLINE = "PLINE",
  STANDARD = "STANDARD",
}

export enum RCM {
  PARALLON = "parallon",
  R1 = "r1",
  OTHER = "other",
}

export const rcmTypesDropdown = {
  [RCM.PARALLON]: {
    name: "Parallon",
    value: RCM.PARALLON,
  },
  [RCM.R1]: {
    name: "R1",
    value: RCM.R1,
  },
  [RCM.OTHER]: {
    name: "Other",
    value: RCM.OTHER,
  },
};

export const specialtyDropdown = {
  [SpecialtyList.ANESTHESIOLOGIST]: {
    name: SpecialtyListDict.ANESTHESIOLOGIST,
    value: SpecialtyList.ANESTHESIOLOGIST,
  },
  [SpecialtyList.PATHOLOGIST]: {
    name: SpecialtyListDict.PATHOLOGIST,
    value: SpecialtyList.PATHOLOGIST,
  },
  [SpecialtyList.FACILITY]: {
    name: SpecialtyListDict.FACILITY,
    value: SpecialtyList.FACILITY,
  },
  [SpecialtyList.PRIMARY_PROVIDER]: {
    name: SpecialtyListDict.PRIMARY_PROVIDER,
    value: SpecialtyList.PRIMARY_PROVIDER,
  },
  [SpecialtyList.ASSISTANT_PROVIDER]: {
    name: SpecialtyListDict.ASSISTANT_PROVIDER,
    value: SpecialtyList.ASSISTANT_PROVIDER,
  },
};
