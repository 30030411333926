import { Button } from "primereact/button";
import { FileUpload, ItemTemplateOptions } from "primereact/fileupload";
import { useEffect, useState } from "react";

type UploadFileProps = {
  name?: string;
  fileType?: "CSV";
  disabled?: boolean;
  customUploadOptions?: any;
  onSelectFile: (selectedFile: File) => void;
};

const fileTypes = {
  CSV: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
};

export function UploadFile({
  name,
  disabled,
  onSelectFile,
  fileType = "CSV",
  customUploadOptions,
}: UploadFileProps) {
  const [selectedFile, setSelectedFile] = useState({} as File);

  const uploadOptions = customUploadOptions || {
    style: { display: "none" },
  };

  useEffect(() => {
    onSelectFile(selectedFile);
  }, [selectedFile]);

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-file mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            color: "var(--surface-d)",
            backgroundColor: "var(--surface-b)",
          }}
        />
        <span
          className="my-5"
          style={{ fontSize: "16px", color: "var(--text-color-secondary)" }}
        >
          Drag and drop a {fileType} file here.
        </span>
      </div>
    );
  };

  const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
    const file = inFile as File;

    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Button
          type="button"
          icon="pi pi-times"
          {...{ disabled }}
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          // eslint-disable-next-line react/prop-types
          onClick={props.onRemove}
        />
      </div>
    );
  };

  return (
    <FileUpload
      auto
      customUpload
      {...{ name, disabled }}
      className="w-full"
      maxFileSize={1000000}
      itemTemplate={itemTemplate}
      accept={fileTypes[fileType]}
      emptyTemplate={emptyTemplate}
      uploadOptions={uploadOptions}
      uploadHandler={(e) => setSelectedFile(e.files[0])}
    />
  );
}
