import ChangeAccountDropdown from "components/ChangeAccountDropdown/ChangeAccountDropdown";
import PendingNotificationIcon from "components/PendingNotification/PendingNotification";
import { orderStatusConstants } from "constants/order";
import { useUserWithAccount } from "context/UserAccountProvider";
import useDocumentTitle from "hooks/useDocumentTitle";
import { sizer } from "layout/styles/styled/sizer";
import { TabPanel, TabView } from "primereact/tabview";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useGetOrdersQuery } from "store/queries/order";
import { userSelector } from "store/slices/user";
import styled from "styled-components";

import { OrdersTable } from "./components/OrdersTable";

const StyledContainer = styled.div`
  margin-bottom: ${sizer(8)};
`;

export function OrdersPage() {
  const { currentAccount, accountIds } = useSelector(userSelector);
  const [searchParams] = useSearchParams();
  const { accounts } = useUserWithAccount();
  useDocumentTitle("Order List");

  const selectedAccount = useMemo(() => {
    const accountId = searchParams.get("accountId");
    const accountIdToFind = accountId || currentAccount;
    return accounts?.data.find((aAccount) => aAccount.id === accountIdToFind);
  }, [currentAccount, accounts, searchParams]);

  const filteredAccounts = useMemo(() => {
    const list = accounts?.data.filter((aAccount) =>
      accountIds?.includes(aAccount.id)
    );
    return list || [];
  }, [accountIds, accounts]);

  const { data: orders } = useGetOrdersQuery(selectedAccount?.id);

  const pendingOrders = useMemo(() => {
    return orders?.data.filter(
      (order) => order.status === orderStatusConstants.DRAFT
    );
  }, [orders]);

  return (
    <>
      <StyledContainer className="flex flex-wrap gap-6 align-items-center">
        <h2>{selectedAccount?.name}</h2>
        <div className="flex gap-4 align-items-center">
          {filteredAccounts.length > 1 ? (
            <ChangeAccountDropdown
              defaultSelected={selectedAccount?.name || null}
              accounts={filteredAccounts}
            />
          ) : null}
          {pendingOrders?.length ? (
            <PendingNotificationIcon count={pendingOrders?.length || 0} />
          ) : null}
        </div>
      </StyledContainer>
      <TabView className="w-full">
        <TabPanel header="Orders">
          <OrdersTable accountId={selectedAccount?.id} />
        </TabPanel>
      </TabView>
    </>
  );
}
