import { Label } from "layout/typography/Label";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";

import { CoinsuranceItem } from "./CoinsuranceItem";
import { CopayItem } from "./CopayItem";

const StyledTextArea = styled(InputTextarea)``;

export function ValueNoteSection({
  title,
  items,
  className,
}: {
  title: string;
  items: {
    Notes?: string | null;
    Value?: string | null;
    subtitle?: string | null;
    additionalCopay?: any | null;
    additionalCoinsurance?: any | null;
  }[];
  className?: string;
}) {
  if (!items.length) {
    return null;
  }
  return (
    <div className={`flex flex-column ${className}`}>
      <p className="text-lg font-bold mb-4">{title}</p>
      <div className={`w-full ${items.length > 1 && "formgrid grid"}`}>
        {items.map((item, index) => (
          <div
            key={index}
            className={`flex flex-column mb-3 ${items.length > 1 && "col-6"}`}
          >
            {item.subtitle && (
              <p
                className="text-md mb-3 "
                style={{ color: "var(--color-purple)" }}
              >
                {item.subtitle}
              </p>
            )}
            <Label htmlFor="value">Value</Label>
            <InputText
              value={item.Value || "N/A"}
              disabled
              className="mt-1 mb-2"
              aria-labelledby="deductible"
            />
            {item.Notes ? (
              <>
                <Label htmlFor="notes">Notes</Label>
                <StyledTextArea
                  value={item.Notes || "N/A"}
                  contentEditable={false}
                  className="mt-1 mb-2"
                  aria-labelledby="notes"
                  autoResize
                />
              </>
            ) : null}
            {item?.additionalCopay?.map((copay: any, index: number) => (
              <CopayItem copay={copay} key={`${index}_additionalCopay`} />
            ))}
            {item?.additionalCoinsurance?.map(
              (coinsurance: any, index: number) => (
                <CoinsuranceItem
                  coinsurance={coinsurance}
                  key={`${index}_additionalCoinsurance`}
                />
              )
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
