import { IFileData } from "../CreateFeeSchedule";

export const normalizeKeys = (data: any[]): IFileData[] => {
  return data.map((row: { [x: string]: any }) => {
    const normalizedRow: IFileData = {
      priceInCents: 0,
      description: "",
      cptCode: "",
    };
    Object.entries(row).forEach(([key, value]) => {
      const normalizedKey = key.trim().toLowerCase().replace(/\s+/g, "_");
      if (normalizedKey === "rate") {
        normalizedRow.priceInCents = Math.round(
          parseFloat(value.toString().replace("$", "").replace(",", "")) * 100
        );
      } else if (normalizedKey === "cpt_name") {
        normalizedRow.description = value.toString();
      } else if (normalizedKey === "cpt_code") {
        normalizedRow.cptCode = value.toString();
      }
    });
    return normalizedRow;
  });
};
