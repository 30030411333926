import { Link, useMatch } from "react-router-dom";
import styled from "styled-components";

type CustomNavLinkProps = {
  to: string;
  matchLinks: string[];
  children: React.ReactNode;
};

const CustomLink = styled(Link)`
  all: unset;

  width: 50px;
  height: 50px;
  cursor: pointer;

  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--bg-white);

  :hover {
    background: var(--primary-10);
  }

  &.active {
    background: var(--primary-10);

    i {
      color: var(--color-purple);
    }
  }
`;

export function CustomNavLink({
  to,
  matchLinks,
  children,
}: CustomNavLinkProps) {
  const match = useMatch(matchLinks[0]);
  const match2 = useMatch(matchLinks[1]);

  return (
    <CustomLink
      {...{ to }}
      data-pr-tooltip="Hospitals"
      data-pr-position="right"
      className={`item-tooltip ${match || match2 ? "active" : ""}`}
    >
      {children}
    </CustomLink>
  );
}
