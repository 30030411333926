import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { setRows } from "store/slices/serviceDetails";
import { ProcedureRow } from "types/CreateOrder";

interface IProviderDropdown {
  row: ProcedureRow;
}
export function ProviderDropdown({ row }: IProviderDropdown) {
  const dispatch = useDispatch();
  const rows = useSelector((state: any) => state.serviceDetailsSlice.rows);

  const { providerDropdown, selectedProvider, id } = row;

  if (providerDropdown.length === 1) {
    return <span>{selectedProvider.name}</span>;
  }

  const handleOnChangeProviderDropdown = (e: DropdownChangeParams) => {
    let newRows = rows.slice() as ProcedureRow[];

    const provider = providerDropdown.find(
      (provider) => provider.id === e.value
    );

    const rowIndex = newRows.findIndex((r: ProcedureRow) => r.id === id);

    if (provider) {
      newRows = [
        ...newRows.slice(0, rowIndex),
        {
          ...newRows[rowIndex],
          selectedProvider: provider,
        },
        ...newRows.slice(rowIndex + 1),
      ];
      dispatch(setRows(newRows));
    }
  };

  return (
    <Dropdown
      data-testid="providerDropdown_ServiceDetails"
      optionValue="id"
      optionLabel="name"
      id="selectProvider"
      options={providerDropdown}
      value={selectedProvider.id}
      placeholder="Select Provider"
      onChange={handleOnChangeProviderDropdown}
    />
  );
}
