function EmptyTemplate() {
  return (
    <div className="flex align-items-center flex-column">
      <i
        className="pi pi-file mt-3 p-5"
        style={{
          fontSize: "5em",
          borderRadius: "50%",
          color: "var(--surface-d)",
          backgroundColor: "var(--surface-b)",
        }}
      />
      <span
        className="my-5"
        style={{ fontSize: "16px", color: "var(--text-color-secondary)" }}
      >
        Drag and drop a CSV file here.
      </span>
    </div>
  );
}
export default EmptyTemplate;
