import { sizer } from "layout/styles/styled/sizer";
import { Dialog } from "primereact/dialog";
import styled from "styled-components";

export const ContainerForm = styled.form`
  max-width: 500px;
  h2 {
    text-align: center;

    color: #5b4dfd;

    font-weight: 600;
    font-size: 24px;
  }

  p {
    text-align: center;
    margin-bottom: 8px;

    font-weight: 700;
    font-size: 24px;

    color: #5b4dfd;
  }

  button {
    span {
      font-weight: bold;
    }
  }
`;

export const DialogContainer = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;
  align-items: center;

  button {
    width: 173px;
    display: flex;
    justify-content: center;
    font-size: 18px;
  }

  img {
    margin-bottom: ${sizer(3)};
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: ${sizer(6.5)};
  }

  p {
    color: #383737;
    font-size: 14px;

    width: 70%;
    text-align: center;

    @media (max-width: 720px) {
      width: 100%;
    }

    span {
      font-weight: bold;
    }
  }
`;

export const Pill = styled.div`
  color: #ff0000;
  text-align: center;
`;

export const StyledDialog = styled(Dialog)`
  width: 40vw;

  @media (max-width: 720px) {
    width: 90vw;
  }
`;

export const EmailContainer = styled.div`
  label {
    font-size: 0.93rem;
    color: #30313d;
    opacity: 0.8;
    margin-bottom: ${sizer(1)};
  }
`;
