import {
  IAccount,
  IAccountCreateRequest,
  IAccountUpdateRequest,
} from "types/Account/Account";
import {
  IAccountResponse,
  IAllAccountResponse,
} from "types/Account/AccountResponse";

import { api } from ".";

const accountQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrent: builder.query<IAccountResponse, null | void>({
      query: () => "accounts/current",
    }),
    getAccountById: builder.query<IAccountResponse, string>({
      query: (id: string) => `accounts/${id}`,
    }),
    getAllAccounts: builder.query<IAllAccountResponse, null | void>({
      query: () => "accounts/all",
    }),
    createAccount: builder.mutation<IAccount, IAccountCreateRequest>({
      query: (newAccount) => ({
        url: "accounts",
        method: "POST",
        body: newAccount,
      }),
    }),
    updateAccount: builder.mutation<IAccount, IAccountUpdateRequest>({
      query: (updatedAccount) => ({
        url: `accounts/${updatedAccount.id}`,
        method: "PUT",
        body: updatedAccount,
      }),
    }),
  }),
});

export const {
  useGetCurrentQuery,
  useGetAllAccountsQuery,
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useGetAccountByIdQuery,
} = accountQueries;
