import useDocumentTitle from "hooks/useDocumentTitle";
import { useForm, FormProvider } from "react-hook-form";

import { AllowDuplicateCptCodes } from "./components/AllowDuplicateCptCodes";
import { CptCodes } from "./components/CptCodes";
import { DiscountPercents } from "./components/DiscountPercents";
import { Header } from "./components/Header";
import { ProviderAndPayerSection } from "./components/ProviderAndPayerSection";
import { RuleName } from "./components/RuleName";
import { Submit } from "./components/Submit";

export function CreatePayerRulesPage() {
  useDocumentTitle("Create Payer Rules");

  const methods = useForm({
    defaultValues: {
      provider: [],
      payerName: "",
      groupName: "",
      cptCodes: [],
      discountPercentForRank2: null,
      discountPercentForRank3: null,
      discountPercentForRank4: null,
      discountPercentForRank5Plus: null,
      allowOnDuplicateCptCodes: false,
    },
  });
  return (
    <FormProvider {...methods}>
      <Header />
      <ProviderAndPayerSection />
      <RuleName />
      <AllowDuplicateCptCodes />
      <CptCodes />
      <DiscountPercents />
      <Submit />
    </FormProvider>
  );
}
