import { api } from ".";

const reconciliationQuery = api.injectEndpoints({
  endpoints: (builder) => ({
    getReconciliation: builder.query<any, void>({
      query: () => "reconciliation",
    }),
  }),
});

export const { useGetReconciliationQuery } = reconciliationQuery;
