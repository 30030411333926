import { ItemBar } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCreatePayerMutation } from "store/queries/payers";

export function SubmitPayer() {
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const navigate = useNavigate();
  const [createPayer] = useCreatePayerMutation();

  const { current: toastElement } = useContext(ToastContext);

  const handleCreatePayer = async () => {
    const values = getValues();
    const payerToCreate = {
      name: values.name,
      parent: values.parent,
      pverifyId: values.pverifyId,
      locality: [],
      placeOfService: [],
      medtelIds: values.medtelIds,
    };
    createPayer(payerToCreate)
      .unwrap()
      .then(() => {
        navigate("/payer-rules?tab=2");
        toastElement?.show({
          severity: "success",
          summary: "Payer created",
          detail: "Payer created successfully.",
        });
      })
      .catch((error) =>
        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail: `Message: ${error}`,
        })
      );
  };
  return (
    <ItemBar>
      <div
        data-testid="submit__Payer"
        className="flex flex-1 justify-content-end"
      >
        <Button
          label="Create Payer"
          onClick={handleCreatePayer}
          disabled={!isValid}
        />
      </div>
    </ItemBar>
  );
}
