import { LoadingSpinner } from "components/LoadingSpinner";
import styled from "styled-components";

const LoadingContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.5);
`;

export function Loading() {
  return (
    <LoadingContainer>
      <LoadingSpinner customStyle={{ width: "60px", height: "60px" }} />
    </LoadingContainer>
  );
}
