import { ItemBar } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCreateCaseRateRulesetMutation } from "store/queries/pricing";

import {
  mapCaseRateFormToRequestBody,
  getRulesetList,
} from "../helpers/mapFormToRequestBody";

export function SubmitCaseRate() {
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const navigate = useNavigate();
  const [useCreateCaseRateRuleset] = useCreateCaseRateRulesetMutation();

  const { current: toastElement } = useContext(ToastContext);

  const createPayerRule = async () => {
    const values = getValues();
    const rulesToCreateRaw = getRulesetList(values, false);
    const rulesToCreate = rulesToCreateRaw.map((rule) => {
      return mapCaseRateFormToRequestBody(rule);
    });
    try {
      const results = await Promise.all(
        rulesToCreate.map((rule) =>
          useCreateCaseRateRuleset(rule)
            .unwrap()
            .catch(() => false)
        )
      );
      const allSuccessful = results.every((result) => result !== false);
      if (allSuccessful) {
        toastElement?.show({
          severity: "success",
          summary: "Payer Ruleset(s) created",
          detail: "Payer Ruleset(s) created successfully",
        });
        navigate("/payer-rules?tab=1");
      } else {
        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail:
            "An error has occurred attempting to create one or more discount rulesets",
        });
        navigate("/payer-rules?tab=1");
      }
    } catch (error) {
      toastElement?.show({
        severity: "error",
        summary: "Error",
        detail: "An error has occurred",
      });
    }
  };
  return (
    <ItemBar>
      <div
        data-testid="submit__Hospital"
        className="flex flex-1 justify-content-end"
      >
        <Button
          label="Create Payer Rule"
          onClick={createPayerRule}
          disabled={!isValid}
        />
      </div>
    </ItemBar>
  );
}
