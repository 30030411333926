import { LoadingSpinner } from "components/LoadingSpinner";
import {
  ED_ORDER_TYPE_EXPIRATION_PERIOD,
  orderTypeConstants,
} from "constants/order";
import { events } from "constants/tagManager";
import { centsToDollars } from "helpers/price";
import { useAnalytics } from "hooks/useAnalytics";
import useDocumentTitle from "hooks/useDocumentTitle";
import usePrice from "hooks/usePrice";
import { useQuery } from "hooks/useQuery";
import { debounce, isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useGetCustomsByOrderTypeAndAccountIdQuery } from "store/queries/customize";
import { useGetPublicOrderQuery } from "store/queries/order";
import { onChangeAccountInfo } from "store/slices/order";
import { IOrder } from "types/Order/Order";
import { IProvider } from "types/Provider/Provider";

import { Home } from "./components/Home";
import PaymentPlan from "./components/PaymentSections/PaymentPlanSection";
import PayNow from "./components/PaymentSections/PayNowSection";
import { groupByCodeDescriptionAndCount } from "./helpers";
import { PageLayout, StyledButton } from "./styled";

const DEFAULT_EXPIRATION_DAYS = 2;

export function Overview() {
  useDocumentTitle("Order Overview");
  const query = useQuery();
  const { id: externalId } = useParams();

  const internalUser = query.get("internalUser");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getClearPriceByOrderType, getInsuranceAmounts, insuranceAmounts } =
    usePrice();
  const { pageViewEvents } = useAnalytics();

  const [disclaimerDialogVisible, setDisclaimerDialogVisible] = useState(false);

  const [showPaymentSection, setShowPaymentSection] = useState({
    paymentSection: false,
    paymentPlan: false,
  });

  const [showClearEstimateDetailsSection, setShowClearEstimateDetailsSection] =
    useState(false);

  const { data, isLoading, isError } = useGetPublicOrderQuery(externalId || "");

  useEffect(() => {
    getInsuranceAmounts(data?.data.gfeQuote?.pricingBreakdown);
  }, [data?.data.gfeQuote?.pricingBreakdown]);

  const debouncedPageViewEvents = debounce((order, event: string) => {
    pageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.communicationMethod,
      },
      event
    );
  }, 300);

  useEffect(() => {
    if (isEmpty(data)) return;

    debouncedPageViewEvents(
      {
        accountName: data?.data.account.name,
        orderType: data?.data.orderType,
        communicationMethod: data?.data.patient.preferredContactMethod,
      },
      events.CHECKOUT
    );
  }, [data]);

  const orderInfo = useMemo(() => {
    const accountInfo = data?.data.account;

    if (accountInfo) {
      dispatch(onChangeAccountInfo({ accountInfo }));
    }

    return data?.data || ({} as IOrder);
  }, [data]);

  const { data: contentData, isFetching: isContentFetching } =
    useGetCustomsByOrderTypeAndAccountIdQuery(
      { orderType: orderInfo.orderType, accountId: orderInfo.accountId },
      {
        skip: !orderInfo.orderType,
      }
    );

  const { overviewPage: content } = contentData?.data || {};

  if (!orderInfo || isError) {
    return <Navigate to="/not-found" />;
  }

  // We can't use the useLoading hook because the clientSecret is required to load the payment for now.
  if (isLoading || isContentFetching) {
    return <LoadingSpinner />;
  }

  const prices = getClearPriceByOrderType({ order: orderInfo });
  const { percentageOff } = prices;

  const fullPrice = centsToDollars(prices.total);
  const clearPrice = centsToDollars(prices.clearPrice);
  const totalSaving = centsToDollars(prices.totalSaving);

  const physicianFee = centsToDollars(prices.physicianFee || 0);
  const facilityFee = centsToDollars(prices.facilityFee || 0);

  const getExpirationDays = () => {
    if (isLoading) return DEFAULT_EXPIRATION_DAYS;

    if (orderInfo.orderType === orderTypeConstants.ED) {
      return ED_ORDER_TYPE_EXPIRATION_PERIOD;
    }

    return (orderInfo.account.providers[0] as unknown as IProvider)
      .expirationDays;
  };

  const expirationDays = getExpirationDays();

  const publicContact = () => {
    const currentProvider = orderInfo.account
      .providers[0] as unknown as IProvider;

    const isVisible =
      currentProvider.publicContactName &&
      currentProvider.publicContactRole &&
      currentProvider.publicContactPhone;

    return {
      isVisible,
      name: currentProvider.publicContactName,
      role: currentProvider.publicContactRole,
      phone: currentProvider.publicContactPhone,
    };
  };

  const showClearEstimateDetails = () => {
    setShowClearEstimateDetailsSection(!showClearEstimateDetailsSection);
  };

  const homeView =
    !showPaymentSection.paymentSection && !showPaymentSection.paymentPlan;

  const {
    totalDeductibleInCents,
    totalCoinsuranceInCents,
    totalInsurancePayInCents,
    totalFlatCopayInCents,
    totalAllowedInsuranceAmount,
  } = insuranceAmounts;

  return (
    <PageLayout className="flex flex-column md:mt-3">
      {internalUser && (
        <StyledButton
          icon="pi pi-arrow-left"
          onClick={() => navigate(`/orders/${orderInfo.id}`)}
          className="p-button-text p-button-secondary p-0"
        >
          <p className="text-xs">Back to order</p>
        </StyledButton>
      )}

      {showPaymentSection.paymentSection && (
        <PayNow
          {...{
            expirationDays,
            orderInfo,
            showPaymentSection,
            setShowPaymentSection,
            clearPrice,
            totalSaving,
            fullPrice,
          }}
        />
      )}
      {showPaymentSection.paymentPlan && (
        <PaymentPlan
          {...{
            expirationDays,
            orderInfo,
            showPaymentSection,
            setShowPaymentSection,
            clearPrice,
            totalSaving,
            fullPrice,
          }}
        />
      )}

      {homeView && (
        <Home
          orderInfo={orderInfo}
          content={content}
          showPaymentSection={showPaymentSection}
          setShowPaymentSection={setShowPaymentSection}
          expirationDays={expirationDays}
          physicianFee={physicianFee}
          facilityFee={facilityFee}
          totalDeductibleInCents={totalDeductibleInCents}
          totalCoinsuranceInCents={totalCoinsuranceInCents}
          totalInsurancePayInCents={totalInsurancePayInCents}
          totalFlatCopayInCents={totalFlatCopayInCents}
          totalAllowedInsuranceAmount={totalAllowedInsuranceAmount}
          publicContact={publicContact}
          showClearEstimateDetails={showClearEstimateDetails}
          showClearEstimateDetailsSection={showClearEstimateDetailsSection}
          procedures={groupByCodeDescriptionAndCount(
            orderInfo.serviceDetails.procedures
          )}
          isGFE={orderInfo.orderType === orderTypeConstants.GFE}
          clearPrice={clearPrice}
          totalSaving={totalSaving}
          fullPrice={fullPrice}
          percentageOff={percentageOff}
          setDisclaimerDialogVisible={setDisclaimerDialogVisible}
          disclaimerDialogVisible={disclaimerDialogVisible}
        />
      )}
    </PageLayout>
  );
}
