export const handleChangeDateWithMask = (
  e: React.ChangeEvent<HTMLInputElement>,
  callback: (...event: any[]) => void
) => {
  let val = e.target.value;

  val = val.replace(/\D/g, "");
  val = val.replace(/(\d{2})(\d)/, "$1/$2");
  val = val.replace(/(\d{2})(\d)/, "$1/$2");
  val = val.substring(0, 10);

  callback(val);
};

export const handleChangePhoneWithMask = (
  e: React.ChangeEvent<HTMLInputElement>,
  callback: (...event: any[]) => void
) => {
  let val = e.target.value;

  val = val.replace(/\D/g, "");
  val = val.replace(/(\d{3})(\d)/, "($1) $2");
  val = val.replace(/(\d{3})(\d)/, "$1-$2");
  val = val.substring(0, 14);

  callback(val);
};

export const handleChangeTimeWithMask = (
  e: React.ChangeEvent<HTMLInputElement>,
  callback: (...event: any[]) => void
) => {
  let val = e.target.value.toUpperCase();

  val = val.replace(/[^0-9APM]/g, "");
  val = val.replace(/(\d{2})(\d)/, "$1:$2");
  val = val.replace(/(\d{2}:)(\d{2})([APM])/g, "$1$2 $3");
  val = val.substring(0, 8);

  callback(val);
};

export function formatPhoneNumberToMask(phoneNumber: string): string {
  const rawNumber = phoneNumber.replace(/\D/g, "");
  const formattedNumber = rawNumber.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "($1) $2-$3"
  );
  return formattedNumber;
}
