import { ItemBar } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledH2 = styled.h2`
  margin-bottom: ${sizer(8)};
`;

export function Header({
  isEditing = false,
  name = "",
  isCaseRate = false,
  isPayer = false,
}: {
  isEditing?: boolean;
  name?: string;
  isCaseRate?: boolean;
  isPayer?: boolean;
}) {
  const navigate = useNavigate();
  const { current: toastElement } = useContext(ToastContext);

  const backToPayerRulesList = () => {
    if (isCaseRate) {
      navigate("/payer-rules?tab=1");
    } else if (isPayer) {
      navigate("/payer-rules?tab=2");
    } else {
      navigate("/payer-rules");
    }
    toastElement?.show({
      severity: "warn",
      summary: "Exit creation",
      detail: "Progress not saved",
    });
  };

  return (
    <ItemBar>
      {isEditing && <StyledH2>{name}</StyledH2>}
      <div
        data-testid="header__PayerRulesCreate"
        className="flex flex-1 justify-content-end"
      >
        <Button label="Cancel" onClick={backToPayerRulesList} />
      </div>
    </ItemBar>
  );
}
