import { specialtyDropdown } from "helpers/providerConstants";
import { Label } from "layout/typography/Label";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { SpecialtyList } from "types/Provider/Provider";

import { formValidationConstants } from "../helpers/formValidations";

const Title = styled.h3`
  margin-bottom: 16px;
`;

type ProvidersFields = "name" | "specialty";

export function ProviderDetailsForm() {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext<any>();

  async function handleOnBlurField({
    onBlur,
    field,
  }: {
    onBlur: () => void;
    field: ProvidersFields;
  }) {
    trigger(field);
    onBlur();
  }

  function getFormErrorMessage(name: string) {
    return (
      errors[name] && (
        <small className="p-error">{errors[name]?.message as string}</small>
      )
    );
  }

  return (
    <div className="block bg-white border-round p-4">
      <Title>Provider Details</Title>
      <div className="formgrid grid">
        <div className="field col-8">
          <Label htmlFor="name">Provider Name</Label>
          <Controller
            name="name"
            control={control}
            rules={{
              required: formValidationConstants.PROVIDER_DETAILS.NAME,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  className="w-full"
                  id="name"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter provider name here"
                  aria-labelledby="name"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "name" });
                  }}
                  onChange={onChange}
                />

                {getFormErrorMessage("name")}
              </>
            )}
          />
        </div>
        <div className="field col-4">
          <Label htmlFor="specialty">Specialty</Label>
          <Controller
            data-testid="specialty"
            name="specialty"
            control={control}
            rules={{
              required: formValidationConstants.PROVIDER_DETAILS.SPECIALTY,
            }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                id="specialty"
                data-testid="specialty__Dropdown"
                value={value}
                onChange={onChange}
                options={Object.values(specialtyDropdown).filter(
                  (aSpecialty) => aSpecialty.name !== SpecialtyList.FACILITY
                )}
                optionLabel="name"
                placeholder="Select"
                className="w-full"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
