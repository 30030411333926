import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";

interface IAuthStateProps {
  token: string;
  publicToken: string;
}

const initialState: IAuthStateProps = {
  token: "",
  publicToken: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    onTokenChange: (state, action: { payload: { token: string } }) => {
      state.token = action.payload.token;
    },
    onPublicTokenChange: (state, action: { payload: { token: string } }) => {
      state.publicToken = action.payload.token;
    },
  },
});

export const { onTokenChange, onPublicTokenChange } = authSlice.actions;
export const authSelector = (state: RootState) => state.authSlice;

export default authSlice.reducer;
