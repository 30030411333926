import useDocumentTitle from "hooks/useDocumentTitle";
import { useForm, FormProvider } from "react-hook-form";

import { CptCodes } from "./components/CptCodes";
import { ExcludedCptCodes } from "./components/ExcludedCptCodes";
import { Header } from "./components/Header";
import { ProviderAndPayerSection } from "./components/ProviderAndPayerSection";
import { RuleName } from "./components/RuleName";
import { SubmitCaseRate } from "./components/SubmitCaseRate";

export function CreateCaseRateRulesPage() {
  useDocumentTitle("Create Case Rate Rules");

  const methods = useForm({
    defaultValues: {
      provider: [],
      payerName: "",
      groupName: "",
      cptCodes: [],
      excludedCptCodes: [],
    },
  });
  return (
    <FormProvider {...methods}>
      <Header isCaseRate />
      <ProviderAndPayerSection />
      <RuleName />
      <CptCodes />
      <ExcludedCptCodes />
      <SubmitCaseRate />
    </FormProvider>
  );
}
