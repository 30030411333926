import { orderTypeConstants } from "constants/order";
import { isValidEmail } from "helpers/email";
import { Label } from "layout/typography/Label";
import { orderTypes } from "pages/CreateOrder/components/Header/constants";
import { Button } from "primereact/button";
import { Chips } from "primereact/chips";
import { Dropdown } from "primereact/dropdown";
import { FileUpload, ItemTemplateOptions } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

const Title = styled.h3`
  margin-bottom: 16px;
`;

const PatientResponsibilityContent = styled.div<{ show: boolean }>`
  width: 100%;
  display: ${(prop) => (prop.show ? "contents" : "none")};
`;

const BundleContent = styled.div<{ show: boolean }>`
  width: 100%;
  display: ${(props) => (props.show ? "contents" : "none")};
`;

const BariatricsContent = styled.div<{ show: boolean }>`
  width: 100%;
  display: ${(props) => (props.show ? "contents" : "none")};
`;

const EDContent = styled.div<{ show: boolean }>`
  width: 100%;
  display: ${(props) => (props.show ? "contents" : "none")};
`;

const GFEContent = styled.div<{ show: boolean }>`
  width: 100%;
  display: ${(props) => (props.show ? "contents" : "none")};
`;

type OrderCommunicationDetailsFields =
  | "messageSender"
  | "emailSender"
  | "emailToReceiveNotification"
  | "frontDeskEmployeesToReceiveDailyEmail"
  | "emailTemplateAttempt1"
  | "attempt1"
  | "emailTemplateAttempt2"
  | "attempt2"
  | "emailTemplateAttempt3"
  | "attempt3"
  | "emailTemplateAttemptBundle1"
  | "attemptBundle1"
  | "emailTemplateAttemptBundle2"
  | "attemptBundle2"
  | "emailTemplateAttemptBundle3"
  | "attemptBundle3"
  | "accountLogoUrl"
  | "emailTemplateAttemptED1"
  | "attemptED1"
  | "emailTemplateAttemptED2"
  | "attemptED2"
  | "emailTemplateAttemptED3"
  | "attemptED3"
  | "emailTemplateAttemptBariatrics1"
  | "attemptBariatrics1"
  | "emailTemplateAttemptBariatrics2"
  | "attemptBariatrics2"
  | "emailTemplateAttemptBariatrics3"
  | "attemptBariatrics3"
  | "emailTemplateAttemptGFE1"
  | "attemptGFE1"
  | "emailTemplateAttemptGFE2"
  | "attemptGFE2"
  | "emailTemplateAttemptGFE3"
  | "attemptGFE3";

export function OrderCommunicationDetails() {
  const { control, trigger, setValue, watch } = useFormContext<any>();
  const orderType = watch("orderType");

  async function handleOnBlurField({
    onBlur,
    field,
  }: {
    onBlur: () => void;
    field: OrderCommunicationDetailsFields;
  }) {
    trigger(field);
    onBlur();
  }

  const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
    const file = inFile as File;
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          // eslint-disable-next-line react/prop-types
          onClick={props.onRemove}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        />
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const uploadOptions = {
    style: { display: "none" },
  };

  const convertToBase64 = (file: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const customBase64Uploader = async (event: any) => {
    const file = event.files[0];
    const convertedFile = await convertToBase64(file);
    setValue("accountLogoUrl", convertedFile);
  };

  return (
    <div className="block bg-white border-round p-4">
      <Title>Order Communication Details</Title>
      <div className="formgrid grid">
        <div className="field col-12">
          <Controller
            name="accountLogoUrl"
            control={control}
            render={({ field: { ref, name } }) => (
              <FileUpload
                ref={ref}
                name={name}
                accept="image/*"
                maxFileSize={1000000}
                itemTemplate={itemTemplate}
                emptyTemplate={emptyTemplate}
                uploadOptions={uploadOptions}
                customUpload
                uploadHandler={customBase64Uploader}
                auto
              />
            )}
          />
        </div>
        <div className="field col-6">
          <Label htmlFor="messageSender">Message Sender</Label>
          <Controller
            name="messageSender"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="messageSender"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter message sender here"
                aria-labelledby="messageSender"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "messageSender" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="field col-6">
          <Label htmlFor="emailSender">Email sender</Label>
          <Controller
            name="emailSender"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="emailSender"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter email sender here"
                aria-labelledby="emailSender"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "emailSender" });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="field col-12">
          <Label htmlFor="orderType">Order Type</Label>
          <Controller
            name="orderType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                id="orderType"
                data-testid="orderType"
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
                options={Object.values(orderTypes)}
                optionLabel="name"
                placeholder="Select"
                className="w-full"
              />
            )}
          />
        </div>
        <div className="field col-6">
          <h3>Mobile Alert Messages</h3>
        </div>
        <div className="field col-6">
          <h3>Email Alert Messages</h3>
        </div>

        <PatientResponsibilityContent
          show={!!(orderType === orderTypeConstants.PATIENT_RESPONSIBILITY)}
        >
          <div className="field col-6">
            <Label htmlFor="attempt1">Attempt 1</Label>
            <Controller
              name="attempt1"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attempt1"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attempt1"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attempt1" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttempt1">
              Email Template ID: Attempt 1
            </Label>
            <Controller
              name="emailTemplateAttempt1"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttempt1"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttempt1"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttempt1",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="attempt2">Attempt 2</Label>
            <Controller
              name="attempt2"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attempt2"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attempt2"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attempt2" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttempt2">
              Email Template ID: Attempt 2
            </Label>
            <Controller
              name="emailTemplateAttempt2"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttempt2"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttempt2"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttempt2",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="attempt3">Attempt 3</Label>
            <Controller
              name="attempt3"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attempt3"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attempt3"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attempt3" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttempt3">
              Email Template ID: Attempt 3
            </Label>
            <Controller
              name="emailTemplateAttempt3"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttempt3"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttempt3"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttempt3",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </PatientResponsibilityContent>

        <EDContent show={!!(orderType === orderTypeConstants.ED)}>
          <div className="field col-6">
            <Label htmlFor="attemptED1">Attempt 1</Label>
            <Controller
              name="attemptED1"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attemptED1"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attemptED1"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attemptED1" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttemptED1">
              Email Template ID: Attempt 1
            </Label>
            <Controller
              name="emailTemplateAttemptED1"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttemptED1"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttemptED1"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttemptED1",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="attemptED2">Attempt 2</Label>
            <Controller
              name="attemptED2"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attemptED2"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attemptED2"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attemptED2" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttemptED2">
              Email Template ID: Attempt 2
            </Label>
            <Controller
              name="emailTemplateAttemptED2"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttemptED2"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttemptED2"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttemptED2",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>

          <div className="field col-6">
            <Label htmlFor="attemptED3">Attempt 3</Label>
            <Controller
              name="attemptED3"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attemptED3"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attemptED3"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attemptED3" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttemptED3">
              Email Template ID: Attempt 3
            </Label>
            <Controller
              name="emailTemplateAttemptED3"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttemptED3"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttemptED3"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttemptED3",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </EDContent>

        <BundleContent show={!!(orderType === orderTypeConstants.BUNDLED)}>
          <div className="field col-6">
            <Label htmlFor="attemptBundle1">Attempt 1</Label>
            <Controller
              name="attemptBundle1"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attemptBundle1"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attemptBundle1"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attemptBundle1" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttemptBundle1">
              Email Template ID: Attempt 1
            </Label>
            <Controller
              name="emailTemplateAttemptBundle1"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttemptBundle1"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttemptBundle1"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttemptBundle1",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="attemptBundle2">Attempt 2</Label>
            <Controller
              name="attemptBundle2"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attemptBundle2"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attemptBundle2"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attemptBundle2" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttemptBundle2">
              Email Template ID: Attempt 2
            </Label>
            <Controller
              name="emailTemplateAttemptBundle2"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttemptBundle2"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttemptBundle2"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttemptBundle2",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="attemptBundle3">Attempt 3</Label>
            <Controller
              name="attemptBundle3"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attemptBundle3"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attemptBundle3"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attemptBundle3" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttemptBundle3">
              Email Template ID: Attempt 3
            </Label>
            <Controller
              name="emailTemplateAttemptBundle3"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttemptBundle3"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttemptBundle3"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttemptBundle3",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </BundleContent>

        <BariatricsContent
          show={!!(orderType === orderTypeConstants.BARIATRICS)}
        >
          <div className="field col-6">
            <Label htmlFor="attemptBariatrics1">Attempt 1</Label>
            <Controller
              name="attemptBariatrics1"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attemptBariatrics1"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attemptBariatrics1"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attemptBariatrics1" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttemptBariatrics1">
              Email Template ID: Attempt 1
            </Label>
            <Controller
              name="emailTemplateAttemptBariatrics1"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttemptBariatrics1"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttemptBariatrics1"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttemptBariatrics1",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="attemptBariatrics2">Attempt 2</Label>
            <Controller
              name="attemptBariatrics2"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attemptBariatrics2"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attemptBariatrics2"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attemptBariatrics2" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttemptBariatrics2">
              Email Template ID: Attempt 2
            </Label>
            <Controller
              name="emailTemplateAttemptBariatrics2"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttemptBariatrics2"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttemptBariatrics2"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttemptBariatrics2",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="attemptBariatrics3">Attempt 3</Label>
            <Controller
              name="attemptBariatrics3"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attemptBariatrics3"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attemptBariatrics3"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attemptBariatrics3" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttemptBariatrics3">
              Email Template ID: Attempt 3
            </Label>
            <Controller
              name="emailTemplateAttemptBariatrics3"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttemptBariatrics3"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttemptBariatrics3"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttemptBariatrics3",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </BariatricsContent>
        <GFEContent show={!!(orderType === orderTypeConstants.GFE)}>
          <div className="field col-6">
            <Label htmlFor="attemptGFE1">Attempt 1</Label>
            <Controller
              name="attemptGFE1"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attemptGFE1"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attemptGFE1"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attemptGFE1" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttemptGFE1">
              Email Template ID: Attempt 1
            </Label>
            <Controller
              name="emailTemplateAttemptGFE1"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttemptGFE1"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttemptGFE1"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttemptGFE1",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="attemptGFE2">Attempt 2</Label>
            <Controller
              name="attemptGFE2"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attemptGFE2"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attemptGFE2"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attemptGFE2" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttemptGFE2">
              Email Template ID: Attempt 2
            </Label>
            <Controller
              name="emailTemplateAttemptGFE2"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttemptGFE2"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttemptGFE2"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttemptGFE2",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="attemptGFE3">Attempt 3</Label>
            <Controller
              name="attemptGFE3"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="attemptGFE3"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="attemptGFE3"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "attemptGFE3" });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="field col-6">
            <Label htmlFor="emailTemplateAttemptGFE3">
              Email Template ID: Attempt 3
            </Label>
            <Controller
              name="emailTemplateAttemptGFE3"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputText
                  className="w-full"
                  id="emailTemplateAttemptGFE3"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter your message here"
                  aria-labelledby="emailTemplateAttemptGFE3"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "emailTemplateAttemptGFE3",
                    });
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </GFEContent>

        <div className="field col-12">
          <Label htmlFor="emailToReceiveNotification">
            Emails to Receive Notifications
          </Label>
          <Controller
            name="emailToReceiveNotification"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Chips
                id="emailToReceiveNotification"
                name="emailToReceiveNotification"
                aria-labelledby="emailToReceiveNotification"
                value={value}
                onChange={onChange}
                className="w-full"
                placeholder="Enter email to receive reports here"
                onAdd={(event) => {
                  const isValid = isValidEmail(event.value);
                  return isValid;
                }}
                onBlur={() => {
                  handleOnBlurField({
                    onBlur,
                    field: "emailToReceiveNotification",
                  });
                }}
              />
            )}
          />
        </div>
        <div className="field col-12">
          <Label htmlFor="frontDeskEmployeesToReceiveDailyEmail">
            Front desk employees to receive daily email
          </Label>
          <Controller
            name="frontDeskEmployeesToReceiveDailyEmail"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Chips
                id="frontDeskEmployeesToReceiveDailyEmail"
                name="frontDeskEmployeesToReceiveDailyEmail"
                aria-labelledby="frontDeskEmployeesToReceiveDailyEmail"
                value={value}
                onChange={onChange}
                className="w-full"
                placeholder="Enter front desk employees to receive daily email"
                onAdd={(event) => {
                  const isValid = isValidEmail(event.value);
                  return isValid;
                }}
                onBlur={() => {
                  handleOnBlurField({
                    onBlur,
                    field: "frontDeskEmployeesToReceiveDailyEmail",
                  });
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
