import { useUserWithAccount } from "context/UserAccountProvider";
import { Navigate } from "react-router-dom";

function withRoleProtection({
  component: Component,
  allowedRoles,
}: {
  component: any;
  allowedRoles: string[];
}) {
  const { userInfo } = useUserWithAccount();
  if (!userInfo) {
    return <Navigate to="/login" />;
  }
  if (!allowedRoles.includes(userInfo.scope)) {
    return <Navigate to="/" />;
  }
  return Component;
}

export default withRoleProtection;
