import { Label } from "layout/typography/Label";
import { Chips } from "primereact/chips";
import { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

const Title = styled.h3`
  margin-bottom: 16px;
`;

export function MedtelIds() {
  const { control, watch } = useFormContext();
  const [medtelIds, setMedtelIds] = useState<string[]>([]);
  const watchedMedtelIds = watch("medtelIds");

  useEffect(() => {
    if (watchedMedtelIds) {
      setMedtelIds(watchedMedtelIds);
    }
  }, [watchedMedtelIds]);

  return (
    <div className="block bg-white border-round p-4 mb-4">
      <Title>Medtel Id</Title>
      <Label htmlFor="cptCodes">Add Medtel Id</Label>
      <Controller
        name="medtelIds"
        control={control}
        render={({ field: { onChange } }) => (
          <Chips
            id="medtelIds"
            value={medtelIds}
            className="w-full mt-2"
            onChange={(e: any) => {
              setMedtelIds(e.value.map((item: any) => item.trim()));
              onChange(e.value.map((item: any) => item.trim()));
            }}
            separator=","
          />
        )}
      />
    </div>
  );
}
