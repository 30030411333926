import { useOrder } from "hooks/useOrder";
import { uniqBy } from "lodash";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { IOrder } from "types/Order/Order";
import { SpecialtyList } from "types/Provider/Provider";

const MarkAsCashContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IProviderDropdownSelect {
  value: string;
  label: string;
}

export function MarkAsPaidCashDialog({
  paymentType,
  markViaFinancingOrCash,
  order,
  showDialog,
  setShowDialog,
  setProviderToCharge,
  providerToCharge,
}: {
  paymentType: string;
  markViaFinancingOrCash: any;
  order: IOrder;
  showDialog: boolean;
  setShowDialog: any;
  setProviderToCharge: any;
  providerToCharge: any;
}) {
  const [convertToDropdownOptions, setConvertToDropdownOptions] = useState<
    IProviderDropdownSelect[]
  >([]);

  const { isPatientResponsibility } = useOrder(order);

  useEffect(() => {
    const mandatoryFacility = order.account.providers
      .filter((prov) => prov.specialty === SpecialtyList.FACILITY)
      .map((prov) => ({
        providerId: prov.id,
        providerName: prov.name,
        specialty: prov.specialty,
      }));

    const providers = order.serviceDetails.procedures.map((provider: any) => ({
      providerId: provider.providerId,
      providerName: provider.providerName,
      specialty: provider.specialty,
    }));

    const uniqueProviders = uniqBy(
      [...providers, ...mandatoryFacility],
      "providerId"
    );

    const convertToDropdownOptions = uniqueProviders.map((provider) => ({
      value: provider.providerId,
      label: provider.providerName,
    }));

    const facilityProvider = uniqueProviders.find(
      (unique) => unique.specialty === SpecialtyList.FACILITY
    );

    const defaultValue = convertToDropdownOptions.find(
      (option) => option.value === facilityProvider?.providerId
    );

    setConvertToDropdownOptions(convertToDropdownOptions);
    setProviderToCharge(
      defaultValue?.value || convertToDropdownOptions[0].value
    );
  }, []);

  const dropdownOnChange = (e: any) => {
    setProviderToCharge(e.value);
  };

  const footerComponent = () => {
    return (
      <div>
        <Button
          label="Cancel"
          onClick={() => setShowDialog(false)}
          className="mr-2"
        />
        <Button
          label="Mark as Paid"
          onClick={() => {
            markViaFinancingOrCash(paymentType);
            setShowDialog(false);
          }}
        />
      </div>
    );
  };

  return (
    <Dialog
      onHide={() => {}}
      visible={showDialog}
      header="Confirmation"
      footer={footerComponent}
    >
      <MarkAsCashContainer>
        <span className="mb-3">{`Are you sure you want to mark as paid via ${paymentType.toLowerCase()} ?`}</span>

        {!isPatientResponsibility && (
          <MarkAsCashContainer>
            <span className="mb-2">
              Select provider who has collected the money:
            </span>
            <Dropdown
              id="providers"
              name="providers"
              value={providerToCharge || ""}
              onChange={dropdownOnChange}
              data-testid="providers__Dropdown"
              options={convertToDropdownOptions}
              placeholder="Select"
              className="w-full"
            />
          </MarkAsCashContainer>
        )}
      </MarkAsCashContainer>
    </Dialog>
  );
}
