export function CallIcon({ ...rest }) {
  return (
    <svg
      {...rest}
      width="16"
      height="16"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.36683 10.4668L6.1335 11.7002C5.8735 11.9602 5.46016 11.9602 5.1935 11.7068C5.12016 11.6335 5.04683 11.5668 4.9735 11.4935C4.28683 10.8002 3.66683 10.0735 3.1135 9.3135C2.56683 8.5535 2.12683 7.7935 1.80683 7.04016C1.4935 6.28016 1.3335 5.5535 1.3335 4.86016C1.3335 4.40683 1.4135 3.9735 1.5735 3.5735C1.7335 3.16683 1.98683 2.7935 2.34016 2.46016C2.76683 2.04016 3.2335 1.8335 3.72683 1.8335C3.9135 1.8335 4.10016 1.8735 4.26683 1.9535C4.44016 2.0335 4.5935 2.1535 4.7135 2.32683L6.26016 4.50683C6.38016 4.6735 6.46683 4.82683 6.52683 4.9735C6.58683 5.1135 6.62016 5.2535 6.62016 5.38016C6.62016 5.54016 6.5735 5.70016 6.48016 5.8535C6.3935 6.00683 6.26683 6.16683 6.10683 6.32683L5.60016 6.8535C5.52683 6.92683 5.4935 7.0135 5.4935 7.12016C5.4935 7.1735 5.50016 7.22016 5.5135 7.2735C5.5335 7.32683 5.5535 7.36683 5.56683 7.40683C5.68683 7.62683 5.8935 7.9135 6.18683 8.26016C6.48683 8.60683 6.80683 8.96016 7.1535 9.3135C7.22016 9.38016 7.2935 9.44683 7.36016 9.5135C7.62683 9.7735 7.6335 10.2002 7.36683 10.4668Z"
        fill="#5B4DFD"
      />
      <path
        d="M14.6466 12.7199C14.6466 12.9065 14.6132 13.0999 14.5466 13.2865C14.5266 13.3399 14.5066 13.3932 14.4799 13.4465C14.3666 13.6865 14.2199 13.9132 14.0266 14.1265C13.6999 14.4865 13.3399 14.7465 12.9332 14.9132C12.9266 14.9132 12.9199 14.9199 12.9132 14.9199C12.5199 15.0799 12.0932 15.1665 11.6332 15.1665C10.9532 15.1665 10.2266 15.0065 9.4599 14.6799C8.69324 14.3532 7.92657 13.9132 7.16657 13.3599C6.90657 13.1665 6.64657 12.9732 6.3999 12.7665L8.5799 10.5865C8.76657 10.7265 8.93324 10.8332 9.07324 10.9065C9.10657 10.9199 9.14657 10.9399 9.19324 10.9599C9.24657 10.9799 9.2999 10.9865 9.3599 10.9865C9.47324 10.9865 9.5599 10.9465 9.63324 10.8732L10.1399 10.3732C10.3066 10.2065 10.4666 10.0799 10.6199 9.99986C10.7732 9.90653 10.9266 9.85986 11.0932 9.85986C11.2199 9.85986 11.3532 9.88653 11.4999 9.94653C11.6466 10.0065 11.7999 10.0932 11.9666 10.2065L14.1732 11.7732C14.3466 11.8932 14.4666 12.0332 14.5399 12.1999C14.6066 12.3665 14.6466 12.5332 14.6466 12.7199Z"
        fill="#5B4DFD"
      />
    </svg>
  );
}
