import { enabledChatForAccounts, UserRole } from "constants/userRole";
import {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "store/slices/auth";

import { useCurrentUser } from "../hooks/useCurrentUser";
import { useGetAllAccountsQuery } from "../store/queries/account";
import { onUserAccountLoad } from "../store/slices/user";
import { IAllAccountResponse } from "../types/Account/AccountResponse";
import { IUserResponse } from "../types/User";

interface IUserAccountType {
  userInfo: IUserResponse | undefined;
  accounts: IAllAccountResponse | undefined;
  loadingAccounts: boolean;
  accountRefetch: () => void;
}

const UserAccountContext = createContext<IUserAccountType | undefined>(
  undefined
);

export const useUserWithAccount = (): IUserAccountType => {
  const context = useContext(UserAccountContext);
  if (!context) {
    throw new Error("useAccount must be used within a AccountProvider");
  }
  return context;
};

export function UserAccountProvider({ children }: { children: ReactNode }) {
  const dispatch = useDispatch();
  const { token } = useSelector(authSelector);

  const { userInfo } = useCurrentUser({ skip: !token });

  const {
    data: accounts,
    isLoading: loadingAccounts,
    refetch,
  } = useGetAllAccountsQuery(null, { skip: !token });

  const loadAccountForUserRole = async () => {
    if (!accounts || !userInfo) return;

    const defaultAccount = userInfo.accountIds.length
      ? userInfo.accountIds[0]
      : accounts.data[0].id;

    // HOSPITAL STAFF WITH AN ENABLED ACCOUNT OR SUPER ADMIN
    const enabledChat =
      userInfo.scope === UserRole.HOSPITAL_STAFF
        ? userInfo.accountIds.some((id) => enabledChatForAccounts.includes(id))
        : userInfo.scope === UserRole.SUPER_ADMIN;

    try {
      dispatch(
        onUserAccountLoad({
          id: userInfo.id,
          name: userInfo.name,
          email: userInfo.email,
          scope: userInfo.scope,
          accountIds: userInfo.accountIds,
          currentAccount: defaultAccount,
          chatOnlineEnabled: enabledChat,
        })
      );
    } catch (err: any) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    loadAccountForUserRole();
  }, [userInfo, accounts]);

  const contextValue: IUserAccountType = useMemo(
    () => ({
      userInfo,
      accounts,
      loadingAccounts,
      accountRefetch: refetch,
    }),
    [userInfo, accounts]
  );

  return (
    <UserAccountContext.Provider value={contextValue}>
      {children}
    </UserAccountContext.Provider>
  );
}
