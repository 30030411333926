import { combineReducers } from "redux";

import { api, publicApi } from "./queries";
import authSlice from "./slices/auth";
import orderSlice from "./slices/order";
import serviceDetailsSlice from "./slices/serviceDetails";
import userSlice from "./slices/user";
import verificationSlice from "./slices/verification";
import websocketSlice from "./slices/websocket";

const rootReducer = combineReducers({
  authSlice,
  orderSlice,
  userSlice,
  verificationSlice,
  serviceDetailsSlice,
  websocketSlice,
  [api.reducerPath]: api.reducer,
  [publicApi.reducerPath]: publicApi.reducer,
});

export default rootReducer;
