import { configureStore } from "@reduxjs/toolkit";

import { api, publicApi } from "./queries";
import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat([api.middleware, publicApi.middleware]);
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
