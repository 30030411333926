import { parsePrice, centsToDollars } from "helpers/price";
import { sizer } from "layout/styles/styled/sizer";
import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import { useMemo } from "react";
import styled from "styled-components";
import { IProviderAmount } from "types/Payment";

type ProviderAmountRow = {
  providerName: string;
  cptCode: string;
  providerPriority: number;
  adjustedAllowablePrice: number;
  clearDiscountedPrice: number;
  expectedTransferAmount: number;
  clearFeeAmountInCents: number;
  patientPortion: number;
};

const StyledDataTable = styled(DataTable)`
  margin-top: ${sizer(6)};

  tr {
    background: var(--gray-50);
  }

  span.p-column-title {
    font-weight: var(--font-weight-semibold);
    font-size: var(--fontsize-contents);
    color: var(--color-black-4);
  }
  .p-rowgroup-header {
    display: none;
  }

  td {
    font-weight: var(--font-weight-medium);
    font-size: var(--fontsize-contents);
    color: var(--color-matte-black);
  }
`;

function ProviderFinalAmounts({
  providerAmounts,
  clearFee,
}: {
  providerAmounts?: IProviderAmount[];
  clearFee?: number | null;
}) {
  const firstColumnTemplate = (row: ProviderAmountRow) => {
    return <span className="font-bold">{row.providerName}</span>;
  };
  const columns = useMemo(() => {
    return [
      {
        field: "providerName",
        header: "Provider",
        body: (row: ProviderAmountRow) => firstColumnTemplate(row),
      },
      { field: "cptCode", header: "CPT Code" },
      { field: "providerPriority", header: "Payment Priority" },
      {
        field: "adjustedAllowablePrice",
        header: "Total Allowable",
        body: (row: ProviderAmountRow) => {
          return parsePrice(centsToDollars(row.adjustedAllowablePrice));
        },
      },
      {
        field: "patientPortion",
        header: "Patient Portion",
        body: (row: ProviderAmountRow) => {
          return parsePrice(centsToDollars(row.patientPortion));
        },
      },
      {
        field: "clearDiscountedPrice",
        header: "Discounted Price",
        body: (row: ProviderAmountRow) => {
          return parsePrice(centsToDollars(row.clearDiscountedPrice));
        },
      },
      {
        field: "expectedTransferAmount",
        header: "Actual Payment",
        body: (row: ProviderAmountRow) => {
          return parsePrice(centsToDollars(row.expectedTransferAmount));
        },
      },
    ];
  }, []);

  const providerTotals = useMemo(() => {
    const totals: { [key: string]: number } = {};
    providerAmounts?.forEach((amount) => {
      if (!totals[amount.providerId]) {
        totals[amount.providerId] = 0;
      }
      totals[amount.providerId] += amount.expectedTransferAmount;
    });
    return totals;
  }, [providerAmounts]);

  const footerTemplate = (data: any) => {
    return (
      <>
        <td colSpan={6} />
        <td>
          <span className="flex font-bold w-full">
            Total: {parsePrice(centsToDollars(providerTotals[data.providerId]))}
          </span>
        </td>
      </>
    );
  };

  const footerGroup = clearFee && (
    <ColumnGroup>
      <Row>
        <Column
          footer="Clear Fee"
          colSpan={6}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={parsePrice(centsToDollars(clearFee))} />
      </Row>
    </ColumnGroup>
  );

  return (
    <StyledDataTable
      rowGroupMode="subheader"
      style={{ marginLeft: "-1rem", marginRight: "-1rem" }}
      groupRowsBy="providerName"
      value={providerAmounts}
      emptyMessage="No Provider Final Amounts"
      rowGroupFooterTemplate={footerTemplate}
      footerColumnGroup={footerGroup}
    >
      {columns.map((col) => (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          body={col?.body}
        />
      ))}
    </StyledDataTable>
  );
}

export default ProviderFinalAmounts;
