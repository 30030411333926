import { validationConstants } from "constants/formValidation";
import { orderTypeConstants } from "constants/order";
import useFormValidation from "hooks/useFormValidation";
import { Label } from "layout/typography/Label";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { ToggleButton } from "primereact/togglebutton";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IOverviewPage } from "types/Customize";
import { OrderType } from "types/Order/OrderType";

import { disclaimerDefaultValues } from "../helper";
import { StyledCard } from "../styled";

interface ICheckoutFields {
  "PatientResponsibility-headlineCheckout": string;
  "Bundled-headlineCheckout": string;
  "Bariatrics-headlineCheckout": string;
  "ED-headlineCheckout": string;
  "GFE-headlineCheckout": string;
  "PatientResponsibility-physicianEstimate": boolean;
  "Bundled-physicianEstimate": boolean;
  "Bariatrics-physicianEstimate": boolean;
  "ED-physicianEstimate": boolean;
  "GFE-physicianEstimate": boolean;
  "PatientResponsibility-estimateDescription": string;
  "Bundled-estimateDescription": string;
  "Bariatrics-estimateDescription": string;
  "ED-estimateDescription": string;
  "GFE-estimateDescription": string;
  "PatientResponsibility-facilityFeeDescription": string;
  "Bundled-facilityFeeDescription": string;
  "Bariatrics-facilityFeeDescription": string;
  "ED-facilityFeeDescription": string;
  "GFE-facilityFeeDescription": string;
  "PatientResponsibility-financingButton": string;
  "Bundled-financingButton": string;
  "Bariatrics-financingButton": string;
  "ED-financingButton": string;
  "GFE-financingButton": string;
  "PatientResponsibility-purchaseButton": string;
  "Bundled-purchaseButton": string;
  "Bariatrics-purchaseButton": string;
  "ED-purchaseButton": string;
  "GFE-purchaseButton": string;
  "PatientResponsibility-paymentPlanButton": string;
  "Bundled-paymentPlanButton": string;
  "Bariatrics-paymentPlanButton": string;
  "ED-paymentPlanButton": string;
  "GFE-paymentPlanButton": string;
  "PatientResponsibility-discountExpires": boolean;
  "Bundled-discountExpires": boolean;
  "Bariatrics-discountExpires": boolean;
  "ED-discountExpires": boolean;
  "GFE-discountExpires": boolean;
  "PatientResponsibility-discountPercentTotal": boolean;
  "Bundled-discountPercentTotal": boolean;
  "Bariatrics-discountPercentTotal": boolean;
  "ED-discountPercentTotal": boolean;
  "GFE-discountPercentTotal": boolean;
  "PatientResponsibility-facilityEstimate": boolean;
  "Bundled-facilityEstimate": boolean;
  "Bariatrics-facilityEstimate": boolean;
  "ED-facilityEstimate": boolean;
  "GFE-facilityEstimate": boolean;
  "PatientResponsibility-clearPriceDescription": string;
  "Bundled-clearPriceDescription": string;
  "Bariatrics-clearPriceDescription": string;
  "ED-clearPriceDescription": string;
  "GFE-clearPriceDescription": string;
  "PatientResponsibility-clearPriceTitle": string;
  "Bundled-clearPriceTitle": string;
  "Bariatrics-clearPriceTitle": string;
  "ED-clearPriceTitle": string;
  "GFE-clearPriceTitle": string;
  "GFE-disclaimerTitleEnglish"?: string;
  "GFE-disclaimerDescriptionEnglish"?: string;
  "GFE-disclaimerTitleSpanish"?: string;
  "GFE-disclaimerDescriptionSpanish"?: string;
}

export function CheckoutForm({
  customFields,
  orderType,
}: {
  customFields: IOverviewPage;
  orderType: OrderType;
}) {
  const {
    control,
    trigger,
    formState: { errors },
    setValue,
  } = useFormContext<ICheckoutFields>();

  const { handleOnBlurField, getFormErrorMessage } = useFormValidation();

  const initializeFields = () => {
    setValue(`${orderType}-headlineCheckout`, customFields?.headline);
    setValue(`${orderType}-physicianEstimate`, customFields?.physicianEstimate);
    setValue(
      `${orderType}-estimateDescription`,
      customFields?.estimateDescription
    );
    setValue(
      `${orderType}-facilityFeeDescription`,
      customFields?.facilityFeeDescription
    );
    setValue(`${orderType}-facilityEstimate`, customFields?.facilityEstimate);
    setValue(`${orderType}-financingButton`, customFields?.financingButton);
    setValue(`${orderType}-purchaseButton`, customFields?.purchaseButton);
    setValue(`${orderType}-paymentPlanButton`, customFields?.paymentPlanButton);
    setValue(`${orderType}-discountExpires`, customFields?.discountExpires);
    setValue(
      `${orderType}-discountPercentTotal`,
      customFields.discountPercentTotal !== undefined
        ? customFields.discountPercentTotal
        : true
    );
    setValue(
      `${orderType}-clearPriceDescription`,
      customFields?.clearPriceDescription
    );
    setValue(
      `${orderType}-clearPriceTitle`,
      customFields?.clearPriceTitle || "Pay Now Price"
    );
    if (orderType === orderTypeConstants.GFE) {
      setValue(
        "GFE-disclaimerTitleEnglish",
        customFields.disclaimerTitleEnglish ||
          disclaimerDefaultValues.disclaimerTitleEnglish
      );
      setValue(
        "GFE-disclaimerDescriptionEnglish",
        customFields.disclaimerDescriptionEnglish ||
          disclaimerDefaultValues.disclaimerDescriptionEnglish
      );
      setValue(
        "GFE-disclaimerTitleSpanish",
        customFields.disclaimerTitleSpanish ||
          disclaimerDefaultValues.disclaimerTitleSpanish
      );
      setValue(
        "GFE-disclaimerDescriptionSpanish",
        customFields.disclaimerDescriptionSpanish ||
          disclaimerDefaultValues.disclaimerDescriptionSpanish
      );
    }
  };

  useEffect(() => {
    initializeFields();
  }, [customFields, orderType]);

  return (
    <StyledCard
      data-test-id="customize-checkout-page"
      title="Checkout Page Information"
    >
      <div className="flex flex-column gap-4 w-100">
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-headlineCheckout`}
              data-test-id={`${orderType}-headline-label-checkout`}
            >
              Headline
            </Label>

            <Controller
              name={`${orderType}-headlineCheckout`}
              control={control}
              rules={{
                required: validationConstants.CUSTOMIZE_FORM.HEADLINE,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id={`${orderType}-headlineCheckout`}
                    autoComplete="off"
                    value={value || ""}
                    aria-labelledby={`${orderType}-headlineCheckout`}
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field: `${orderType}-headlineCheckout`,
                        trigger,
                      });
                    }}
                    onChange={onChange}
                  />

                  {getFormErrorMessage(`${orderType}-headlineCheckout`, errors)}
                </>
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-physicianEstimate`}
              data-test-id={`${orderType}-physicianEstimate`}
            >
              Physician estimate
            </Label>

            <Controller
              data-test-id={`${orderType}-physicianEstimate`}
              name={`${orderType}-physicianEstimate`}
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <ToggleButton
                  id={`${orderType}-physicianEstimate`}
                  checked={value}
                  onChange={onChange}
                  className="w-8rem"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: `${orderType}-physicianEstimate`,
                      trigger,
                    });
                  }}
                />
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-estimateDescription`}
              data-test-id={`${orderType}-estimateDescription`}
            >
              Estimate description
            </Label>

            <Controller
              name={`${orderType}-estimateDescription`}
              control={control}
              rules={{
                required:
                  validationConstants.CUSTOMIZE_FORM.ESTIMATE_DESCRIPTION,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id={`${orderType}-estimateDescription`}
                    autoComplete="off"
                    value={value || ""}
                    aria-labelledby={`${orderType}-estimateDescription`}
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field: `${orderType}-estimateDescription`,
                        trigger,
                      });
                    }}
                    onChange={onChange}
                  />

                  {getFormErrorMessage(
                    `${orderType}-estimateDescription`,
                    errors
                  )}
                </>
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-facilityFeeDescription`}
              data-test-id={`${orderType}-facilityFeeDescription`}
            >
              Facility Description
            </Label>

            <Controller
              name={`${orderType}-facilityFeeDescription`}
              control={control}
              rules={{
                required:
                  validationConstants.CUSTOMIZE_FORM.FACILITY_DESCRIPTION,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id={`${orderType}-facilityFeeDescription`}
                    autoComplete="off"
                    value={value || ""}
                    aria-labelledby={`${orderType}-facilityFeeDescription`}
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field: `${orderType}-facilityFeeDescription`,
                        trigger,
                      });
                    }}
                    onChange={onChange}
                  />

                  {getFormErrorMessage(
                    `${orderType}-facilityFeeDescription`,
                    errors
                  )}
                </>
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-facilityEstimate`}
              data-test-id={`${orderType}-facilityEstimate-label`}
            >
              Facility estimate
            </Label>

            <Controller
              name={`${orderType}-facilityEstimate`}
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <ToggleButton
                  id={`${orderType}-facilityEstimate`}
                  data-test-id={`${orderType}-facilityEstimate`}
                  checked={value}
                  onChange={onChange}
                  className="w-8rem"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: `${orderType}-physicianEstimate`,
                      trigger,
                    });
                  }}
                />
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-financingButton`}
              data-test-id={`${orderType}-financingButton-label`}
            >
              Financing Button
            </Label>

            <Controller
              name={`${orderType}-financingButton`}
              control={control}
              rules={{
                required: validationConstants.CUSTOMIZE_FORM.FINANCING_BUTTON,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id={`${orderType}-financingButton`}
                    autoComplete="off"
                    value={value || ""}
                    aria-labelledby={`${orderType}-financingButton`}
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field: `${orderType}-financingButton`,
                        trigger,
                      });
                    }}
                    onChange={onChange}
                  />

                  {getFormErrorMessage(`${orderType}-financingButton`, errors)}
                </>
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-purchaseButton`}
              data-test-id={`${orderType}-purchaseButton-label`}
            >
              Purchase Button
            </Label>

            <Controller
              name={`${orderType}-purchaseButton`}
              control={control}
              rules={{
                required: validationConstants.CUSTOMIZE_FORM.PURCHASE_BUTTON,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id={`${orderType}-purchaseButton`}
                    autoComplete="off"
                    value={value || ""}
                    aria-labelledby={`${orderType}-purchaseButton`}
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field: `${orderType}-purchaseButton`,
                        trigger,
                      });
                    }}
                    onChange={onChange}
                  />

                  {getFormErrorMessage(`${orderType}-purchaseButton`, errors)}
                </>
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-paymentPlanButton`}
              data-test-id={`${orderType}-paymentPlanButton-label`}
            >
              Payment Plan Button
            </Label>

            <Controller
              name={`${orderType}-paymentPlanButton`}
              control={control}
              rules={{
                required:
                  validationConstants.CUSTOMIZE_FORM.PAYMENT_PLAN_BUTTON,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id={`${orderType}-paymentPlanButton`}
                    autoComplete="off"
                    value={value || ""}
                    aria-labelledby={`${orderType}-paymentPlanButton`}
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field: `${orderType}-paymentPlanButton`,
                        trigger,
                      });
                    }}
                    onChange={onChange}
                  />

                  {getFormErrorMessage(
                    `${orderType}-paymentPlanButton`,
                    errors
                  )}
                </>
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-clearPriceTitle`}
              data-test-id={`${orderType}-clearPriceTitle-label`}
            >
              Clear Price title
            </Label>

            <Controller
              name={`${orderType}-clearPriceTitle`}
              control={control}
              rules={{
                required: validationConstants.CUSTOMIZE_FORM.CLEAR_PRICE_TITLE,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id={`${orderType}-clearPriceTitle`}
                    autoComplete="off"
                    value={value || ""}
                    aria-labelledby={`${orderType}-clearPriceTitle`}
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field: `${orderType}-clearPriceTitle`,
                        trigger,
                      });
                    }}
                    onChange={onChange}
                  />

                  {getFormErrorMessage(`${orderType}-clearPriceTitle`, errors)}
                </>
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-clearPriceDescription`}
              data-test-id={`${orderType}-clearPriceDescription-label`}
            >
              Clear Price description
            </Label>

            <Controller
              name={`${orderType}-clearPriceDescription`}
              control={control}
              rules={{
                required:
                  validationConstants.CUSTOMIZE_FORM.CLEAR_PRICE_DESCRIPTION,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <InputText
                    id={`${orderType}-clearPriceDescription`}
                    autoComplete="off"
                    value={value || ""}
                    aria-labelledby={`${orderType}-clearPriceDescription`}
                    onBlur={() => {
                      handleOnBlurField({
                        onBlur,
                        field: `${orderType}-clearPriceDescription`,
                        trigger,
                      });
                    }}
                    onChange={onChange}
                  />

                  {getFormErrorMessage(
                    `${orderType}-clearPriceDescription`,
                    errors
                  )}
                </>
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-discountExpires`}
              data-test-id={`${orderType}-discountExpires-label`}
            >
              Discount Expires
            </Label>

            <Controller
              name={`${orderType}-discountExpires`}
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <ToggleButton
                  id={`${orderType}-discountExpires`}
                  data-test-id={`${orderType}-discountExpires`}
                  checked={value}
                  onChange={onChange}
                  className="w-8rem"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: `${orderType}-discountExpires`,
                      trigger,
                    });
                  }}
                />
              )}
            />
          </div>
          <div className="field flex flex-column col-12">
            <Label
              htmlFor={`${orderType}-discountPercentTotal`}
              data-test-id={`${orderType}-discountPercentTotal-label`}
            >
              Show Discount Percent Total
            </Label>

            <Controller
              name={`${orderType}-discountPercentTotal`}
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <ToggleButton
                  id={`${orderType}-discountPercentTotal`}
                  data-test-id={`${orderType}-discountPercentTotal`}
                  checked={value}
                  onChange={onChange}
                  className="w-8rem"
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: `${orderType}-discountPercentTotal`,
                      trigger,
                    });
                  }}
                />
              )}
            />
          </div>
          {orderType === orderTypeConstants.GFE && (
            <>
              <div className="field flex flex-column col-12">
                <Label
                  htmlFor="GFE-disclaimer"
                  data-test-id="GFE-disclaimerTitleEnglish"
                >
                  Disclaimer Title English
                </Label>

                <Controller
                  name="GFE-disclaimerTitleEnglish"
                  control={control}
                  rules={{
                    required:
                      validationConstants.CUSTOMIZE_FORM.GFE_DISCLAIMER_TITLE,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <InputText
                        id="GFE-disclaimerTitleEnglish"
                        autoComplete="off"
                        value={value || ""}
                        aria-labelledby="GFE-disclaimerTitleEnglish"
                        onBlur={() => {
                          handleOnBlurField({
                            onBlur,
                            field: `GFE-disclaimerTitleEnglish`,
                            trigger,
                          });
                        }}
                        onChange={onChange}
                      />

                      {getFormErrorMessage(
                        `GFE-disclaimerTitleEnglish`,
                        errors
                      )}
                    </>
                  )}
                />
              </div>
              <div className="field flex flex-column col-12">
                <Label
                  htmlFor="GFE-disclaimerDescriptionEnglish"
                  data-test-id="GFE-disclaimerDescriptionEnglish"
                >
                  Disclaimer Description English
                </Label>

                <Controller
                  name="GFE-disclaimerDescriptionEnglish"
                  control={control}
                  rules={{
                    required:
                      validationConstants.CUSTOMIZE_FORM
                        .GFE_DISCLAIMER_DESCRIPTION,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <InputTextarea
                        id="GFE-disclaimerDescriptionEnglish"
                        autoComplete="off"
                        value={value || ""}
                        rows={10}
                        aria-labelledby="GFE-disclaimerDescriptionEnglish"
                        onBlur={() => {
                          handleOnBlurField({
                            onBlur,
                            field: `GFE-disclaimerDescriptionEnglish`,
                            trigger,
                          });
                        }}
                        onChange={onChange}
                      />

                      {getFormErrorMessage(
                        `GFE-disclaimerDescriptionEnglish`,
                        errors
                      )}
                    </>
                  )}
                />
              </div>
              <div className="field flex flex-column col-12">
                <Label
                  htmlFor="GFE-disclaimer"
                  data-test-id="GFE-disclaimerTitleSpanish"
                >
                  Disclaimer Title Spanish
                </Label>

                <Controller
                  name="GFE-disclaimerTitleSpanish"
                  control={control}
                  rules={{
                    required:
                      validationConstants.CUSTOMIZE_FORM.GFE_DISCLAIMER_TITLE,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <InputText
                        id="GFE-disclaimerTitleSpanish"
                        autoComplete="off"
                        value={value || ""}
                        aria-labelledby="GFE-disclaimerTitleSpanish"
                        onBlur={() => {
                          handleOnBlurField({
                            onBlur,
                            field: `GFE-disclaimerTitleSpanish`,
                            trigger,
                          });
                        }}
                        onChange={onChange}
                      />

                      {getFormErrorMessage(
                        `GFE-disclaimerTitleSpanish`,
                        errors
                      )}
                    </>
                  )}
                />
              </div>
              <div className="field flex flex-column col-12">
                <Label
                  htmlFor="GFE-disclaimerDescriptionSpanish"
                  data-test-id="GFE-disclaimerDescriptionSpanish"
                >
                  Disclaimer Description Spanish
                </Label>

                <Controller
                  name="GFE-disclaimerDescriptionSpanish"
                  control={control}
                  rules={{
                    required:
                      validationConstants.CUSTOMIZE_FORM
                        .GFE_DISCLAIMER_DESCRIPTION,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <InputTextarea
                        id="GFE-disclaimerDescriptionSpanish"
                        autoComplete="off"
                        value={value || ""}
                        rows={10}
                        aria-labelledby="GFE-disclaimerDescriptionSpanish"
                        onBlur={() => {
                          handleOnBlurField({
                            onBlur,
                            field: `GFE-disclaimerDescriptionSpanish`,
                            trigger,
                          });
                        }}
                        onChange={onChange}
                      />

                      {getFormErrorMessage(
                        `GFE-disclaimerDescriptionSpanish`,
                        errors
                      )}
                    </>
                  )}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </StyledCard>
  );
}
