import { validationConstants } from "constants/formValidation";
import { centsToDollars } from "helpers/price";
import useFormValidation from "hooks/useFormValidation";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { getPaymentPlanEnabled } from "pages/CreateOrder/utils/helpers";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { ToggleButton } from "primereact/togglebutton";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { IAccountResponse } from "types/Account/AccountResponse";
import { IPaymentPlan } from "types/Payment";

const StyledCard = styled(Card)<{ paymentPlanIsEnabledForOrderType: boolean }>`
  margin-bottom: ${sizer(4)};
  display: ${({ paymentPlanIsEnabledForOrderType }) =>
    paymentPlanIsEnabledForOrderType ? "block" : "none"};
`;

export function PaymentPlan({
  accountData,
  paymentPlan,
}: {
  accountData: IAccountResponse;
  paymentPlan?: IPaymentPlan;
}) {
  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { handleOnBlurField, getFormErrorMessage } = useFormValidation();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const orderTypeField = watch("orderType");
  const minDownPaymentTypeField = watch("paymentPlanMinDownPaymentType");
  const minDownPaymentPercentField = watch("paymentPlanMinDownPaymentPercent");

  const paymentPlanIsEnabledForOrderType = useMemo(
    () =>
      getPaymentPlanEnabled(
        orderTypeField,
        accountData.data.paymentPlanTypeEnabled
      ),
    [orderTypeField, accountData.data.paymentPlanTypeEnabled]
  );

  useEffect(() => {
    if (!isFirstRender) {
      return;
    }
    const { paymentPlanDuration, paymentPlanMinDownPaymentPercent } =
      accountData.data;

    if (!paymentPlan) {
      setValue("paymentPlanDuration", paymentPlanDuration);
      setValue(
        "paymentPlanMinDownPaymentPercent",
        paymentPlanMinDownPaymentPercent
      );
      setValue("paymentPlanMinDownPaymentType", "Percentage");
      setValue("paymentPlanMinDownPaymentFlat", null);
      setIsFirstRender(false);
      return;
    }

    if (paymentPlan.paymentPlanDuration) {
      setValue("paymentPlanDuration", paymentPlan.paymentPlanDuration);
    }
    if (paymentPlan.paymentPlanMinDownPaymentPercent) {
      setValue(
        "paymentPlanMinDownPaymentPercent",
        paymentPlan.paymentPlanMinDownPaymentPercent
      );
    } else {
      setValue("paymentPlanMinDownPaymentPercent", null);
    }
    if (paymentPlan.paymentPlanMinDownPaymentFlat) {
      setValue(
        "paymentPlanMinDownPaymentFlat",
        centsToDollars(paymentPlan.paymentPlanMinDownPaymentFlat)
      );
    } else {
      setValue("paymentPlanMinDownPaymentFlat", null);
    }
    if (
      paymentPlan.paymentPlanMinDownPaymentFlat &&
      !paymentPlan.paymentPlanMinDownPaymentPercent
    ) {
      setValue("paymentPlanMinDownPaymentType", "Flat amount");
    } else {
      setValue("paymentPlanMinDownPaymentType", "Percentage");
    }

    setIsFirstRender(false);
  }, [accountData.data, paymentPlan, minDownPaymentPercentField]);

  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    const { paymentPlanMinDownPaymentPercent } = accountData.data;
    if (minDownPaymentTypeField === "Percentage") {
      setValue("paymentPlanMinDownPaymentFlat", null);
      if (!paymentPlan) {
        setValue(
          "paymentPlanMinDownPaymentPercent",
          paymentPlanMinDownPaymentPercent
        );
        return;
      }
      if (paymentPlan.paymentPlanMinDownPaymentPercent) {
        setValue(
          "paymentPlanMinDownPaymentPercent",
          paymentPlan.paymentPlanMinDownPaymentPercent
        );
      } else {
        setValue("paymentPlanMinDownPaymentPercent", null);
      }
    } else {
      setValue("paymentPlanMinDownPaymentPercent", null);
      if (!paymentPlan) {
        setValue("paymentPlanMinDownPaymentFlat", null);
        return;
      }
      if (paymentPlan.paymentPlanMinDownPaymentFlat) {
        setValue(
          "paymentPlanMinDownPaymentFlat",
          centsToDollars(paymentPlan.paymentPlanMinDownPaymentFlat)
        );
      } else {
        setValue("paymentPlanMinDownPaymentFlat", null);
      }
    }
  }, [minDownPaymentTypeField]);

  const showPercentageField = useMemo(() => {
    return minDownPaymentTypeField === "Percentage";
  }, [minDownPaymentTypeField]);

  return (
    <StyledCard
      data-testid="paymentPlan_Card"
      title="Payment Plan"
      paymentPlanIsEnabledForOrderType={paymentPlanIsEnabledForOrderType}
    >
      <div className="flex flex-column gap-4 w-100">
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12 md:col-3">
            <Label
              htmlFor="paymentPlanDuration"
              data-testid="paymentPlanMonthsLabel"
            >
              Number of months
            </Label>

            <Controller
              name="paymentPlanDuration"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  id="paymentPlanDuration"
                  data-testid="months_PaymentPlan"
                  value={value}
                  onChange={onChange}
                  options={[3, 6, 9, 12, 18, 24, 36]}
                  placeholder="Select number of months"
                  className="w-full"
                />
              )}
            />
          </div>
          <div className="field flex flex-column col-12 md:col-3">
            <Label
              htmlFor="paymentPlanMinDownPaymentType"
              data-testid="paymentPlanMinDownPaymentType"
            >
              Minimum down payment type
            </Label>
            <Controller
              name="paymentPlanMinDownPaymentType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  id="paymentPlanMinDownPaymentType"
                  data-testid="minDownPaymentType_PaymentPlan"
                  value={value}
                  onChange={onChange}
                  options={["Percentage", "Flat amount"]}
                  placeholder="Select minimum down payment type"
                  className="w-full"
                />
              )}
            />
          </div>
          {showPercentageField ? (
            <div className="field flex flex-column col-12 md:col-3">
              <Label
                htmlFor="paymentPlanMinDownPaymentPercent"
                data-testid="paymentPlanMinDownPaymentPercent"
              >
                Minimum first payment percent
              </Label>

              <Controller
                name="paymentPlanMinDownPaymentPercent"
                control={control}
                rules={{
                  required:
                    validationConstants.PAYMENT_PLAN.MIN_DOWN_PAYMENT_PERCENT,
                }}
                render={({ field: { onChange, value, onBlur } }) => (
                  <>
                    <InputNumber
                      value={value}
                      suffix="%"
                      onBlur={() =>
                        handleOnBlurField({
                          onBlur,
                          field: "paymentPlanMinDownPaymentPercent",
                          trigger,
                        })
                      }
                      inputId="paymentPlanMinDownPaymentPercent"
                      data-testid="minDownPaymentPercent_PaymentPlan"
                      onValueChange={onChange}
                      min={5}
                      max={100}
                    />
                    {getFormErrorMessage(
                      "paymentPlanMinDownPaymentPercent",
                      errors
                    )}
                  </>
                )}
              />
            </div>
          ) : (
            <div className="field flex flex-column col-12 md:col-3">
              <Label
                htmlFor="paymentPlanMinDownPaymentFlat"
                data-testid="paymentPlanMinDownPaymentFlat"
              >
                Minimum first payment flat
              </Label>

              <Controller
                name="paymentPlanMinDownPaymentFlat"
                control={control}
                rules={{
                  required:
                    validationConstants.PAYMENT_PLAN.MIN_DOWN_PAYMENT_FLAT,
                }}
                render={({ field: { onChange, value, onBlur } }) => (
                  <>
                    <InputNumber
                      value={value}
                      currency="USD"
                      locale="en-US"
                      mode="currency"
                      onBlur={() =>
                        handleOnBlurField({
                          onBlur,
                          field: "paymentPlanMinDownPaymentFlat",
                          trigger,
                        })
                      }
                      inputId="paymentPlanMinDownPaymentFlat"
                      data-testid="minDownPaymentFlat_PaymentPlan"
                      onValueChange={onChange}
                      min={10}
                      aria-autocomplete="none"
                    />
                    {getFormErrorMessage(
                      "paymentPlanMinDownPaymentFlat",
                      errors
                    )}
                  </>
                )}
              />
            </div>
          )}
          <div className="field flex flex-column col-12 md:col-3">
            <Label
              htmlFor="paymentPlanEnabled"
              data-testid="paymentPlanEnabled"
            >
              Payment Plan Enabled
            </Label>
            <Controller
              name="paymentPlanEnabled"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ToggleButton
                  checked={value}
                  onChange={onChange}
                  className="w-8rem"
                />
              )}
            />
          </div>
        </div>
      </div>
    </StyledCard>
  );
}
