import { LoadingSpinner } from "components/LoadingSpinner";
import { UploadFile } from "components/UploadFile";
import { ToastContext } from "context/ToastContext";
import { parseDate } from "helpers/date";
import { centsToDollars, parsePrice } from "helpers/price";
import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useContext, useMemo, useState } from "react";
import {
  useGenerateNewReportMutation,
  useGetReportsQuery,
} from "store/queries/report";
import styled from "styled-components";

import { MonthlyReportModal } from "../MonthlyReportModal";

const StyledDialog = styled(Dialog)`
  width: 50vw;

  @media (max-width: 720px) {
    width: 90vw;
  }
`;

const DialogContainer = styled.div`
  gap: ${sizer(4)};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export function ReportsTable() {
  const {
    data,
    isFetching,
    isError,
    refetch: refetchTable,
  } = useGetReportsQuery();

  const { current: toastElement } = useContext(ToastContext);

  const [selectedFile, setSelectedFile] = useState({} as File);
  const [selectedReportId, setSelectedReportId] = useState(null);

  const [selectAccountDialog, setSelectAccountDialog] = useState(false);

  const [mutation, { isLoading: isGeneratingNewReport }] =
    useGenerateNewReportMutation();

  const reportTypes = {
    STANDARD: "STANDARD",
    PLINE: "PLINE",
  };

  const handleOnChangeAccountDialogVisibility = () => {
    setSelectAccountDialog((prevState) => !prevState);
  };

  const reportInfo = useMemo(() => {
    if (isError || !data) return [];

    return data?.data.map((currentReport) => ({
      id: currentReport.id,
      accountName: currentReport?.name || currentReport?.provider?.name,
      reportDate: parseDate(currentReport?.reportDate),
      reportAmount: currentReport?.reportAmountInCents
        ? centsToDollars(currentReport?.reportAmountInCents)
        : "-",
      reportType: currentReport.reportType,
      stripePayoutAmount: currentReport?.stripePayout?.amountInCents
        ? centsToDollars(currentReport?.stripePayout?.amountInCents)
        : "",
      stripePayoutId: currentReport?.stripePayout?.id,
      stripeAccountNumber: currentReport?.provider?.stripeAccountNumber,
      signedUrl: currentReport?.signedUrl,
    }));
  }, [data]);

  const statusRowFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        placeholder="Select a Status"
        className="p-column-filter"
        options={Object.values(reportTypes)}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };

  const priceFilterTemplate = (options: any) => {
    return (
      <InputNumber
        locale="en-US"
        currency="USD"
        mode="currency"
        value={options.value}
        placeholder="$1234,00"
        onChange={(e) => options.filterApplyCallback(e.value, options.index)}
      />
    );
  };

  const stripePayoutTemplate = (
    stripePayoutId: string | null,
    stripePayoutAmountInCents: number | null,
    stripeAccountNumber: string | null
  ) => {
    if (!stripePayoutId || !stripePayoutAmountInCents) return null;

    return (
      <a
        href={`https://dashboard.stripe.com/${stripeAccountNumber}/payouts/${stripePayoutId}`}
        target="_blank"
        rel="noreferrer"
      >
        {parsePrice(stripePayoutAmountInCents)}
      </a>
    );
  };

  const viewReport = (url: string) => {
    return (
      <Button onClick={() => window.open(url)} className="p-button-link">
        <p className="small-text">Download</p>
      </Button>
    );
  };

  const uploadReport = (row: any) => {
    const { reportType } = row;

    if (reportType === reportTypes.STANDARD) return null;

    return (
      <Button
        className="p-button-link"
        onClick={() => setSelectedReportId(row.id)}
      >
        <p className="small-text">Upload</p>
      </Button>
    );
  };

  const handleOnClickToGenerateReport = () => {
    mutation({ reportId: selectedReportId, file: selectedFile })
      .unwrap()
      .then(() => {
        refetchTable();

        toastElement?.show({
          severity: "success",
          summary: "Success!",
          detail: `Your report has been updated successfully.`,
        });

        setSelectedFile({} as File);
        setSelectedReportId(null);
      })
      .catch(() => {
        toastElement?.show({
          severity: "error",
          summary: "Something went wrong.",
          detail: `Try again later.`,
        });
      });
  };

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <StyledDialog
        draggable={false}
        header="Upload a new report"
        visible={!!selectedReportId}
        closable={!isGeneratingNewReport}
        onHide={() => {
          setSelectedReportId(null);
          setSelectedFile({} as File);
        }}
      >
        <DialogContainer>
          <UploadFile
            name="plineReport"
            disabled={isGeneratingNewReport}
            onSelectFile={setSelectedFile}
          />

          <Button
            loading={isGeneratingNewReport}
            onClick={handleOnClickToGenerateReport}
          >
            Upload and Generate New Reports
          </Button>
        </DialogContainer>
      </StyledDialog>
      <MonthlyReportModal
        visible={selectAccountDialog}
        onClose={handleOnChangeAccountDialogVisibility}
      />
      <div className="flex justify-content-end mb-3">
        <Button
          type="button"
          icon="pi pi-book"
          label="Monthly Sales Reports"
          onClick={handleOnChangeAccountDialogVisibility}
        />
      </div>
      <DataTable
        rows={10}
        paginator
        stripedRows
        value={reportInfo}
        className="p-fluid"
        filterDisplay="row"
        responsiveLayout="scroll"
        emptyMessage="No reports to show."
      >
        <Column
          filter
          field="accountName"
          style={{ minWidth: "320px" }}
          header="Account/Provider Name"
          filterPlaceholder="John Smith"
        />
        <Column
          filter
          field="reportDate"
          header="Report Date"
          style={{ minWidth: "280px" }}
          filterPlaceholder="mm/dd/yyyy"
        />
        <Column
          filter
          field="stripePayoutAmount"
          style={{ minWidth: "280px" }}
          header="Stripe Payout Amount"
          body={(row) =>
            stripePayoutTemplate(
              row.stripePayoutId,
              row.stripePayoutAmount,
              row.stripeAccountNumber
            )
          }
        />
        <Column
          filter
          dataType="numeric"
          field="reportAmount"
          header="Report Amount"
          style={{ minWidth: "280px" }}
          filterElement={priceFilterTemplate}
          body={(row) => parsePrice(row.reportAmount)}
        />
        <Column
          filter
          field="reportType"
          header="Report Type"
          showFilterMenu={false}
          style={{ minWidth: "280px" }}
          filterElement={statusRowFilterTemplate}
        />
        <Column
          field="uploadReport"
          style={{ maxWidth: "120px" }}
          body={(row) => uploadReport(row)}
        />
        <Column
          field="viewReport"
          style={{ maxWidth: "130px" }}
          body={(row) => viewReport(row.signedUrl)}
        />
      </DataTable>
    </>
  );
}
