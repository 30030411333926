import { ItemBar } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { useUserWithAccount } from "context/UserAccountProvider";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCreateAccountMutation } from "store/queries/account";

import { mapFormToRequestBody } from "../helpers/mapFormToRequestBody";

export function Submit() {
  const [useCreateAccount] = useCreateAccountMutation();
  const { accountRefetch } = useUserWithAccount();

  const { current: toastElement } = useContext(ToastContext);

  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const navigate = useNavigate();

  const createHospital = async () => {
    const requestBody = await mapFormToRequestBody(getValues());
    try {
      useCreateAccount(requestBody)
        .unwrap()
        .then(() => {
          toastElement?.show({
            severity: "success",
            summary: "Account created",
            detail: "Account created successfully",
          });

          navigate("/hospitals");
          accountRefetch();
        })
        .catch(() =>
          toastElement?.show({
            severity: "error",
            summary: "Error",
            detail: "An error has occurred attempting to create the account",
          })
        );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ItemBar>
      <div
        data-testid="submit__Hospital"
        className="flex flex-1 justify-content-end"
      >
        <Button
          label="Create Hospital"
          onClick={createHospital}
          disabled={!isValid}
        />
      </div>
    </ItemBar>
  );
}
