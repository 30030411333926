import { ToastContext } from "context/ToastContext";
import { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useLoginWithOrderIdAndDOBMutation } from "store/queries/auth";
import { onPublicTokenChange } from "store/slices/auth";
import { orderSelector, onOrderOverviewStepChange } from "store/slices/order";

import { DOBAuth } from "./components/DOBAuth";
import { Overview } from "./components/Overview";
import { Steps } from "./utils/constants";

type OrderOverviewProps = {
  variant: Steps;
};

export function OrderOverview({ variant }: OrderOverviewProps) {
  const { overviewStep } = useSelector(orderSelector);
  const [login] = useLoginWithOrderIdAndDOBMutation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { current: toastElement } = useContext(ToastContext);
  const [isProcessing, setIsProcessing] = useState(true);
  useEffect(() => {
    const userType = localStorage.getItem("clear_userType");
    const dob = localStorage.getItem("clear_dob");
    if (userType && dob && id) {
      login({
        orderId: id || "",
        dateOfBirth: new Date(dob).toISOString(),
      })
        .unwrap()
        .then(({ token }: { token: string }) => {
          dispatch(onPublicTokenChange({ token }));
          dispatch(
            onOrderOverviewStepChange({
              step: Steps.ORDER_OVERVIEW,
            })
          );
          setIsProcessing(false);
          localStorage.removeItem("clear_userType");
          localStorage.removeItem("clear_dob");
        })
        .catch(() => {
          const detail =
            "Authentication failed. Please check your date of birth.";
          toastElement?.show({
            detail,
            summary: "Error",
            severity: "error",
          });
          setIsProcessing(false);
          localStorage.removeItem("clear_userType");
          localStorage.removeItem("clear_dob");
        });
    } else {
      setIsProcessing(false);
    }
  }, [id, login, dispatch, toastElement]);

  if (isProcessing) {
    return null;
  }

  switch (overviewStep) {
    case Steps.ORDER_OVERVIEW:
      return <Overview />;

    default:
      return <DOBAuth {...{ variant }} />;
  }
}
