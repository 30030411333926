import useFormValidation from "hooks/useFormValidation";
import { Label } from "layout/typography/Label";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { specialtyDropdown } from "../../../helpers/providerConstants";
import { formValidationConstants } from "../helpers/formValidations";

const Title = styled.h3`
  margin-bottom: 16px;
`;

export function ClearFee() {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext<any>();

  const { getFormErrorMessage, handleOnBlurField } = useFormValidation();

  return (
    <div className="block bg-white border-round p-4">
      <Title>Clear Fee Section</Title>
      <div className="formgrid grid">
        <div className="field col-6">
          <Label htmlFor="clearFeeForClearPackage">Fee for Clear package</Label>
          <Controller
            name="clearFeeForClearPackage"
            control={control}
            rules={{
              required:
                formValidationConstants.CLEAR_FEE.CLEAR_FEE_FOR_CLEAR_PACKAGE,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  className="w-full"
                  id="clearFeeForClearPackage"
                  autoComplete="off"
                  type="number"
                  value={value || ""}
                  placeholder="Enter amount here"
                  aria-labelledby="clearFeeForClearPackage"
                  min={0}
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "clearFeeForClearPackage",
                      trigger,
                    });
                  }}
                  onChange={onChange}
                />
                {getFormErrorMessage("clearFeeForClearPackage", errors)}
              </>
            )}
          />
        </div>
        <div className="field col-6">
          <Label htmlFor="prFeeMethod">Patient Responsibility Fee method</Label>
          <Controller
            data-testid="prFeeMethod"
            name="prFeeMethod"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                id="prFeeMethod"
                data-testid="prFeeMethod__Dropdown"
                value={value}
                onChange={onChange}
                options={Object.values(specialtyDropdown)}
                optionLabel="name"
                placeholder="Select"
                className="w-full"
                disabled
              />
            )}
          />
        </div>
        <div className="field col-6">
          <Label htmlFor="clearTransactionFeeForPR">
            Fee for Patient Responsibility
          </Label>
          <Controller
            name="clearTransactionFeeForPR"
            control={control}
            rules={{
              required:
                formValidationConstants.CLEAR_FEE.CLEAR_TRANSACTION_FEE_FOR_PR,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  className="w-full"
                  id="clearTransactionFeeForPR"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter amount here"
                  aria-labelledby="clearTransactionFeeForPR"
                  type="number"
                  min={0}
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "clearTransactionFeeForPR",
                      trigger,
                    });
                  }}
                  onChange={onChange}
                />
                {getFormErrorMessage("clearTransactionFeeForPR", errors)}
              </>
            )}
          />
        </div>
        <div className="field col-6">
          <Label htmlFor="patientResponsibilityDiscount">
            Patient Responsibility discount
          </Label>
          <Controller
            name="patientResponsibilityDiscount"
            control={control}
            rules={{
              required:
                formValidationConstants.CLEAR_FEE
                  .PATIENT_RESPONSIBILITY_DISCOUNT,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  className="w-full"
                  id="patientResponsibilityDiscount"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter amount here"
                  aria-labelledby="patientResponsibilityDiscount"
                  type="number"
                  min={0}
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "patientResponsibilityDiscount",
                      trigger,
                    });
                  }}
                  onChange={onChange}
                />
                {getFormErrorMessage("patientResponsibilityDiscount", errors)}
              </>
            )}
          />
        </div>
        <div className="field col-6">
          <Label htmlFor="clearFinancingFee">Financing Fee</Label>
          <Controller
            name="clearFinancingFee"
            control={control}
            rules={{
              required: formValidationConstants.CLEAR_FEE.CLEAR_FINANCING_FEE,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  className="w-full"
                  id="clearFinancingFee"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter amount here"
                  aria-labelledby="clearFinancingFee"
                  type="number"
                  min={0}
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "clearFinancingFee",
                      trigger,
                    });
                  }}
                  onChange={onChange}
                />
                {getFormErrorMessage("clearFinancingFee", errors)}
              </>
            )}
          />
        </div>

        <div className="field col-6">
          <Label htmlFor="clearHealthFeeForGFE">Fee for GFE</Label>
          <Controller
            name="clearHealthFeeForGFE"
            control={control}
            rules={{
              required:
                formValidationConstants.CLEAR_FEE.CLEAR_HEALTH_FEE_FOR_GFE,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  className="w-full"
                  id="clearHealthFeeForGFE"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter amount here"
                  aria-labelledby="clearHealthFeeForGFE"
                  type="number"
                  min={0}
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "clearHealthFeeForGFE",
                      trigger,
                    });
                  }}
                  onChange={onChange}
                />
                {getFormErrorMessage("clearHealthFeeForGFE", errors)}
              </>
            )}
          />
        </div>
        <div className="field col-6">
          <Label htmlFor="clearHealthDiscountForGFE">GFE Discount</Label>
          <Controller
            name="clearHealthDiscountForGFE"
            control={control}
            rules={{
              required:
                formValidationConstants.CLEAR_FEE.CLEAR_HEALTH_DISCOUNT_FOR_GFE,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  className="w-full"
                  id="clearHealthDiscountForGFE"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter amount here"
                  aria-labelledby="clearHealthDiscountForGFE"
                  type="number"
                  min={0}
                  onBlur={() => {
                    handleOnBlurField({
                      onBlur,
                      field: "clearHealthDiscountForGFE",
                      trigger,
                    });
                  }}
                  onChange={onChange}
                />
                {getFormErrorMessage("clearHealthDiscountForGFE", errors)}
              </>
            )}
          />
        </div>
        <div className="field col-6">
          <Label htmlFor="expirationDays">Expiration Number of Days</Label>
          <Controller
            name="expirationDays"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <InputText
                className="w-full"
                id="expirationDays"
                autoComplete="off"
                value={value}
                placeholder="Enter expiration number of days"
                aria-labelledby="expirationDays"
                type="number"
                min={0}
                onBlur={() => {
                  handleOnBlurField({
                    onBlur,
                    field: "expirationDays",
                    trigger,
                  });
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
