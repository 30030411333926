import useDocumentTitle from "hooks/useDocumentTitle";
import { TabPanel, TabView } from "primereact/tabview";

import { UsersTable } from "./components/UsersTable";

export function UsersPage() {
  useDocumentTitle("List Users");

  return (
    <TabView className="w-full">
      <TabPanel header="Users">
        <UsersTable />
      </TabPanel>
    </TabView>
  );
}
