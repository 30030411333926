import { userRoleMapping } from "constants/userRole";
import { Label } from "layout/typography/Label";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Controller, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import { UserDetailsFields } from "../CreateEditUser";
import { formValidationConstants } from "../helpers/formValidations";

export function UserInfo() {
  const { id } = useParams();

  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  function getFormErrorMessage(name: string) {
    return (
      errors[name] && (
        <small className="p-error">{errors[name]?.message as string}</small>
      )
    );
  }

  async function handleOnBlurField({
    onBlur,
    field,
  }: {
    onBlur: () => void;
    field: UserDetailsFields;
  }) {
    trigger(field);
    onBlur();
  }

  return (
    <div className="formgrid grid">
      <div className="field col-4">
        <Label htmlFor="name">Name</Label>
        <Controller
          name="name"
          control={control}
          rules={{
            required: formValidationConstants.USER.NAME,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <InputText
                id="name"
                className="w-full"
                autoComplete="off"
                value={value || ""}
                placeholder="Enter user name here"
                aria-labelledby="name"
                onBlur={() => {
                  handleOnBlurField({ onBlur, field: "name" });
                }}
                onChange={onChange}
              />

              {getFormErrorMessage("name")}
            </>
          )}
        />
      </div>

      {!id && (
        <div className="field col-4">
          <Label htmlFor="email">Email</Label>
          <Controller
            name="email"
            control={control}
            rules={{
              required: formValidationConstants.USER.EMAIL,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <InputText
                  id="email"
                  className="w-full"
                  autoComplete="off"
                  value={value || ""}
                  placeholder="Enter user email here"
                  aria-labelledby="email"
                  onBlur={() => {
                    handleOnBlurField({ onBlur, field: "email" });
                  }}
                  onChange={onChange}
                />

                {getFormErrorMessage("email")}
              </>
            )}
          />
        </div>
      )}

      <div className="field col-4">
        <Label htmlFor="scope">Scope</Label>
        <Controller
          name="scope"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Dropdown
              id="scope"
              name="scope"
              value={value}
              className="w-full"
              onChange={onChange}
              placeholder="Select"
              options={Object.values(userRoleMapping)}
            />
          )}
        />
      </div>
    </div>
  );
}
