import { Dialog } from "primereact/dialog";
import styled from "styled-components";

const StyledDialog = styled(Dialog)`
  width: 40vw;

  @media (max-width: 720px) {
    width: 90vw;
  }
`;

const Title = styled.h3`
  margin-bottom: 16px;
`;

function DisclaimerDialog({
  visible,
  onClose,
  englishTitle,
  englishDescription,
  spanishTitle,
  spanishDescription,
}: {
  visible: boolean;
  onClose: () => void;
  englishTitle: string;
  englishDescription: string;
  spanishTitle: string;
  spanishDescription: string;
}) {
  return (
    <StyledDialog header="Disclaimer" visible={visible} onHide={onClose}>
      <Title>{englishTitle}</Title>
      <p className="text-xs">{englishDescription}</p>
      <Title className="mt-4">{spanishTitle}</Title>
      <p className="text-xs">{spanishDescription}</p>
    </StyledDialog>
  );
}

export default DisclaimerDialog;
