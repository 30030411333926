import { AccountFeeType } from "constants/fee";
import { validationConstants } from "constants/formValidation";
import { calculateTax, centsToDollars, parsePrice } from "helpers/price";
import useFormValidation from "hooks/useFormValidation";
import { useGetAccountDiscountAndFee } from "hooks/useGetAccountDiscountAndFee";
import {
  useProviderProcedure,
  CLEAR_HEALTH_PAYER_NAME,
} from "hooks/useProviderProcedure";
import { sizer } from "layout/styles/styled/sizer";
import { StyledCard } from "pages/CreateOrder/CreateOrder.styled";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { IServiceDetailsState } from "store/slices/serviceDetails";
import { SpecialtyList, SpecialtyListDict } from "types/Provider/Provider";

import { ProcedureRow } from "../../../../../../types/CreateOrder";
import {
  buildClearPackageTotalAmount,
  buildFirstColumnWithQuantityTemplate,
} from "../../builders";
import { FieldsContainer } from "../../ServiceDetails";
import { AutoCompleteCPT } from "../Common/AutoCompleteCPT";
import { ProviderDropdown } from "../Common/ProviderDropdown";
import { RemoveItem } from "../Common/RemoveItem";

export function DefaultServiceDetails() {
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { handleOnBlurField, getFormErrorMessage } = useFormValidation();
  const dispatch = useDispatch();

  const accountFee = useGetAccountDiscountAndFee(
    AccountFeeType.ClearTransactionFee
  );

  const { procedures, providerProcedures, search } = useProviderProcedure({
    payerNameField: CLEAR_HEALTH_PAYER_NAME,
  });

  const {
    rows,
    autoCompleteValue,
    dataTableRows,
    selectedProcedures,
  }: IServiceDetailsState = useSelector(
    (state: RootState) => state.serviceDetailsSlice
  );

  useEffect(() => {
    setValue("amount", centsToDollars(buildClearPackageTotalAmount(rows)));

    setValue(
      "totalAmount",
      centsToDollars(
        calculateTax(buildClearPackageTotalAmount(rows), accountFee)
      )
    );

    setValue(
      "serviceDetails",
      rows.map((row: ProcedureRow) => ({
        code: row.cptCode,
        specialty: row.specialty,
        amountInCents: row.amount,
        procedureId: row.procedureId,
        cptDescription: row.description,
        providerId: row.selectedProvider.id,
        providerName: row.selectedProvider.name,
        providerProcedureId: row.providerProcedureId,
      }))
    );
  }, [rows, accountFee]);

  const providerDropdownTemplate = (row: ProcedureRow) => {
    return <ProviderDropdown row={row} />;
  };

  const actionTemplate = (row: ProcedureRow) => {
    return <RemoveItem row={row} autoCompleteValue={autoCompleteValue} />;
  };

  return (
    <StyledCard
      title="Service"
      data-testid="serviceDetails_DefaultServiceDetails"
    >
      <div className="formgrid grid w-100">
        <div className="field p-fluid col-12">
          <AutoCompleteCPT
            search={search}
            autoCompleteValue={autoCompleteValue}
            procedures={procedures}
            providerProcedures={providerProcedures}
          />
        </div>
      </div>

      <DataTable
        stripedRows
        value={dataTableRows}
        groupRowsBy="cptCode"
        rowGroupMode="rowspan"
        responsiveLayout="scroll"
        emptyMessage="No CPT codes selected."
        style={{
          marginLeft: sizer(-4),
          marginRight: sizer(-4),
        }}
      >
        <Column
          field="cptCode"
          header="CPT Code/Name"
          body={(row) =>
            buildFirstColumnWithQuantityTemplate({
              row,
              rows,
              autoCompleteValue,
              dispatch,
              selectedProcedures,
              procedures,
              providerProcedures,
            })
          }
          style={{
            minWidth: "100px",
            maxWidth: "150px",
            paddingLeft: sizer(6),
          }}
        />

        <Column
          field="amount"
          header="Amount"
          body={(row) => parsePrice(centsToDollars(row.amount))}
        />
        <Column
          field="specialty"
          header="Specialty"
          body={(row) => SpecialtyListDict[row.specialty as SpecialtyList]}
        />
        <Column
          field="provider"
          header="Provider"
          body={(row) => providerDropdownTemplate(row)}
        />
        <Column
          style={{ maxWidth: "80px" }}
          body={(row) => actionTemplate(row)}
        />
      </DataTable>
      <FieldsContainer>
        <div className="flex">
          <label
            htmlFor="estimatedVisitCostForPatient"
            className="mr-2 flex align-items-center"
          >
            Facility Patient Estimate
          </label>

          <Controller
            control={control}
            name="estimatedVisitCostForPatient"
            rules={{
              required: validationConstants.SERVICE_DETAILS.FACILITY_ESTIMATE,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <InputNumber
                  value={value}
                  currency="USD"
                  locale="en-US"
                  mode="currency"
                  onBlur={() =>
                    handleOnBlurField({
                      onBlur,
                      field: "estimatedVisitCostForPatient",
                      trigger,
                    })
                  }
                  inputId="estimatedVisitCostForPatient"
                  onChange={({ value }) => onChange(value)}
                />

                {getFormErrorMessage("estimatedVisitCostForPatient", errors)}
              </div>
            )}
          />
        </div>
      </FieldsContainer>
      <FieldsContainer>
        <div className="flex">
          <label htmlFor="amount" className="mr-2 flex align-items-center">
            Subtotal Cost
          </label>

          <Controller
            name="amount"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputNumber
                disabled
                value={value}
                currency="USD"
                locale="en-US"
                mode="currency"
                inputId="amount"
                onChange={({ value }) => onChange(value)}
              />
            )}
          />
        </div>
      </FieldsContainer>

      <FieldsContainer>
        <div className="flex">
          <label htmlFor="totalAmount" className="mr-2 flex align-items-center">
            Clear Price for Patient
          </label>

          <Controller
            name="totalAmount"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputNumber
                disabled
                value={value}
                currency="USD"
                locale="en-US"
                mode="currency"
                inputId="totalAmount"
                onChange={({ value }) => onChange(value)}
              />
            )}
          />
        </div>
      </FieldsContainer>
    </StyledCard>
  );
}
