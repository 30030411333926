import { Label } from "layout/typography/Label";
import { Chips } from "primereact/chips";
import { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

const Title = styled.h3`
  margin-bottom: 16px;
`;

export function ExcludedCptCodes() {
  const { control, watch } = useFormContext();
  const [cptCodes, setCptCodes] = useState<string[]>([]);
  const watchedCptCodes = watch("excludedCptCodes");

  useEffect(() => {
    if (watchedCptCodes) {
      setCptCodes(watchedCptCodes);
    }
  }, [watchedCptCodes]);

  return (
    <div className="block bg-white border-round p-4 mb-4">
      <Title>Excluded CPT Codes</Title>
      <Label htmlFor="cptCodes">Add CPT Codes</Label>
      <Controller
        name="excludedCptCodes"
        control={control}
        render={({ field: { onChange } }) => (
          <Chips
            id="excludedCptCodes"
            value={cptCodes}
            className="w-full mt-2"
            onChange={(e: any) => {
              setCptCodes(e.value.map((item: any) => item.trim()));
              onChange(e.value.map((item: any) => item.trim()));
            }}
            separator=","
          />
        )}
      />
    </div>
  );
}
