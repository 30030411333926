import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { IOrder } from "types/Order/Order";

const StyledCard = styled(Card)`
  margin-bottom: ${sizer(2)};
`;

interface IPayerDetails {
  payerName: string;
  placeOfService: string;
  locality: string;
}

function PayerDetails({ order }: { order: IOrder }) {
  const { control, setValue } = useFormContext<IPayerDetails>();

  useEffect(() => {
    setValue("payerName", (order.serviceDetails as any)?.payerName || "Any");
  }, [order, setValue]);

  return (
    <StyledCard title="Patient Benefits">
      <div className="formgrid grid w-100">
        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="payerName">Payer Name</Label>

          <Controller
            name="payerName"
            control={control}
            render={({ field: { value } }) => (
              <InputText
                disabled
                data-testid="payerName"
                id="payerName"
                placeholder="Payer Name"
                value={value}
              />
            )}
          />
        </div>
      </div>
    </StyledCard>
  );
}

export default PayerDetails;
