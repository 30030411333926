import {
  IProcedureDiscountRuleset,
  IProcedureDiscountRulesetCreateRequest,
  IProcedureDiscountRulesetUpdateRequest,
} from "types/ProcedureDiscountRuleset/ProcedureDiscountRuleset";
import {
  IAllProcedureDiscountRulesetResponse,
  IProcedureDiscountRulesetResponse,
} from "types/ProcedureDiscountRuleset/ProcedureDiscountRulesetResponse";

import { api } from ".";

const procedureDiscountRulesetQueries = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllDiscountRulesets: builder.query<
      IAllProcedureDiscountRulesetResponse,
      null | void
    >({
      query: () => "payer-rules/all",
    }),
    getDiscountRulesetById: builder.query<
      IProcedureDiscountRulesetResponse,
      string | undefined
    >({
      query: (id: string) => `payer-rules/${id}`,
    }),
    createDiscountRuleset: builder.mutation<
      IProcedureDiscountRuleset,
      IProcedureDiscountRulesetCreateRequest
    >({
      query: (newRuleset) => ({
        url: "payer-rules",
        method: "POST",
        body: newRuleset,
      }),
    }),
    updateDiscountRuleset: builder.mutation<
      IProcedureDiscountRuleset,
      IProcedureDiscountRulesetUpdateRequest
    >({
      query: (updatedRuleset) => ({
        url: `payer-rules/${updatedRuleset.id}`,
        method: "PUT",
        body: updatedRuleset,
      }),
    }),
    deleteDiscountRuleset: builder.mutation<any, string>({
      query: (id) => ({
        url: `payer-rules/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllDiscountRulesetsQuery,
  useGetDiscountRulesetByIdQuery,
  useCreateDiscountRulesetMutation,
  useUpdateDiscountRulesetMutation,
  useDeleteDiscountRulesetMutation,
} = procedureDiscountRulesetQueries;
