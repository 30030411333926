import { LoadingSpinner } from "components/LoadingSpinner";
import useDocumentTitle from "hooks/useDocumentTitle";
import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useGetCaseRateRulesetByIdQuery } from "store/queries/pricing";

import { CptCodes } from "./components/CptCodes";
import { ExcludedCptCodes } from "./components/ExcludedCptCodes";
import { Header } from "./components/Header";
import { ProviderAndPayerEditSection } from "./components/ProviderAndPayerEditSection";
import { RuleName } from "./components/RuleName";
import { SubmitEditCaseRate } from "./components/SubmitEditCaseRate";

export function EditCaseRateRulesPage() {
  useDocumentTitle("Edit Case Rate Rules");
  const { id } = useParams();
  const { data: ruleset, isLoading } = useGetCaseRateRulesetByIdQuery(id);

  const methods = useForm({
    defaultValues: {
      provider: {
        name: ruleset?.data.provider.name,
        value: ruleset?.data.provider.id,
      },
      payerName: {
        name: ruleset?.data?.payer?.name,
        value: ruleset?.data?.payer?.id,
      },
      groupName: ruleset?.data.groupName,
      cptCodes: ruleset?.data.cptCodes,
      excludedCptCodes: ruleset?.data.excludedCptCodes,
    },
  });

  useEffect(() => {
    if (ruleset) {
      methods.reset({
        provider: {
          name: ruleset?.data.provider.name,
          value: ruleset?.data.provider.id,
        },
        payerName: {
          name: ruleset?.data?.payer?.name,
          value: ruleset?.data?.payer?.id,
        },
        groupName: ruleset?.data.groupName,
        cptCodes: ruleset?.data.cptCodes,
        excludedCptCodes: ruleset?.data.excludedCptCodes,
      });
    }
  }, [ruleset, methods]);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <FormProvider {...methods}>
      <Header name={ruleset?.data.groupName} isCaseRate />
      <ProviderAndPayerEditSection />
      <RuleName />
      <CptCodes />
      <ExcludedCptCodes />
      {id && ruleset && <SubmitEditCaseRate id={id} />}
    </FormProvider>
  );
}
