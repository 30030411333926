import PingAnimation from "components/PingAnimation/PingAnimation";
import { orderStatusConstants } from "constants/order";
import { useLoading } from "context/LoadingContext";
import {
  AutoComplete,
  AutoCompleteChangeParams,
} from "primereact/autocomplete";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { onStaffAccountChange } from "store/slices/user";
import styled from "styled-components";
import { IAccount } from "types/Account/Account";

const StyledDropdown = styled(AutoComplete)`
  min-width: 330px !important;
`;

function ChangeAccountDropdown({
  defaultSelected,
  accounts,
  onAccountChange,
}: {
  defaultSelected: string | null;
  accounts: IAccount[];
  onAccountChange?: (account: IAccount) => void;
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [selectedAccount, setSelectedAccount] = useState<string | null>(
    defaultSelected
  );
  const [filteredAccounts, setFilteredAccounts] = useState<IAccount[]>([]);

  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const parsedFilteredAccounts = useMemo(() => {
    const accountsList = filteredAccounts.map((account: IAccount) => {
      const pendingOrdersCounts = account.orders.filter(
        (order) => order.status === orderStatusConstants.DRAFT
      ).length;
      return { name: account.name, pending: pendingOrdersCounts };
    });
    return accountsList;
  }, [filteredAccounts]);

  const searchAccount = (event: { query: string }) => {
    setTimeout(() => {
      let filteredAccountsInternal;
      if (!event.query.trim().length) {
        filteredAccountsInternal = [...accounts];
      } else {
        filteredAccountsInternal = accounts.filter((account: IAccount) => {
          return account.name
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }
      setFilteredAccounts(filteredAccountsInternal);
    }, 250);
  };

  const onAutoCompleteChange = (e: AutoCompleteChangeParams) => {
    setSelectedAccount(e.value.name);
    const newlySelectedAccount = accounts.find(
      (theAccount) => theAccount.name === e.value.name
    );
    if (newlySelectedAccount?.id) {
      onAccountChange?.(newlySelectedAccount);
      dispatch(onStaffAccountChange({ currentAccount: newlySelectedAccount }));
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        if (location.pathname === "/orders") {
          navigate(`/orders?accountId=${newlySelectedAccount?.id}`);
        } else {
          navigate(`/hospitals/view/${newlySelectedAccount?.id}`);
        }
      }, 1000);
    }
  };

  const itemTemplate = (item: any) => {
    return (
      <div className="flex gap-2 justify-content-between align-items-center">
        {item.name}
        {item.pending ? <PingAnimation count={item.pending} /> : null}
      </div>
    );
  };

  return (
    <StyledDropdown
      dropdown
      value={selectedAccount}
      completeMethod={searchAccount}
      placeholder="Ex: Hospital Clear"
      suggestions={parsedFilteredAccounts}
      onChange={onAutoCompleteChange}
      itemTemplate={itemTemplate}
    />
  );
}

export default ChangeAccountDropdown;
