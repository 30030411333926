import { FieldValues } from "react-hook-form";

export const mapFormToRequestBody = async (
  props: FieldValues,
  { editMode }: { editMode: boolean }
) => ({
  name: props.name,
  email: props.email,
  scope: props.scope.replace(/\s+/g, ""),
  ...(editMode
    ? { accountIds: props.accountIds }
    : { accountId: props.accountIds }),
});
