import { getPosthogConfig } from "config/api";
import posthog from "posthog-js";

export function usePostHog() {
  const { apiKey, apiHost } = getPosthogConfig();

  // Initialize PostHog only once
  const initializePostHog = () => {
    posthog.init(apiKey, {
      api_host: apiHost,
      autocapture: true, // Enable automatic tracking of button clicks, form submissions, etc.
      capture_pageview: true, // Track page views automatically on initial load
      session_recording: {
        maskTextSelector: ".sensitive",
        blockSelector: ".sensitive",
        blockClass: "sensitive",
        maskInputFn: (text, element) => {
          if (
            element?.getAttribute("id") === "firstName" ||
            element?.getAttribute("id") === "lastName" ||
            element?.getAttribute("id") === "email" ||
            element?.getAttribute("id") === "dateOfBirth" ||
            element?.getAttribute("id") === "phoneNumber" ||
            element?.getAttribute("id") === "patientSearch" ||
            element?.getAttribute("id") === "code" ||
            element?.getAttribute("id") === "patientAccountNumber"
          ) {
            return "*".repeat(text.length);
          }
          return text;
        },
      },
    });
  };

  // Track custom events
  const posthogEvent = (event: string, properties?: object) => {
    posthog.capture(event, properties);
  };

  // Track page view events with custom properties (e.g., account name, order type, etc.)
  const pageViewEvents = (
    {
      accountName,
      orderType,
      communicationMethod,
    }: {
      [key: string]: string | undefined;
    },
    event: string
  ) => {
    posthog.capture(event, {
      accountName,
      orderType,
      communicationMethod,
    });
  };

  return {
    posthogEvent,
    pageViewEvents,
    initializePostHog,
  };
}
