import styled from "styled-components";

export const ContentContainer = styled.div`
  margin-top: 80px;
`;

export const ImageContainer = styled.div`
  height: 80px;

  @media (max-width: 720px) {
    height: 62px;
  }
`;

export const StyledImage = styled.img`
  height: 42px;

  @media (max-width: 720px) {
    height: 30px;
  }
`;
