export function interpolateString(
  template: string,
  values: Record<string, string>
): string {
  if (!template) return "";

  return template.replace(/\{\{(.*?)\}\}/g, (match, key) => {
    const replacement = values[key];
    return replacement !== undefined && replacement !== "" ? replacement : "";
  });
}
