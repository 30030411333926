export const roundToX = (value: string | number, digits = 2) => {
  if (typeof value === "string") {
    return (
      Math.round((parseFloat(value) + Number.EPSILON) * 10 ** digits) /
      10 ** digits
    );
  }

  return Math.round((value + Number.EPSILON) * 10 ** digits) / 10 ** digits;
};
