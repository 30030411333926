import { useSelector } from "react-redux";
import { verificationSelector } from "store/slices/verification";

import { DOBAuth } from "./components/DOBAuth";
import { HospitalConfirmation } from "./components/HospitalConfirmation";
import { Receipt } from "./components/Receipt";
import { VerificationSteps } from "./utils/constants";

type VerificationPageProps = {
  variant: VerificationSteps;
};

export function VerificationPage({ variant }: VerificationPageProps) {
  const { verificationStep } = useSelector(verificationSelector);

  switch (verificationStep) {
    case VerificationSteps.RECEIPT:
      return <Receipt />;

    case VerificationSteps.HOSPITAL_CONFIRMATION:
      return <HospitalConfirmation />;

    default:
      return <DOBAuth {...{ variant }} />;
  }
}
