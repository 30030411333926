import { Label } from "layout/typography/Label";
import { Checkbox } from "primereact/checkbox";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

const Title = styled.h3`
  margin-bottom: 16px;
`;

export function AllowDuplicateCptCodes() {
  const { control } = useFormContext<any>();
  return (
    <div className="block bg-white border-round p-4 mb-4">
      <Title>Rule</Title>
      <Label htmlFor="groupName">Allow Duplicate CPT codes</Label>
      <Controller
        name="allowOnDuplicateCptCodes"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Checkbox className="ml-3" onChange={onChange} checked={value} />
        )}
      />
    </div>
  );
}
