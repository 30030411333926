import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { ChatWidget } from "components/ChatWidget";
import { LoadingSpinner } from "components/LoadingSpinner";
import { UserRole } from "constants/userRole";
import { useLoading } from "context/LoadingContext";
import { useUserWithAccount } from "context/UserAccountProvider";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { authSelector, onTokenChange } from "store/slices/auth";
import { userSelector } from "store/slices/user";
import styled from "styled-components";

import { Loading } from "../Loading";
import { Navbar } from "../Navbar";
import { Sidebar } from "../Sidebar";

const ContentContainer = styled.div`
  width: 100%;
  min-height: 100vh;

  padding: 104px 16px 24px 116px;

  background: var(--bg-white);
`;

function AppLayoutElement() {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { token } = useSelector(authSelector);
  const { isLoading } = useLoading();

  const { accountRefetch } = useUserWithAccount();
  const { currentAccount, scope, name, email, chatOnlineEnabled } =
    useSelector(userSelector);

  const initialLoad = async () => {
    let token: string;
    try {
      token = await getAccessTokenSilently();
    } catch (err: any) {
      console.log("error", err);
      loginWithRedirect();
      return;
    }

    dispatch(onTokenChange({ token }));

    setTimeout(() => {
      accountRefetch();
    }, 500);
  };

  useEffect(() => {
    initialLoad();
  }, []);

  useEffect(() => {
    if (scope === UserRole.HOSPITAL_STAFF && location.pathname === "/") {
      navigate(`/hospitals/view/${currentAccount}`);
    } else if (scope === UserRole.SUPER_ADMIN && location.pathname === "/") {
      navigate("/hospitals");
    }
  }, [scope, location, currentAccount]);

  if (token && currentAccount) {
    return (
      <>
        <Navbar />
        <Sidebar />
        <ConfirmDialog />

        <ContentContainer>
          {isLoading && <Loading />}

          <Outlet />
        </ContentContainer>
        {chatOnlineEnabled && (
          <ChatWidget
            currentAccount={currentAccount}
            name={name || ""}
            scope={scope || ""}
            email={email || ""}
          />
        )}
      </>
    );
  }

  return (
    <ContentContainer>
      <LoadingSpinner />
    </ContentContainer>
  );
}

export const AppLayout = withAuthenticationRequired(AppLayoutElement, {});
