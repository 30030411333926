import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import styled from "styled-components";

export const StyledDiscountBanner = styled.div`
  height: fit-content;
  background: rgba(4, 198, 169, 0.1);
`;

export const StyledServicesList = styled.div``;

export const HorizontalLine = styled.hr`
  border-top: 1px solid #e5e5e5;
`;

export const StyledContainer = styled.div`
  margin-top: ${sizer(4)};
  padding: 1.5rem;
  background-color: var(--color-white);
  border-radius: 0.625rem;
  border: 1px solid rgb(218, 232, 255);
  box-shadow: rgba(247, 247, 247, 0.25) 0px 17px 20px;
  height: fit-content;

  @media (max-width: 425px) {
    padding: 1.5rem 0.5rem;
  }
`;

export const Pill = styled.div`
  color: #ff0000;
  width: fit-content;
`;

export const PageLayout = styled.div`
  max-width: 720px;
  padding: 5px;
  font-size: 14px;

  .purple {
    color: #5b4dfd;
  }

  .black {
    color: black;
  }

  .gray {
    color: #766b6b;
  }

  .red {
    color: #ff0000;
  }

  .green {
    color: #35c92b;
  }
`;

export const StyledButton = styled(Button)`
  gap: ${sizer(2)};
  margin-bottom: ${sizer(4)};

  span:first-child {
    font-size: ${sizer(3)};
  }
`;

export const PriceWrapper = styled.div`
  background: rgba(71, 105, 234, 0.05);
  border-radius: 0.5rem;
  padding: 0.625rem 1rem;
`;

export const Spacer = styled.div`
  flex: initial;
  width: 20%;
`;

export const StyledLinkButton = styled(Button)``;
