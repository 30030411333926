import { useMemo } from "react";

import { useGetCurrentUserQuery } from "../store/queries/users";

export function useCurrentUser({ skip }: { skip: boolean }) {
  const { data, isLoading, isFetching } = useGetCurrentUserQuery(null, {
    skip,
    refetchOnMountOrArgChange: true,
  });

  const userInfo = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return data;
  }, [data]);

  return { userInfo, loaded: !isLoading || !isFetching };
}
