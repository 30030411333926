import useFormValidation from "hooks/useFormValidation";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { useState, useMemo } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { ValueNoteSection } from "./components/ValueNoteSection";

const StyledCard = styled(Card)<{ ordertypefield: string }>`
  margin-bottom: ${sizer(4)};
  display: ${({ ordertypefield }) =>
    ordertypefield === "GFE" ? "block" : "none"};
`;

const StyledShowMore = styled.p`
  color: var(--color-purple);
`;

export function PatientBenefitsForm() {
  const [showMore, setShowMore] = useState(false);
  const { watch, control, trigger } = useFormContext<FieldValues>();

  const orderTypeField = watch("orderType");
  const eligibilityDetailsField = watch("eligibilityDetails");
  const { handleOnBlurField } = useFormValidation();
  const individualDeductible = useMemo(() => {
    const individualDeductibleRemainingInNet =
      eligibilityDetailsField?.HBPC_Deductible_OOP_Summary
        ?.IndividualDeductibleRemainingInNet;
    return [individualDeductibleRemainingInNet].filter(
      (value) => value !== null && value !== undefined
    );
  }, [eligibilityDetailsField]);

  const familyDeductible = useMemo(() => {
    const familyRemainingDeductibleInNet =
      eligibilityDetailsField?.HBPC_Deductible_OOP_Summary
        ?.FamilyDeductibleRemainingInNet;
    return [familyRemainingDeductibleInNet].filter(
      (value) => value !== null && value !== undefined
    );
  }, [eligibilityDetailsField]);

  const individualOOP = useMemo(() => {
    const individualRemainingOOPInNet =
      eligibilityDetailsField?.HBPC_Deductible_OOP_Summary
        ?.IndividualOOPRemainingInNet;
    return [individualRemainingOOPInNet].filter(
      (value) => value !== null && value !== undefined
    );
  }, [eligibilityDetailsField]);

  const familyOOP = useMemo(() => {
    const familyRemainingOOPInNet =
      eligibilityDetailsField?.HBPC_Deductible_OOP_Summary
        ?.FamilyOOPRemainingInNet;
    return [familyRemainingOOPInNet].filter(
      (value) => value !== null && value !== undefined
    );
  }, [eligibilityDetailsField]);

  const specialitOfficeBenefits = useMemo(() => {
    const specialistOfficeSummaryCoPay =
      eligibilityDetailsField?.SpecialistOfficeSummary?.CoPayInNet;
    const specialistOfficeSummaryCoins =
      eligibilityDetailsField?.SpecialistOfficeSummary?.CoInsInNet;
    const individualDeductible = eligibilityDetailsField
      ?.SpecialistOfficeSummary?.IndividualDeductibleRemainingInNet as {
      Value: string;
      Notes: string;
    } | null;
    const copayFromServiceDetails =
      eligibilityDetailsField?.SpecialistOfficeSummary?.serviceDetails?.copay;
    const coinsuranceFromServiceDetails =
      eligibilityDetailsField?.SpecialistOfficeSummary?.serviceDetails
        ?.coinsurance;
    const copayDetails = {
      subtitle: "Copay",
      Value: specialistOfficeSummaryCoPay?.Value,
      Notes: specialistOfficeSummaryCoPay?.Notes,
      additionalCopay: copayFromServiceDetails,
    };
    const coinsDetails = {
      subtitle: "Coinsurance",
      Value: specialistOfficeSummaryCoins?.Value,
      Notes: specialistOfficeSummaryCoins?.Notes,
      additionalCoinsurance: coinsuranceFromServiceDetails,
    };
    const item1 =
      specialistOfficeSummaryCoPay || copayFromServiceDetails
        ? copayDetails
        : null;
    const item2 =
      specialistOfficeSummaryCoins || coinsuranceFromServiceDetails?.length
        ? coinsDetails
        : null;
    const item3 = individualDeductible
      ? { subtitle: "Remaining Deductible", ...individualDeductible }
      : null;
    return [item1, item2, item3].filter(
      (value) => value !== null && value !== undefined
    ) as any[];
  }, [eligibilityDetailsField]);

  const ascSummary = useMemo(() => {
    const copay = eligibilityDetailsField?.ASC_FacilitySummary?.CoPayInNet as {
      Value: string;
      Notes: string;
    } | null;
    const coins = eligibilityDetailsField?.ASC_FacilitySummary?.CoInsInNet as {
      Value: string;
      Notes: string;
    } | null;
    const copaysFromServiceDetails =
      eligibilityDetailsField?.ASC_FacilitySummary?.serviceDetails?.copay;
    const coinsuranceFromServiceDetails =
      eligibilityDetailsField?.ASC_FacilitySummary?.serviceDetails?.coinsurance;
    const copayDetails = {
      subtitle: "Copay",
      Value: copay?.Value,
      Notes: copay?.Notes,
      additionalCopay: copaysFromServiceDetails,
    };
    const coinsDetails = {
      subtitle: "Coinsurance",
      Value: coins?.Value,
      Notes: coins?.Notes,
      additionalCoinsurance: coinsuranceFromServiceDetails,
    };
    const item1 =
      copay || copaysFromServiceDetails?.length ? copayDetails : null;
    const item2 =
      coins || coinsuranceFromServiceDetails?.length ? coinsDetails : null;
    return [item1, item2].filter(
      (value) => value !== null && value !== undefined
    ) as any[];
  }, [eligibilityDetailsField]);

  const hospitalInpatientSummary = useMemo(() => {
    const copay = eligibilityDetailsField?.HospitalInpatientSummary
      ?.CoPayInNet as {
      Value: string;
      Notes: string;
    } | null;
    const coins = eligibilityDetailsField?.HospitalInpatientSummary
      ?.CoInsInNet as {
      Value: string;
      Notes: string;
    } | null;
    const copayFromServiceDetails =
      eligibilityDetailsField?.HospitalInpatientSummary?.serviceDetails?.copay;
    const coinsuranceFromServiceDetails =
      eligibilityDetailsField?.HospitalInpatientSummary?.serviceDetails
        ?.coinsurance;
    const copayDetails = {
      subtitle: "Copay",
      Value: copay?.Value,
      Notes: copay?.Notes,
      additionalCopay: copayFromServiceDetails,
    };
    const coinsDetails = {
      subtitle: "Coinsurance",
      Value: coins?.Value,
      Notes: coins?.Notes,
      additionalCoinsurance: coinsuranceFromServiceDetails,
    };
    const item1 =
      copay || copayFromServiceDetails?.length ? copayDetails : null;
    const item2 =
      coins || coinsuranceFromServiceDetails?.length ? coinsDetails : null;
    return [item1, item2].filter(
      (value) => value !== null && value !== undefined
    ) as any[];
  }, [eligibilityDetailsField]);

  const hospitalOutpatientBenefits = useMemo(() => {
    const copay = eligibilityDetailsField?.HospitalOutPatientSummary
      ?.CoPayInNet as {
      Value: string;
      Notes: string;
    } | null;
    const coins = eligibilityDetailsField?.HospitalOutPatientSummary
      ?.CoInsInNet as {
      Value: string;
      Notes: string;
    } | null;
    const copayFromServiceDetails =
      eligibilityDetailsField?.HospitalOutPatientSummary?.serviceDetails?.copay;
    const coinsuranceFromServiceDetails =
      eligibilityDetailsField?.HospitalOutPatientSummary?.serviceDetails
        ?.coinsurance;
    const copayDetails = {
      subtitle: "Copay",
      Value: copay?.Value,
      Notes: copay?.Notes,
      additionalCopay: copayFromServiceDetails,
    };
    const coinsDetails = {
      subtitle: "Coinsurance",
      Value: coins?.Value,
      Notes: coins?.Notes,
      additionalCoinsurance: coinsuranceFromServiceDetails,
    };

    const item1 =
      copay || copayFromServiceDetails?.length ? copayDetails : null;
    const item2 =
      coins || coinsuranceFromServiceDetails?.length ? coinsDetails : null;
    return [item1, item2].filter(
      (value) => value !== null && value !== undefined
    ) as any[];
  }, [eligibilityDetailsField]);

  const mriCtSummary = useMemo(() => {
    const copay = eligibilityDetailsField?.MRI_CT_ScanSummary?.CoPayInNet as {
      Value: string;
      Notes: string;
    } | null;
    const coins = eligibilityDetailsField?.MRI_CT_ScanSummary?.CoInsInNet as {
      Value: string;
      Notes: string;
    } | null;
    const copaysFromServiceDetails =
      eligibilityDetailsField?.MRI_CT_ScanSummary?.serviceDetails?.copay;
    const coinsuranceFromServiceDetails =
      eligibilityDetailsField?.MRI_CT_ScanSummary?.serviceDetails?.coinsurance;
    const copayDetails = {
      subtitle: "Copay",
      Value: copay?.Value,
      Notes: copay?.Notes,
      additionalCopay: copaysFromServiceDetails,
    };
    const coinsDetails = {
      subtitle: "Coinsurance",
      Value: coins?.Value,
      Notes: coins?.Notes,
      additionalCoinsurance: coinsuranceFromServiceDetails,
    };
    const item1 =
      copay || copaysFromServiceDetails?.length ? copayDetails : null;
    const item2 =
      coins || coinsuranceFromServiceDetails?.length ? coinsDetails : null;
    return [item1, item2].filter(
      (value) => value !== null && value !== undefined
    ) as any[];
  }, [eligibilityDetailsField]);

  const xRaySummary = useMemo(() => {
    const copay = eligibilityDetailsField?.Diagnostic_X_Ray?.CoPayInNet as {
      Value: string;
      Notes: string;
    } | null;
    const coins = eligibilityDetailsField?.Diagnostic_X_Ray?.CoInsInNet as {
      Value: string;
      Notes: string;
    } | null;
    const item1 = copay ? { subtitle: "Copay", ...copay } : null;
    const item2 = coins ? { subtitle: "Coinsurance", ...coins } : null;
    return [item1, item2].filter(
      (value) => value !== null && value !== undefined
    ) as any[];
  }, [eligibilityDetailsField]);

  const showAdditionalDetailsSection = useMemo(() => {
    const hasData =
      individualDeductible.length > 0 ||
      familyDeductible.length > 0 ||
      individualOOP.length > 0 ||
      familyOOP.length > 0 ||
      specialitOfficeBenefits.length > 0 ||
      ascSummary.length > 0 ||
      hospitalInpatientSummary.length > 0 ||
      mriCtSummary.length > 0 ||
      hospitalOutpatientBenefits.length > 0;
    return eligibilityDetailsField && hasData;
  }, [
    individualDeductible,
    familyDeductible,
    individualOOP,
    familyOOP,
    eligibilityDetailsField,
    specialitOfficeBenefits,
    ascSummary,
    hospitalInpatientSummary,
    mriCtSummary,
    hospitalOutpatientBenefits,
  ]);

  return (
    <StyledCard title="Patient Benefits" ordertypefield={orderTypeField}>
      <div className="flex flex-column gap-4 w-100">
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="deductible">Remaining Deductible</Label>

            <Controller
              name="deductible"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNumber
                  value={value}
                  currency="USD"
                  locale="en-US"
                  mode="currency"
                  onBlur={() =>
                    handleOnBlurField({
                      onBlur,
                      field: "deductible",
                      trigger,
                    })
                  }
                  inputId="deductible"
                  onValueChange={({ value }) => onChange(value)}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  min={0}
                  aria-autocomplete="none"
                />
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="outOfPocketMax">Remaining Out-of-pocket Max</Label>

            <Controller
              name="outOfPocketMax"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNumber
                  value={value}
                  currency="USD"
                  locale="en-US"
                  mode="currency"
                  onBlur={() =>
                    handleOnBlurField({
                      onBlur,
                      field: "outOfPocketMax",
                      trigger,
                    })
                  }
                  inputId="outOfPocketMax"
                  onValueChange={({ value }) => onChange(value)}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  min={0}
                  aria-autocomplete="none"
                />
              )}
            />
          </div>
          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="flatCopay">Flat Copay</Label>

            <Controller
              name="flatCopay"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNumber
                  value={value}
                  currency="USD"
                  locale="en-US"
                  mode="currency"
                  onBlur={() =>
                    handleOnBlurField({
                      onBlur,
                      field: "flatCopay",
                      trigger,
                    })
                  }
                  inputId="flatCopay"
                  onValueChange={({ value }) => onChange(value)}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  min={0}
                  aria-autocomplete="none"
                />
              )}
            />
          </div>
          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="coinsurancePercent">Coinsurance Percent</Label>

            <Controller
              name="coinsurancePercent"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNumber
                  value={value}
                  suffix="%"
                  onBlur={() =>
                    handleOnBlurField({
                      onBlur,
                      field: "coinsurancePercent",
                      trigger,
                    })
                  }
                  inputId="coinsurancePercent"
                  onValueChange={({ value }) => onChange(value)}
                  min={0}
                  max={100}
                  aria-autocomplete="none"
                />
              )}
            />
          </div>
        </div>
        {showAdditionalDetailsSection && (
          <div className="w-full flex flex-column">
            <StyledShowMore
              className="small-text purple flex align-items-center cursor-pointer mb-4"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? (
                <>
                  Show Less <i className="pi pi-angle-up ml-1" />
                </>
              ) : (
                <>
                  Show More <i className="pi pi-angle-down ml-1" />
                </>
              )}
            </StyledShowMore>
            {showMore && (
              <>
                <div className="w-full formgrid grid">
                  <ValueNoteSection
                    title="Remaining Individual Deductible"
                    items={individualDeductible}
                    className="col-6"
                  />
                  <ValueNoteSection
                    title="Remaining Family Deductible"
                    items={familyDeductible}
                    className="col-6"
                  />
                </div>
                <div className="w-full formgrid grid">
                  <ValueNoteSection
                    title="Remaining Individual OOP"
                    items={individualOOP}
                    className="col-6"
                  />
                  <ValueNoteSection
                    title="Remaining Family OOP"
                    items={familyOOP}
                    className="col-6"
                  />
                </div>
                <ValueNoteSection
                  title="Specialist Office"
                  items={specialitOfficeBenefits}
                  className="mt-4"
                />
                <ValueNoteSection
                  title="ASC Benefits"
                  items={ascSummary}
                  className="mt-4"
                />
                <ValueNoteSection
                  title="MRI/CT Scan Benefits"
                  items={mriCtSummary}
                  className="mt-4"
                />
                <ValueNoteSection
                  title="Diagnostic X-Ray"
                  items={xRaySummary}
                  className="mt-4"
                />
                <ValueNoteSection
                  title="Inpatient Benefits"
                  items={hospitalInpatientSummary}
                />
                <ValueNoteSection
                  title="Outpatient Benefits"
                  items={hospitalOutpatientBenefits}
                />
              </>
            )}
          </div>
        )}
      </div>
    </StyledCard>
  );
}
