import { useAuth0 } from "@auth0/auth0-react";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "store/queries/auth";
import { onTokenChange } from "store/slices/auth";

export interface ILoginPageProps {
  returnTo?: string;
}

export function LoginPage({ returnTo }: ILoginPageProps): JSX.Element {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login] = useLoginMutation();

  const getToken = async () => {
    const token = await getAccessTokenSilently();

    dispatch(onTokenChange({ token }));
  };

  useEffect(() => {
    if (isAuthenticated) {
      getToken();

      if (returnTo) {
        navigate(returnTo);
      } else {
        navigate("/");
      }

      return;
    }

    if (isLoading || isAuthenticated) {
      return;
    }

    if (!isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, isLoading, login]);

  return <LoadingSpinner />;
}
