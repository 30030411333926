import { Label } from "layout/typography/Label";
import { InputText } from "primereact/inputtext";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

const Title = styled.h3`
  margin-bottom: 16px;
`;

export function RuleName() {
  const { control } = useFormContext<any>();
  return (
    <div className="block bg-white border-round p-4 mb-4">
      <Title>Rule</Title>
      <Label htmlFor="groupName">Rule Name</Label>
      <Controller
        name="groupName"
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputText
            className="w-full mt-2"
            id="groupName"
            autoComplete="off"
            value={value || ""}
            placeholder="Enter rule name here"
            aria-labelledby="groupName"
            onChange={onChange}
          />
        )}
      />
    </div>
  );
}
