// Import createSlice from Redux Toolkit
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { buildInternalRowsQuantity } from "pages/CreateOrder/components/ServiceDetails/builders";
import { PriceTableBreakdown, ProcedureRow } from "types/CreateOrder";

export interface IPriceBreakdownRows {
  adjustments: PriceTableBreakdown[];
  patientResponsibility: PriceTableBreakdown[];
}

export interface IServiceDetailsState {
  rows: ProcedureRow[];
  autoCompleteValue: string[];
  selectedProcedures: string[];
  dataTableRows: ProcedureRow[];
  displayAdjustments: boolean;
  displayPatientResponsibility: boolean;
  displayProviderFinalAmounts: boolean;
  priceBreakdownRows: IPriceBreakdownRows;
  loadingPriceBreakdown: boolean;
}

// Define the initial state using that type
const initialState: IServiceDetailsState = {
  rows: [],
  autoCompleteValue: [],
  selectedProcedures: [],
  dataTableRows: [],
  displayAdjustments: false,
  displayPatientResponsibility: false,
  displayProviderFinalAmounts: false,
  priceBreakdownRows: { adjustments: [], patientResponsibility: [] },
  loadingPriceBreakdown: false,
};

export const serviceDetailsSlice = createSlice({
  name: "serviceDetails",
  initialState,
  reducers: {
    setRows: (state, action: PayloadAction<ProcedureRow[]>) => {
      state.dataTableRows = buildInternalRowsQuantity(action.payload);
      state.rows = action.payload;
    },
    updateRows: (state, action: PayloadAction<ProcedureRow[]>) => {
      state.dataTableRows = buildInternalRowsQuantity([
        ...state.rows,
        ...action.payload,
      ]);
      state.rows = [...state.rows, ...action.payload];
    },
    removeRows: (state, action: PayloadAction<string[]>) => {
      const idsToRemove = new Set(action.payload);
      state.rows = state.rows.filter(
        (row) => !idsToRemove.has(row.id.toString())
      );
      state.dataTableRows = buildInternalRowsQuantity(state.rows);
    },
    setAutoCompleteValue: (state, action: PayloadAction<string[]>) => {
      state.autoCompleteValue = action.payload;
    },
    addAutoCompleteValue: (state, action: PayloadAction<string>) => {
      const newValue = action.payload;
      if (!state.autoCompleteValue.includes(newValue)) {
        state.autoCompleteValue.push(newValue);
      }
    },
    updateAutoCompleteList: (state, action: PayloadAction<string[]>) => {
      if (state.autoCompleteValue !== action.payload) {
        state.autoCompleteValue = action.payload;
      }
    },
    setSelectedProcedures: (state, action: PayloadAction<string[]>) => {
      state.selectedProcedures = action.payload;
    },
    addSelectedProcedures: (state, action: PayloadAction<string>) => {
      state.selectedProcedures.push(action.payload);
    },
    toggleDisplayAdjustments: (state) => {
      state.displayAdjustments = !state.displayAdjustments;
    },
    toggleDisplayPatientResponsibility: (state) => {
      state.displayPatientResponsibility = !state.displayPatientResponsibility;
    },
    toggleDisplayProviderFinalAmounts: (state) => {
      state.displayProviderFinalAmounts = !state.displayProviderFinalAmounts;
    },
    setPriceBreakdownRows: (
      state,
      action: PayloadAction<{
        adjustments: PriceTableBreakdown[];
        patientResponsibility: PriceTableBreakdown[];
      }>
    ) => {
      const { adjustments, patientResponsibility } = action.payload;
      state.priceBreakdownRows.adjustments = adjustments;
      state.priceBreakdownRows.patientResponsibility = patientResponsibility;
      state.loadingPriceBreakdown = false;
    },
    cleanUpServiceDetails: (state) => {
      state.rows = [];
      state.dataTableRows = [];
      state.autoCompleteValue = [];
      state.selectedProcedures = [];
      state.priceBreakdownRows = { adjustments: [], patientResponsibility: [] };
      state.loadingPriceBreakdown = false;
    },
    setLoadingPriceBreakdown: (state, action: PayloadAction<boolean>) => {
      state.loadingPriceBreakdown = action.payload;
    },
    removeSelectedProcedures: (state, action: PayloadAction<string>) => {
      const index = state.selectedProcedures.indexOf(action.payload);
      if (index > -1) {
        state.selectedProcedures = state.selectedProcedures.filter(
          (_, i) => i !== index
        );
      }
    },
    removeAutoComplete: (state, action: PayloadAction<string>) => {
      const index = state.autoCompleteValue.indexOf(action.payload);
      if (index > -1) {
        state.autoCompleteValue = state.autoCompleteValue.filter(
          (_, i) => i !== index
        );
      }
    },
  },
});

// Export actions
export const {
  setRows,
  addAutoCompleteValue,
  addSelectedProcedures,
  setAutoCompleteValue,
  setSelectedProcedures,
  toggleDisplayAdjustments,
  toggleDisplayProviderFinalAmounts,
  toggleDisplayPatientResponsibility,
  setPriceBreakdownRows,
  cleanUpServiceDetails,
  updateRows,
  removeRows,
  setLoadingPriceBreakdown,
  removeAutoComplete,
  removeSelectedProcedures,
} = serviceDetailsSlice.actions;

// Export reducer
export default serviceDetailsSlice.reducer;
