import { LoadingSpinner } from "components/LoadingSpinner";
import { centsToDollars, parsePrice } from "helpers/price";
import { sizer } from "layout/styles/styled/sizer";
import { StyledCard } from "pages/CreateOrder/CreateOrder.styled";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dispatch } from "react";
import { AnyAction } from "redux";
import {
  toggleDisplayPatientResponsibility,
  IPriceBreakdownRows,
} from "store/slices/serviceDetails";
import styled from "styled-components";

import { buildSimpleFirstColumnTemplate } from "../../builders";

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const CardHeader = styled.div``;

export function PatientResponsibilityDetails({
  displayPatientResponsibility,
  priceBreakdownRows,
  dispatch,
  priceExecuting,
}: {
  displayPatientResponsibility: boolean;
  priceBreakdownRows: IPriceBreakdownRows;
  dispatch: Dispatch<AnyAction>;
  priceExecuting: boolean;
}) {
  return (
    <StyledCard
      title={
        <CardHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
          }}
          onClick={() => {
            dispatch(toggleDisplayPatientResponsibility());
          }}
        >
          <span>Patient Responsibility</span>
          {displayPatientResponsibility ? (
            <i
              className="pi pi-angle-up
"
              style={{ fontSize: "1.5rem" }}
            />
          ) : (
            <i
              className="pi pi-angle-down
"
              style={{ fontSize: "1.5rem" }}
            />
          )}
        </CardHeader>
      }
      data-testid="gfeServiceDetails_PatientResponsibility"
      aria-disabled={priceBreakdownRows.patientResponsibility.length > 0}
    >
      {!priceExecuting ? (
        <DataTable
          stripedRows
          value={priceBreakdownRows.patientResponsibility}
          tableStyle={{
            minWidth: "100%",
          }}
          style={{ marginLeft: sizer(-4), marginRight: sizer(-4) }}
          responsiveLayout="scroll"
          emptyMessage="Nothing owed by patient."
        >
          <Column
            field="providerName"
            style={{ width: "20%" }}
            body={(row) => buildSimpleFirstColumnTemplate(row)}
          />
          <Column
            field="internalId"
            style={{ width: "23%" }}
            body={(row) => row.cptCode}
          />
          <Column
            field="amount"
            style={{ width: "15%" }}
            body={(row) => parsePrice(centsToDollars(row.amount))}
          />
          <Column
            field="specialty"
            style={{ width: "62%" }}
            body={(row) => row.specialty}
          />
        </DataTable>
      ) : (
        <LoadingContainer>
          <LoadingSpinner customStyle={{ width: "60px", height: "60px" }} />
          <h3>Calculating Patient Responsibility</h3>
        </LoadingContainer>
      )}
    </StyledCard>
  );
}
