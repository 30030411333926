export enum UserRole {
  SUPER_ADMIN = "SuperAdmin",
  HOSPITAL_STAFF = "HospitalStaff",
}

export const userRoleMapping = {
  [UserRole.HOSPITAL_STAFF]: "Hospital Staff",
  [UserRole.SUPER_ADMIN]: "Super Admin",
};

// Ascentist Hospital
// Ascentist Merriam
// Cedar Oak
// Ascentist Clinics
export const enabledChatForAccounts = [
  "ee1a9fcf-4654-421a-9085-86595089a2c7",
  "3ca1490d-18dd-4c4a-a16e-0b5d7760e9c2",
  "8a446a27-adaa-4599-b2cc-c59b6fc37042",
  "4c50efb9-ac4b-47c0-9a2a-81dbf843b844",
];
