import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { HospitalsTable } from "./components/HospitalsTable";

const StyledButton = styled(Button)`
  gap: ${sizer(2)};
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export function HospitalPage() {
  const navigate = useNavigate();

  return (
    <TabView className="w-full">
      <TabPanel header="Hospitals">
        <FlexWrapper>
          <StyledButton
            icon="pi pi-plus"
            className="p-button-raised"
            onClick={() => navigate("/hospitals/new")}
          >
            New Hospital
          </StyledButton>
        </FlexWrapper>
        <HospitalsTable />
      </TabPanel>
    </TabView>
  );
}
