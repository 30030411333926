import { LoadingSpinner } from "components/LoadingSpinner";
import useDocumentTitle from "hooks/useDocumentTitle";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { TabPanel, TabView } from "primereact/tabview";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllOrderTypesCustomsQuery } from "store/queries/customize";
import { CustomizeType, ICustomizeResponse } from "types/Customize";

import { SwitchAccountModal } from "./components/SwitchAccountModal";

export function ListCustoms() {
  useDocumentTitle("Customize");
  const navigate = useNavigate();

  const [selectAccountDialog, setSelectAccountDialog] = useState(false);

  const handleOnChangeAccountDialogVisibility = () => {
    setSelectAccountDialog((prevState) => !prevState);
  };

  const { data, isLoading, isFetching } = useGetAllOrderTypesCustomsQuery();

  const customInfo = useMemo(() => {
    if (!data || !data.data) {
      return undefined;
    }

    const groupCustomInfoByAccount = data.data.reduce(
      (acc: CustomizeType, currentItem: ICustomizeResponse) => {
        const key = currentItem.accountId;
        if (key === null) {
          if (!acc.default) acc.default = [];
          acc.default.push(currentItem);
        } else {
          if (!acc[key]) acc[key] = [];
          acc[key].push(currentItem);
        }

        return acc;
      },
      { default: [] } as CustomizeType
    );

    const parsedDataToTable = Object.entries(groupCustomInfoByAccount).reduce(
      (acc: { name: string; id: string }[], currentItem) => {
        acc.push({
          name: (currentItem[1] as any)[0].accountName,
          id: currentItem[0],
        });

        return acc;
      },
      []
    );

    return parsedDataToTable;
  }, [data]);

  if (!customInfo || isLoading || isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <SwitchAccountModal
        visible={selectAccountDialog}
        onClose={handleOnChangeAccountDialogVisibility}
      />
      <TabView className="w-full">
        <TabPanel header="Customs">
          <div className="flex justify-content-end">
            <Button
              data-testid="create_new_custom_Customize"
              icon="pi pi-plus"
              className="p-button-raised"
              label="Create Account Custom"
              onClick={handleOnChangeAccountDialogVisibility}
            />
          </div>
          <DataTable
            rows={10}
            paginator
            stripedRows
            className="p-fluid"
            filterDisplay="row"
            value={customInfo}
            responsiveLayout="scroll"
            style={{ cursor: "pointer" }}
            emptyMessage="No customs to show."
            selectionMode="single"
            onRowClick={({ data }) => {
              navigate(`/customize/${data.id}`);
            }}
          >
            <Column
              filter
              field="name"
              style={{ minWidth: "320px" }}
              filterPlaceholder="Search customization by hospital name"
            />
          </DataTable>
        </TabPanel>
      </TabView>
    </>
  );
}
