import { FieldValues } from "react-hook-form";

export const mapFormToRequestBody = async (props: FieldValues) => ({
  name: props.name,
  streetAddress: props.address,
  city: props.city,
  state: props.state,
  zipcode: Number(props.zipcode),
  npi: props.npi,
  specialty: props.specialty,
  paymentContact: {
    contactName: props.contactName,
    contactPhone: props.contactPhone,
    contactEmail: props.contactEmail,
  },
  taxPayorIdNumber: props.taxId,
  stripeAccountNumber: props.stripeAccountNumber,
  emailToReceiveReports: props.emailToReceiveReports,
  emailToReceiveNotification: props.emailToReceiveNotification,
  classification: props.type,
  accountId: props.accountId,
});
