import useDocumentTitle from "hooks/useDocumentTitle";
import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { FeeScheduleTable } from "./components/FeeScheduleTable";

const Container = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  gap: ${sizer(2)};
`;

export function FeeSchedulePage() {
  const navigate = useNavigate();
  useDocumentTitle("Fee schedule list");

  return (
    <Container>
      <TabView className="w-full">
        <TabPanel header="Fee Schedule">
          <div className="flex justify-content-end mb-3">
            <StyledButton
              icon="pi pi-plus"
              className="p-button-raised"
              onClick={() => navigate("/fee-schedule/new")}
            >
              New Fee Schedule
            </StyledButton>
          </div>

          <FeeScheduleTable />
        </TabPanel>
      </TabView>
    </Container>
  );
}
