export enum contactMethodConstants {
  PHONE = "Phone",
  EMAIL = "Email",
  BOTH = "Both",
}

export const contactMethodOptions = [
  {
    value: contactMethodConstants.EMAIL,
    name: "Email",
  },
  {
    value: contactMethodConstants.PHONE,
    name: "Phone",
  },
  {
    value: contactMethodConstants.BOTH,
    name: "Both",
  },
];
