/* eslint-disable jsx-a11y/anchor-is-valid */

import { Page, View, Text, Link } from "@react-pdf/renderer";

import { styles } from "../styles";
import { Footer } from "./Footer";
import { Header } from "./Header";

export function SpanishInformation({
  facilityLogo,
  fullAddress,
  phoneNumber,
  facilityName,
  isGFE,
  gfeDisclaimer,
}: {
  facilityLogo: string;
  fullAddress: string;
  phoneNumber: string;
  facilityName: string;
  isGFE: boolean;
  gfeDisclaimer?: { title?: string; description?: string };
}) {
  return (
    <Page size="A4" style={styles.page}>
      <Header
        facilityLogo={facilityLogo}
        fullAddress={fullAddress}
        phoneNumber={phoneNumber}
      />
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          height: "700px",
          justifyContent: "space-between",
          lineHeight: 1.5,
        }}
      >
        <View
          style={{
            ...styles.section,
            fontSize: 11,
            textAlign: "justify",
          }}
        >
          <Text
            style={{
              fontFamily: "Helvetica-Bold",
              marginBottom: 8,
            }}
          >
            {isGFE
              ? gfeDisclaimer?.title
              : "Sin actos sorpresa - Descargo de responsabilidad sobre resolución de disputas"}
          </Text>
          {isGFE ? (
            <Text style={styles.bodyText}>
              {gfeDisclaimer?.description || ""}
            </Text>
          ) : (
            <>
              <Text style={styles.bodyText}>
                Esta estimación de buena fe muestra el costo de los artículos y
                servicios que se esperan razonablemente para sus necesidades de
                atención médica para un artículo o servicio. La estimación se
                basa en información conocida en el momento en que se creó la
                estimación. El Estimado de buena fe no incluye ningún costo
                desconocido o inesperado que pueda surgir durante el
                tratamiento. Tú podrías Se le cobrará más si ocurren
                complicaciones o circunstancias especiales. Si esto sucede, la
                ley federal le permite disputar (apelar) la factura.
              </Text>
              <Text style={styles.bodyText}>
                Si se le factura una cantidad superior a esta Estimación de
                buena fe, tiene derecho a disputar la factura. Puede comunicarse
                con el proveedor de atención médica o el centro indicado para
                informarles que los cargos facturados son más altos que el Buen
                Estimación de fe. Puede pedirles que actualicen la factura para
                que coincida con la estimación de buena fe, solicitar negociar
                la factura o preguntar si hay asistencia financiera disponible.
                También puede iniciar un proceso de resolución de disputas con
                el Departamento de Salud de EE. UU. y Servicios Humanos (DHHS).
                Si decide disputar el proceso de resolución, debe iniciar el
                proceso de disputa dentro de 120 días calendario
                (aproximadamente 4 meses) a partir de la fecha de la factura
                original. Hay una tarifa de $25 por utilizar el proceso de
                disputa. Si el Si la agencia que revisa su disputa está de
                acuerdo con usted, tendrá que pagar el precio en su Estimación
                de buena fe. si la agencia no está de acuerdo con usted y está
                de acuerdo con el proveedor/centro de atención médica, tendrá
                que pagar la cantidad más alta. Aprender Para obtener más
                información y obtener un formulario para iniciar el proceso,{" "}
                <Link src="https://www.cms.gov/nosurprises">
                  https://www.cms.gov/nosurprises
                </Link>{" "}
                o llame al 1-888-918-2522. Si tiene preguntas o más información
                sobre su derecho a una estimación de buena fe o el proceso de
                disputa,
                <Link src="https://www.cms.gov/nosurprises">
                  https://www.cms.gov/nosurprises
                </Link>{" "}
                o llame al 1-888-918-2522. Guarde una copia de este Estimado de
                buena fe en un lugar seguro o tome fotografías del mismo. Es
                posible que lo necesite si le facturan un precio más alto.
                cantidad.
              </Text>
            </>
          )}
        </View>
        <Footer facilityName={facilityName} />
      </View>
    </Page>
  );
}
