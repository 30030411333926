import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getAPIUrl } from "../../config/api";

import type { RootState } from "..";

const baseUrl = getAPIUrl();

export const api = createApi({
  reducerPath: "store",
  baseQuery: async (args, api, extraOptions) => {
    const baseQuery = fetchBaseQuery({
      baseUrl,
      credentials: "include",
      prepareHeaders: async (headers, { getState }) => {
        const { token } = (getState() as RootState).authSlice;
        const { currentAccount } = (getState() as RootState).userSlice;

        if (token) {
          headers.set("authorization", `Bearer ${token}`);
        }

        if (currentAccount) {
          headers.set("account", currentAccount);
        }

        return headers;
      },
    });

    const result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
      window.location.href = "/login";
    }
    return result;
  },
  endpoints: () => ({}),
  keepUnusedDataFor: 0,
});

export const publicApi = createApi({
  reducerPath: "publicStore",
  baseQuery: async (args, api, extraOptions) => {
    const baseQuery = fetchBaseQuery({
      baseUrl,
      credentials: "include",
      prepareHeaders: async (headers, { getState }) => {
        const { publicToken } = (getState() as RootState).authSlice;

        if (publicToken) {
          headers.set("authorization", `Bearer ${publicToken}`);
        }

        return headers;
      },
    });

    const result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
      window.location.href = "/login";
    }
    return result;
  },
  endpoints: () => ({}),
  keepUnusedDataFor: 0,
});
