export const totalLabelsPlugin = {
  id: "totalLabels",
  afterDatasetsDraw(chart: any) {
    const {
      ctx,
      chartArea: { top },
      scales: { x, y },
      data: { datasets },
    } = chart;
    chart.data.labels.forEach((label: any, index: number) => {
      let total = 0;

      // Calculate the total for each stacked bar by summing up each dataset's value at the current index
      datasets.forEach((dataset: any, datasetIndex: number) => {
        if (chart.isDatasetVisible(datasetIndex)) {
          total += dataset.data[index] || 0;
        }
      });

      // Draw the total label above the bar
      ctx.save();
      ctx.font = "bold 16px Arial";
      ctx.fillStyle = "#4338ca";
      ctx.textAlign = "center";
      ctx.fillText(
        total,
        x.getPixelForValue(index),
        y.getPixelForValue(total) - 10 // Adjust position slightly above the bar
      );
      ctx.restore();
    });
  },
};

export const totalLabelsInCurrencyPlugin = {
  id: "totalLabels",
  afterDatasetsDraw(chart: any) {
    const {
      ctx,
      chartArea: { top },
      scales: { x, y },
      data: { datasets },
    } = chart;
    chart.data.labels.forEach((label: any, index: number) => {
      let total = 0;

      // Calculate the total for each stacked bar by summing up each dataset's value at the current index
      datasets.forEach((dataset: any, datasetIndex: number) => {
        if (chart.isDatasetVisible(datasetIndex)) {
          total += dataset.data[index] || 0;
        }
      });
      const formattedTotal = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }).format(total);

      // Draw the total label above the bar
      ctx.save();
      ctx.font = "bold 16px Arial";
      ctx.fillStyle = "#4338ca";
      ctx.textAlign = "center";
      ctx.fillText(
        formattedTotal,
        x.getPixelForValue(index),
        y.getPixelForValue(total) - 10 // Adjust position slightly above the bar
      );
      ctx.restore();
    });
  },
};
